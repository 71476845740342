import axios from 'axios';
import React, { useEffect, useState } from 'react';
import env from 'react-dotenv';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import bankCodes from '../utils/bankCodes.js'


export default function UpgradeBankAccount(props) {

    const [bankList, setBankList] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [acctName, setAcctName] = useState('');
    const [acctnumber, setAcctNumber] = useState('');
    const [acctbank, setAcctBank] = useState('');
    const [amount, setAmount] = useState('');

    
    function commifyNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    function handleAmountChange(event) {
      const inputAmount = event.target.value.replace(/,/g, ''); // Remove existing commas
      const formattedAmount = commifyNumber(inputAmount);
      setAmount(formattedAmount);
    }


    useEffect(() => {
        const fetchBanks = () => {

            // if (localStorage.getItem("bankList")) {
            //     if (bankList.length === 0) {
            //         setBankList(JSON.parse(localStorage.getItem("bankList")))
            //     }
            // } else {
                axios.post(`${env.PROXY_URL}/api/users/getBanks`, {})
                .then(res => { 
                    alert(res.json());
                    if(res.data.success){
                        setBankList(res.data.data.data)
                        localStorage.setItem("bankList", JSON.stringify(res.data.data.data))
                    }else{
                        toast('res.data.message', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                }); 
            // }
            
        };
    
        fetchBanks();
       
      }, [bankList]);

    

    const initialAccountValues = {
        account_bank: '',
        account_number: '',
    };

    const AcctVerificationSchema = Yup.object().shape({
        
        account_bank: Yup.string()
        .required('Select from the list')
        .test('bank-validation', 'Invalid bank selection', value => {
            return bankCodes.includes(value);
          }),
        account_number: Yup.string()
        .required('account number is required')
        .matches(/^[0-9]+$/, 'Account number must contain only numbers')
        .min(10, 'Account number not complete')
        .max(10, 'Account number can have a maximum of 10 digits'),

    });

    const verifyAccount = (values, { setSubmitting, resetForm }) => {
        axios.post(`${env.PROXY_URL}/api/users/verifyAccount`, values)
        .then(res => { 
            
            if(res.data.success){
                setIsVerified(true)
                setAcctNumber(values.account_number)
                setAcctBank(values.account_bank)
                setAcctName(res.data.data.account_name)
                setSubmitting(false);
                // console.log(res.data, isVerified, initialTranferValues);

                resetForm();
            }else{
                toast(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setSubmitting(false);
            }
        })
        .catch(err => {
            console.log(err);
            setSubmitting(false);
        }); 
    }


    const initialTranferValues = {
        from: 'NGN',
        account_name: '',
        amount: '',
        desc: ''

    };

    const TransferCashSchema = Yup.object().shape({
        
        from: Yup.string().oneOf(['NGN'], 'Invalid currency').required('Currency is required'),
        account_name: Yup.string().required('Account name is required'),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .min(10, 'Amount must be greater than or equal to 10')
            .required('Amount is required'),
        desc: Yup.string().max(100, 'Description must not exceed 100 characters'),
        });

    const TransferCash = (values, { setSubmitting, resetForm }) => {
        console.log('hi')
         values.account_number = acctnumber 
         values.account_bank = acctbank
        console.log(values)
        axios.post(`${env.PROXY_URL}/api/users/transferCash`, values)
                .then(res => { 
                    console.log(res.data);
                    if(res.data.success){
                        // localStorage.setItem("bankList", JSON.stringify(res.data.data.data))
                        setSubmitting(false);
                        resetForm();
                    }else{
                        toast(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        setSubmitting(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setSubmitting(false);
                }); 
    }
    
    

    return (
        <>
            <div className="modal fade action-sheet" id="UpgradeAccountActionSheet" tabIndex="-1" role="dialog" >
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Upgrade Account</h5>
                        </div>

                        
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                {!isVerified 
                                ? 
                                (<Formik
                                    initialValues={initialAccountValues}
                                    validationSchema={AcctVerificationSchema}
                                    onSubmit={verifyAccount}
                                >
                                

                                {({ isSubmitting, setFieldValue }) => (

                                    
                                    <Form>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="text11d">BVN</label>
                                                <Field type="text" className="form-control" id="text11d" placeholder="BVN" name="account_number"/>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <ErrorMessage name="account_number" component="div" style={{color:'#FF396F'}}/>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="text11d">Address</label>
                                                <Field type="text" className="form-control" id="text11d" placeholder="Home Address" name="account_number"/>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <ErrorMessage name="account_number" component="div" style={{color:'#FF396F'}}/>
                                        </div>

                                        

                                        <div className="form-group basic">
                                            <button className="btn btn-primary btn-block btn-lg" type="submit" disabled={isSubmitting} >Upgrade</button>
                                        </div>
                                    </Form>
                                    )}
                                </Formik>) 
                                : ''}
                                

                                {isVerified 
                                ? 
                                (<Formik
                                    initialValues={initialTranferValues}
                                    validationSchema={TransferCashSchema}
                                    onSubmit={TransferCash}
                                >
                                

                                {({ isSubmitting, setFieldValue }) => (

                                    
                                    <Form>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="account2d">From</label>
                                                <Field as="select" onChange={(event) => {const { name, value } = event.target; setFieldValue(name, value); }} name="from" className="form-control custom-select" id="account2d">
                                                    <option value="NGN" >Naira</option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="from" component="div" style={{color:'#FF396F'}}/>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label" htmlFor="account_name">Account Name</label>
                                            <div className="input-group mb-2">
                                                <Field name="account_name" id="account_name" type="text" className="form-control" value={acctName} readOnly
                                                    />
                                            </div>
                                            <ErrorMessage name="to" component="div" style={{color:'#FF396F'}}/>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label" htmlFor="amount">Amount</label>
                                            <div className="input-group mb-2">
                                                <Field name="amount" id="amount" type="number" className="form-control" placeholder="Enter an amount"
                                                // onBlur={(e) => {
                                                //     handleAmountChange(e)
                                                // }} 
                                                />
                                            </div>
                                            <ErrorMessage name="amount" component="div" style={{color:'#FF396F'}}/>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label" htmlFor="desc">Description</label>
                                            <div className="input-group mb-2">
                                                <Field name="desc" id="desc" type="text" className="form-control" placeholder="optional"
                                                    />
                                            </div>
                                            <ErrorMessage name="desc" component="div" style={{color:'#FF396F'}}/>
                                        </div>

                                        <div className="form-group basic">
                                            <button type="submit" name="submit" disabled={isSubmitting}  className="btn btn-primary btn-block btn-lg" >Withdraw</button>
                                        </div>
                                    </Form>
                                    )}
                                </Formik>) 
                                : ''}

                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
