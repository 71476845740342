import React from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {ethers} from "ethers";
import env from "react-dotenv";
import USDTContract from "../json/USDT.json"
import { useNavigate } from 'react-router-dom';
// mvWqrftxCJa5eSKp229gkZbMf2XXrfZe9p

function SendCryptoComponent() {

    const app_running = env.APP_RUNNING;
    const connection = app_running === 'development' ? new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER) : new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER_LIVE)
    const id = localStorage.getItem('prospayId')
    const userSeed = `prospaySeed${id}`;
    const passPhrase = localStorage.getItem(userSeed);

    const navigate = useNavigate();
    var wallet;
    var signer;
    if(!passPhrase){
        navigate('/register');
    }else if(!id){
        navigate('/login');
    }else {
        wallet = ethers.Wallet.fromMnemonic(passPhrase);
        signer = wallet.connect(connection)

    }

    // console.log(wallet.privateKey, wallet.address);

    const initialValues = {
        coin: '',
        network: '',
        amount: '',
        toaddress: '',

        // toaddress: 'mvWqrftxCJa5eSKp229gkZbMf2XXrfZe9p',
    };
    
    const validationSchema = Yup.object().shape({
    
    coin: Yup.string()
        .required()
        .oneOf(["BTC", "USDT", "ETH"])
        .label("Selected a coin"),
    network: Yup.string()
        .required()
        .oneOf(["bitcoin", "ethereum", "binance"])
        .label("Selected a network"),
    amount: Yup.string()
    .required('Amount cannot be empty')
    // allows only decimals and integers
    .test(
        'is-decimal',
        'Enter a valid amount',
        value => (value + "")
        .match(/^[0-9]\d*(\.\d+)?$/)
    ),
        // check if input value has a comma
    // check if sender has the amount they want to send
    toaddress: Yup.string()
    // check if an adress is valid based on the network
        .required('Address cannot be empty'),
    });

    
    const onSubmit = async (values, { setSubmitting, resetForm }) => {

        if (values.coin === 'BTC') {


            const version = "v1"
            const protocol = "bitcoin"
            // const network = "mainnet"
            const network =  app_running === 'development' ? 'testnet' : 'mainnet';
            const address = localStorage.getItem(`sourceAddress${id}`)
            const HISTORY_ENDPOINT = `https://svc.blockdaemon.com/universal/${version}/${protocol}/${network}/account/${address}/txs`;
            
            values.passPhrase = passPhrase
            values.xpub = localStorage.getItem('xpub')
            values.privateKey = localStorage.getItem('privateKey')
            values.sourceAddress = localStorage.getItem(`sourceAddress${id}`)
            values.HISTORY_ENDPOINT = HISTORY_ENDPOINT
            values.UBIQUITY_API_KEY = env.UBIQUITY_API_KEY

            axios
            .post(`${env.PROXY_URL}/api/users/sendCrypto`, values)
            .then(res => { 
                console.log(res.data);
            
                if(res.data.success === 1){
                    // Bitcoin 
                    toast("Transaction submitted")
                    setSubmitting(false);
                    resetForm();
                    
                }
                else{
                    toast(res.data.data)
                    setSubmitting(false);
                }
                
            })
            .catch(err => {
                console.error(err);
                setSubmitting(false);
            });
            
        }else if (values.coin === 'USDT') {
            try {
                const amt = ethers.utils.parseUnits(values.amount, env.DECIMAL);
                const app_running = env.APP_RUNNING;
                const contract = app_running === 'development' ? new ethers.Contract(env.USDT_CONTRACT_ADDRS, USDTContract.abi, signer) : new ethers.Contract(env.USDT_CONTRACT_ADDRS_LIVE, USDTContract.abi, signer);
                const txn = await contract.transfer(values.toaddress, amt);
                console.log(txn, "transaction pending....");
                const confirm = await txn.wait();
                toast(`transaction successful....`);
                setTimeout(() => {
                    window.location.reload();
                }, 3000) 
            } catch (error) {
                console.log(error.message)
                toast('insufficient ETH for transaction fee')
                setSubmitting(false); 
            }

        }else if (values.coin === 'ETH') {

            try {
                    
                const recipient = values.toaddress;
                const gasPrice = connection.getGasPrice();
                const tx = {
                    from: wallet.address,
                    to: recipient,
                    value: ethers.utils.parseUnits(values.amount, 'ether'),
                    gasPrice:gasPrice,
                    gasLimit: ethers.utils.hexlify(100000), // 100 gwei
                    nonce: connection.getTransactionCount(wallet.address, 'latest')
                }

                const transaction = await signer.sendTransaction(tx)
                if (transaction){
                    console.log(transaction)
                    toast("ETH sent successfully")
                    setSubmitting(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                }
            } catch (error) {
                console.log(error.message)
                toast('insufficient ETH')
                setSubmitting(false);
            }
        } else {
            
        }
            
    };

    return (
        <>
            
            <div className="modal fade action-sheet" id="sendActionSheet" tabIndex="-1" role="dialog" >
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Send Crypto</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                            
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                            

                                    {({ isSubmitting, setFieldValue }) => (
                                    <Form>                                    
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="coin">From</label>
                                                <Field as="select" className="form-control custom-select" name="coin" id="coin" onChange={(event) => {const { name, value } = event.target; setFieldValue(name, value); }}>
                                                    <option value="Select">Select Crypto</option>
                                                    <option value="BTC">BTC</option>
                                                    <option value="USDT">USDT</option>
                                                    <option value="ETH">ETH</option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="coin" component="div" style={{color:'#FF396F'}} />
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="network">Network</label>
                                                <Field as="select" className="form-control custom-select" name="network" id="network" onChange={(event) => {const { name, value } = event.target; setFieldValue(name, value); }}>
                                                    <option value="Select">Select Network</option>
                                                    <option value="bitcoin">Bitcoin</option>
                                                    <option value="ethereum">Ethereum(ERC20)</option>
                                                    {/* <option value="binance">BNB Smart Chain(BEP20)</option> */}
                                                </Field>
                                                <ErrorMessage name="network" component="div" style={{color:'#FF396F'}} />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="toaddress">To</label>
                                                <Field type="text" className="form-control" id="toaddress" name="toaddress" placeholder="Reciever's Address"/>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <ErrorMessage name="toaddress" component="div" style={{color:'#FF396F'}} />
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label" htmlFor="amount">Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon1"></span>
                                                <Field type="text" className="form-control" id="amount" placeholder="Enter an amount" name="amount"/>
                                            </div>
                                            <ErrorMessage name="amount" component="div" style={{color:'#FF396F'}} />
                                        </div>

                                        <div className="form-group basic">
                                            <button name="submit" type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg" >Send</button>
                                        </div>
                                    </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendCryptoComponent;