import React, { useEffect, useState } from 'react'
import {ethers} from "ethers";
import env from "react-dotenv";
import USDTContract from "../json/USDT.json"
import axios from 'axios';
import axiosPrivate from '../utils/axiosPrivate';

export default function CoinItemsComponent(props) {
    const [ETHbalance, setETHBalance] = useState();
    const [USDTbalance, setUSDTbalance] = useState();
    const [BTCbalance, setBTCbalance] = useState();

    const [ethUsdVal, setEthUsdVal] = useState(0);
    const [btcUsdVal, setBtcUsdVal] = useState(0);
    const [nairaUsdVal, setNairaUsdVal] = useState(0);
    const [usdtUsdVal, setUsdtUsdVal] = useState(0);

    
    const [loading, setLoading] = useState({
        eth: false,
        usdt: false,
        naira: false,
        btc: false,
    });
    
    const app_running = env.APP_RUNNING;
    const provider = app_running === 'development' ? new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER) : new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER_LIVE)
    const id = localStorage.getItem('prospayId')
    const userSeed = `prospaySeed${id}`;
    const passPhrase = localStorage.getItem(userSeed);
    const wallet = ethers.Wallet.fromMnemonic(passPhrase);

    
    const getETHBalance = async (address) => {
       let balance = await provider.getBalance(address);
        setETHBalance(parseFloat(ethers.utils.formatUnits(balance, env.DECIMAL)).toFixed(4));
    };

    
    const getUSDTBalance = async (address) => {
        setLoading({...loading, usdt:true});
        const app_running = env.APP_RUNNING;
        const contract = app_running === 'development' ? new ethers.Contract(env.USDT_CONTRACT_ADDRS, USDTContract.abi, provider) : new ethers.Contract(env.USDT_CONTRACT_ADDRS_LIVE, USDTContract.abi, provider);
        const balance = await contract.balanceOf(address);
        
        setUSDTbalance(parseFloat(ethers.utils.formatUnits(balance, env.DECIMAL)).toFixed(2));
        setLoading({...loading, usdt:false});
    }


    // const btcAddress = localStorage.getItem(`sourceAddress${id}`);
    // const getBTCBalance  = async()  => {
    //     try {
    //         const response = await fetch(`https://api.blockchair.com/bitcoin/dashboards/address/${btcAddress}`);
    //         if (!response.ok) {
    //             throw new Error('Failed to fetch balance');
    //         }
    //         const data = await response.json();
    //         const balance = data.data[btcAddress].address.balance;
    //         // Convert balance from satoshis to BTC
    //         const balanceBTC = balance / 10**10; // 1 Bitcoin = 100,000,000 satoshis
    //         return balanceBTC;
    //     } catch (error) {
    //         console.error('Error fetching balance:', error.message);
    //         return null;
    //     }
    // }



    const getBTCBalance = () => {

        const version = "v1"
        const protocol = "bitcoin"
        // const network = "mainnet"
        const network =  app_running === 'development' ? 'testnet' : 'mainnet';
        const from = localStorage.getItem(`sourceAddress${id}`)
        const BAL_ENDPOINT = `https://svc.blockdaemon.com/universal/${version}/${protocol}/${network}/account/${from}`;

        axios.post(`${env.PROXY_URL}/api/users/getBTCTBalance`, {UBIQUITY_API_KEY:env.UBIQUITY_API_KEY, BAL_ENDPOINT})
        .then(response => {
            // console.log('Account data:', response.data.data[0].confirmed_balance, env.UBIQUITY_API_KEY, BAL_ENDPOINT );
            setBTCbalance((response.data.data[0].confirmed_balance)/100000000);
        })
        .catch(error => {
            // console.error('Error ', error.response.data);
            return false;
        });
    }


    const getNAIRARate  = async () => {

        await axios.post(`${env.PROXY_URL}/api/users/getAddreses`, {})
        .then(res => {
            
            if (res.data.success) {
                setNairaUsdVal(localStorage.getItem('naira_bal')/res.data.data[0].naira_rate)
                // console.log(nairaUsdVal) 
            } else {
                console.log(res.data)
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    // Define the headers for the API request
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${env.UBIQUITY_API_KEY}`,
    };

    const setCoinToUsdVal = () => {

        axios
        .get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum,tether,bitcoin&vs_currencies=usd`)
        .then(res => { 
    
            if(res.data){
                // console.log(res.data);

                setEthUsdVal(res.data.ethereum.usd)
                setBtcUsdVal(res.data.bitcoin.usd)
                setUsdtUsdVal(res.data.tether.usd)

            }else{
                console.log(res.data);
                return false
            }
            
        })
        .catch(err => {
            console.error(err);
        });
    }


    useEffect(() => {
        getETHBalance(wallet.address);
        getUSDTBalance(wallet.address);
        getBTCBalance();
        getNAIRARate()
        setCoinToUsdVal()

        const widgetContainer = document.getElementById("coinmarket-widget");
        const widget = document.getElementById("coinmarketcap-widget-marquee");

        if (widget.hasChildNodes()) {
            // widgetContainer.appendChild(widget);
        }
    
    }, [ethUsdVal, nairaUsdVal, usdtUsdVal, btcUsdVal]);


    const [error, setError] = useState(null)
    const [accountData, setAccountData] = useState()
    // const [loading, setLoading] = useState(false)
    const [userHasAccount, setUserHasAccount] = useState(false)
    const userID = localStorage.getItem('prospayId')

    
    useEffect(() => {
        const accountId = localStorage.getItem(`account_id${userID}`)

        if (accountId) {
            setLoading(true)

            const fetchBankAccount = async() => {
                try {
                    const response = await axiosPrivate.get(`accounts/${accountId}`);
                    const { data } = response;
                    if (data.statusCode === 200) {
                    //   console.log(data.data);
                    
                      setAccountData(data.data)
                      setUserHasAccount(true)
                      setLoading(false);
                    
                    } else {
                      console.log(data);
                      setLoading(false);
                    //   toast(data.message);
                      console.log(data.message);
                      setError('Account not fetched.');
                    }
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                    setError('Account not fetched.');

                    if (error.response) {
                        // toast(error.response.data.message);
                        console.log(error.response.data.message);
                    }else {
                        // toast(error.message);
                        console.log(error.message);
                    }
                }
            }

            fetchBankAccount()
            
        } else {
            setUserHasAccount(false)
            setAccountData({})


        }

        
    }, [userHasAccount])
    

    return (
        <>
            <div className="row mt-2">
                <div className="col-6">
                    <div className="stat-box">
                        <div className="title ">ETH: ${(ETHbalance) && (ethUsdVal) ? parseFloat(ETHbalance*ethUsdVal).toFixed(2): '...'}</div>
                        <div className="value">⟠ {ETHbalance}</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="stat-box">
                        <div className="title ">ERC20 USDT: ${ (USDTbalance) && (usdtUsdVal) ? parseFloat(USDTbalance*usdtUsdVal).toFixed(2): '...'}</div>
                        <div className="value">₮ {USDTbalance}</div>
                    </div>
                </div>
            </div>
           <div className="row mt-2">
                <div className="col-6">
                <div className="stat-box">
                        <div className="title">NGN: ${nairaUsdVal ? parseFloat(nairaUsdVal).toFixed(2): '...'}</div>
                        <div className="value">₦ { ethers.utils.commify(localStorage.getItem('naira_bal'))}</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="stat-box">
                        <div className="title ">BTC: ${(BTCbalance) && (btcUsdVal) ? parseFloat(BTCbalance*btcUsdVal).toFixed(2): '...'}</div>
                        <div className="value">฿ {BTCbalance}</div>
                        {/* <div className="title ">BTC: ${(BTCbalance) && (btcUsdVal) ? parseFloat(BTCbalance*btcUsdVal).toFixed(2): '...'}</div>
                        <div className="value">฿ 0.00</div> */}
                    </div>
                </div>

                <div id="coinmarket-widget" />
            </div> 
        </>
    )
}
