import React from 'react'
// import { Link } from 'react-router-dom'

export default function AppHeaderRightComponent(props) {
    

    return (
        <div className="right">
            {/* <a href="app-notifications.html" className="headerButton">
                <ion-icon className="icon" name="notifications-outline"></ion-icon>
                <span className="badge badge-danger">4</span>
            </a> */}

            {/* <Link to={"/settings"} className="headerButton">
                <img src="assets/img/sample/avatar/avatar1.jpg" alt="logo" className="imaged w32" />
                <span className="badge badge-danger">6</span>
            </Link> */}

            {/* <a href="settings" className="headerButton">
                <img src="assets/img/sample/avatar/avatar1.jpg" alt="logo" className="imaged w32" />
                <span className="badge badge-danger">6</span>
            </a> */}
        </div>
    )
}
