import React from 'react'
import WalletFooterItemComponent from './WalletFooterItemComponent'

export default function WalletFooterComponent(props) {
    

    return (
        <div className="wallet-footer">
            <WalletFooterItemComponent/>
        </div>
    )
}
