import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import env from "react-dotenv";


export default function ForgotPasswordPage(props) {
    const navigate = useNavigate()
    const [code, setCode] = useState(null);

    function generateCode() {
        setCode(Math.floor(100000 + Math.random() * 900000).toString());
        return code
    }

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    });

    const mailContent = (subject, firstname, mailBody) => {
        return (
            `
                <html>
    
                    <head>
                        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                        <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0;" />
                        <link href='https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700' rel="stylesheet" />
                        <link href='https://fonts.googleapis.com/css?family=Quicksand:300,400,700' rel="stylesheet" />
    
                        <title>${subject}</title>
    
                        <style>
                            body {
                                width: 100%;
                                background-color: #ffffff;
                                margin: 0;
                                padding: 0;
                                -webkit-font-smoothing: antialiased;
                                mso-margin-top-alt: 0px;
                                mso-margin-bottom-alt: 0px;
                                mso-padding-alt: 0px 0px 0px 0px;
                            }p,
                            h1,
                            h2,
                            h3,
                            h4 {
                                margin-top: 0;
                                margin-bottom: 0;
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                            
                            span.preheader {
                                display: none;
                                font-size: 1px;
                            }
                            
                            html {
                                width: 100%;
                            }
                            
                            table {
                                font-size: 14px;
                                border: 0;
                            }
                            /* ----------- responsivity ----------- */
                            
                            @media only screen and (max-width: 640px) {
                                /*------ top header ------ */
                                .main-header {
                                    font-size: 20px !important;
                                }
                                .main-section-header {
                                    font-size: 28px !important;
                                }
                                .show {
                                    display: block !important;
                                }
                                .hide {
                                    display: none !important;
                                }
                                .align-center {
                                    text-align: center !important;
                                }
                                .no-bg {
                                    background: none !important;
                                }
                                /*----- main image -------*/
                                .main-image img {
                                    width: 440px !important;
                                    height: auto !important;
                                }
                                /* ====== divider ====== */
                                .divider img {
                                    width: 440px !important;
                                }
                                /*-------- container --------*/
                                .container590 {
                                    width: 440px !important;
                                }
                                .container580 {
                                    width: 400px !important;
                                }
                                .main-button {
                                    width: 220px !important;
                                }
                                /*-------- secions ----------*/
                                .section-img img {
                                    width: 320px !important;
                                    height: auto !important;
                                }
                                .team-img img {
                                    width: 100% !important;
                                    height: auto !important;
                                }
                            }
                            
                            @media only screen and (max-width: 479px) {
                                /*------ top header ------ */
                                .main-header {
                                    font-size: 18px !important;
                                }
                                .main-section-header {
                                    font-size: 26px !important;
                                }
                                /* ====== divider ====== */
                                .divider img {
                                    width: 280px !important;
                                }
                                /*-------- container --------*/
                                .container590 {
                                    width: 280px !important;
                                }
                                .container590 {
                                    width: 280px !important;
                                }
                                .container580 {
                                    width: 260px !important;
                                }
                                /*-------- secions ----------*/
                                .section-img img {
                                    width: 280px !important;
                                    height: auto !important;
                                }
                            }
                        </style>
                        
                    </head>
    
                    <body className="respond" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">
                        <table style="display:none!important;">
                            <tr>
                                <td>
                                    <div style="overflow:hidden;display:none;font-size:1px;color:#ffffff;line-height:1px;font-family:Arial;maxheight:0px;max-width:0px;opacity:0;">
                                        ${subject}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff">
    
                            <tr>
                                <td align="center">
                                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">
    
                                        <tr>
                                            <td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>
                                        </tr>
    
                                        <tr>
                                            <td align="center">
    
                                                <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">
    
                                                    <tr>
                                                        <td align="center" height="70" style="height:70px;">
                                                            <a href="" style="display: block; border-style: none !important; border: 0 !important;"><img width="100" border="0" style="display: block; width: 300px;" src="https://prospay.io/assets/app_img/logo.png" alt="" /></a>
                                                        </td>
                                                    </tr>
    
                                                </table>
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>
                                        </tr>
    
                                    </table>
                                </td>
                            </tr>
                        </table>
    
    
                        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff" className="bg_color">
    
                            <tr>
                                <td align="center">
                                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">
    
                                        <tr>
                                            <td align="center" style="color: #343434; font-size: 24px; font-family: Quicksand, Calibri, sans-serif; font-weight:700;letter-spacing: 3px; line-height: 35px;"
                                                className="main-header">
    
                                                <div style="line-height: 35px">
    
                                                    ${subject}
    
                                                </div>
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>
                                        </tr>
    
                                        <tr>
                                            <td align="center">
                                                <table border="0" width="40" align="center" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                                    <tr>
                                                        <td height="2" style="font-size: 2px; line-height: 2px;">&nbsp;</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td height="20" style="font-size: 20px; line-height: 20px;">&nbsp;</td>
                                        </tr>
    
                                        <tr>
                                            <td align="left">
                                                <table border="0" width="590" align="center" cellpadding="0" cellspacing="0" className="container590">
                                                    <tr>
                                                        <td align="left" style="color: #888888; font-size: 16px; font-family: 'Work Sans', Calibri, sans-serif; line-height: 24px;">
    
                                                            <p style="line-height: 24px; margin-bottom:15px;">
    
                                                                Hi ${firstname},
    
                                                            </p>
    
                                                            ${mailBody}
    
                                                            <p style="line-height: 24px">
                                                                Love,<br/>
                                                                The Prospay team.
                                                            </p>
    
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
    
    
    
    
    
                                    </table>
    
                                </td>
                            </tr>
    
                            <tr>
                                <td height="40" style="font-size: 40px; line-height: 40px;">&nbsp;</td>
                            </tr>
    
                        </table>
    
    
    
                        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="2a2e36">
    
                            <tr>
                                <td align="center" style="background-image: url(https://prospay.io/assets/app_img/footer-card.png); background-size: cover; background-position: top center; background-repeat: no-repeat;"
                                    background="https://prospay.io/assets/app_img/footer-card.png">
    
                                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">
    
                                        <tr>
                                            <td height="50" style="font-size: 50px; line-height: 50px;">&nbsp;</td>
                                        </tr>
    
                                        <tr>
                                            <td align="center">
                                                <table border="0" width="380" align="center" cellpadding="0" cellspacing="0" style="border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;"
                                                    className="container590">
    
                                                    <tr>
                                                        <td align="center">
                                                            <table border="0" align="center" cellpadding="0" cellspacing="0" className="container580">
                                                                <tr>
                                                                    <td align="center" style="color: #cccccc; font-size: 16px; font-family: 'Work Sans', Calibri, sans-serif; line-height: 26px;">
    
                                                                        <div style="line-height: 26px">
    
                                                                            Visit out Website
    
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
    
                                                </table>
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>
                                        </tr>
    
                                        <tr>
                                            <td align="center">
                                                <table border="0" align="center" width="250" cellpadding="0" cellspacing="0" style="border:2px solid #ffffff;">
    
                                                    <tr>
                                                        <td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>
                                                    </tr>
    
                                                    <tr>
                                                        <td align="center" style="color: #ffffff; font-size: 14px; font-family: 'Work Sans', Calibri, sans-serif; line-height: 22px; letter-spacing: 2px;">
    
                                                            <div style="line-height: 22px;">
                                                                <a target="_blank" href="https://prospay.io" style="color: #fff; text-decoration: none;">Go to prospay.io</a>
                                                            </div>
                                                        </td>
                                                    </tr>
    
                                                    <tr>
                                                        <td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>
                                                    </tr>
    
                                                </table>
                                            </td>
                                        </tr>
    
    
                                        <tr>
                                            <td height="50" style="font-size: 50px; line-height: 50px;">&nbsp;</td>
                                        </tr>
    
                                    </table>
                                </td>
                            </tr>
    
                        </table>
    
    
                        
    
                        
    
                    </body>
    
                </html>
            `
        )
    }


    const onSubmit = (values, { setSubmitting, resetForm }) => {
        axios.post(`${env.PROXY_URL}/api/users/checkMail`, values)
        .then(function (res) {

            if(res.data.success){
                if (generateCode()) {
                    const subject = "Password Recovery Request"
                    const firstname = res.data.data.firstname
                    const email = res.data.data.email
                    const mailBody = 
                    `
                        <p style="line-height: 24px;margin-bottom:15px;">You Requested a change of password, Kindly use the code below to verify you are the one making this request.</p>
                        <p style="line-height: 24px;margin-bottom:15px;">Your Password Reset Code is:</p>
                        <p style="line-height: 24px;margin-bottom:15px;">${code}</p>
                        <p style="line-height: 24px;margin-bottom:15px;">If you did not initiate this request, reply to this mail or email us at <a href="mailto:support@prospay.io">support@prospay.io</a>.</p>
                        <p style="line-height: 24px;margin-bottom:15px;">We are always available to answer your questions or provide you with clarifications. always reach us via email at <a href="mailto:support@prospay.io">support@prospay.io</a>.</p>
                    `
                    const html = mailContent(subject, firstname, mailBody)
                    const userId = res.data.data.id
                    const emailData = {
                        firstname: firstname,
                        subject: subject,
                        html : html, // mail content
                        email: email, // reciever
                        code: code, // verification code
                        id: userId // current user id
                    }
                    resetForm({});
                    
                    // request to send welcome mail to user
                    axios.post(`${env.PROXY_URL}/api/users/passCode`, emailData)
                    .then(function (res) {
                        setTimeout(() => {
                            toast('Password Reset Email sent succefully.')
                        }, 2000);

                        setTimeout(() => {
                            toast('You will be redirected shortly.')
                        }, 3000);

                        setTimeout(() => {
                            navigate(`/passwordReset?id=${userId}`)
                        }, 5000);
                    })
                    .catch(function (error) {
                        console.log(error);                    
                    });
                } else {
                    console.log(code)
                }
                
            }else if(res.data.success === 0){
                toast(res.data.message)
            }else {
                console.log(res)
            }
            setSubmitting(false);
        })
        .catch(function (error) {
            console.log(error);
            toast('Something went wrong: ' + error.message + ', Try again.')
            setSubmitting(false);
        });
                
    };

    return (
        <>
            <div className="section mt-2 text-center">
                <h1>Forgot Password</h1>
                <h4>Enter your registered email to reset your password</h4>
            </div>

            <div className="section mb-5 p-2">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form >
                            <div className="card">
                                <div className="card-body pb-1">

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="email1">Email</label>
                                            <Field type="email" className="form-control" id="email1" placeholder="Your e-mail" name="email"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <ErrorMessage name="email" component="div" style={{color:'#FF396F'}} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-links mt-2">
                                <div> 
                                    <Link to="/login" className="headerButton">
                                       Remember Password? Login.
                                    </Link>                        
                                </div>
                            </div>

                            <div className="form-button-group transparent">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg">Reset Password</button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

