import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import LogOutButton from './LogOutButton';
import { useNavigate } from 'react-router-dom';
import SendCryptoComponent from './SendCryptoComponent';
import {ethers} from "ethers";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SideBarTotal from './SideBarTotal';
import SwapCryptoComponent from './SwapCryptoComponent';
// import TransferCashComponent from './TransferCashComponent';


export default function AppHeaderLeftComponent(props) {
    const navigate = useNavigate();
    const id = localStorage.getItem('prospayId')
    const userSeed = `prospaySeed${id}`
    const passPhrase = localStorage.getItem(userSeed);
    // console.log(id, userSeed, passPhrase)
    var wallet;
    
    if(!passPhrase){
        navigate('/register');
    }else if(!id){
        navigate('/login');
    }else {
        wallet = ethers.Wallet.fromMnemonic(passPhrase);
    }
    

    
    const [copiedText, setCopiedText] = useState(localStorage.getItem(`sourceAddress${id}`));
      
    const body = document.getElementsByTagName("body")[0];
    var backdrop = document.createElement("div"); // <div></div>
    
    const handleBackDrop = () => {
        backdrop = document.querySelector('.modal-backdrop');
        // const appHeader = document.getElementById('appHeader');
        body.removeChild(backdrop);
        // appHeader.appendChild(backdrop);
    }

    const handleLogout = () => {
        backdrop = document.querySelector('.modal-backdrop');
        const appHeader = document.getElementById('appHeader');
        appHeader.removeChild(backdrop);
        localStorage.removeItem('userToken');
        navigate('/login');
    };

    const handleComingSoon=()=>{
        toast('coming soon')
    }

    const handleRecieveAddrs = () => {
        const recieveCoin = document.getElementById('recieveCoin')
        const recieveAddr = document.getElementById('recieveAddr')

        if(recieveCoin.value === 'BTC'){
            recieveAddr.value = localStorage.getItem(`sourceAddress${id}`);
            setCopiedText(localStorage.getItem(`sourceAddress${id}`))
        }else if(recieveCoin.value === 'ETH' || recieveCoin.value === 'USDT'){
            recieveAddr.value = wallet.address;
            setCopiedText(wallet.address)
        }else{
            
        }
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText(copiedText);
        toast('copied');
    };



    return (
        <>
            <div className="left">

                <button data-bs-toggle="modal" data-bs-target="#sidebarPanel" className="headerButton bg-transparent shadow-none border-0" onClick={() => handleBackDrop()}>
                    <ion-icon name="menu-outline"></ion-icon>
                </button>

            </div>

            {/* recieve modal */}
            <div className="modal fade action-sheet" id="depositActionSheet" tabIndex="-1" role="dialog" >
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Recieve Crypto</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <form>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="recieveCoin">Select Crypto</label>
                                            <select className="form-control custom-select" id="recieveCoin" onChange={() => {
                                                handleRecieveAddrs()
                                            }}>
                                                <option value="BTC">BTC</option>
                                                <option value="USDT">USDT</option>
                                                <option value="ETH">ETH</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <label className="label" htmlFor='recieveAddr'> Address</label>
                                        <div className="input-group mb-2">
                                            <input type="text" id='recieveAddr' className="form-control" placeholder={localStorage.getItem(`sourceAddress${id}`) } readOnly
                                            
                                                />
                                        </div>
                                    </div>


                                    <div className="form-group basic">
                                        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={handleCopyClick}>Copy Address</button>
                                            {/* data-bs-dismiss="modal" */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            

            {/* withdraw modal
            <TransferCashComponent/> */}

            

            {/* Send modal */}
            <SendCryptoComponent/>

            {/* swap modal */}
            <SwapCryptoComponent/>
        


            {/* sidebar modal */}
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" aria-labelledby="sidebarPanelLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="Profile" className="imaged  w36"/>
                                </div>
                                <div className="in">
                                    <strong>{localStorage.getItem('firstname')} {localStorage.getItem('lastname')}</strong>
                                    {/* <div className="text-muted">4029209</div> */}
                                </div>

                                <a href="/" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>
                            
                            
                            <SideBarTotal/>
                            

                            
                            <div className="action-group">

                                <a href="/" className="action-button" data-bs-toggle="modal" data-bs-target="#sendActionSheet" onClick={() => handleBackDrop()}>
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-up-outline"></ion-icon>
                                        </div>
                                        Send
                                    </div>
                                </a>

                                <a href="/" className="action-button"data-bs-toggle="modal" data-bs-target="#depositActionSheet" onClick={() => handleBackDrop()}>
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Recieve
                                    </div>
                                </a>

                                {/* <a href="index.html" className="action-button"  data-bs-toggle="modal" data-bs-target="#withdrawActionSheet" onClick={() => handleBackDrop()}> */}
                                <Link to="/transfer" className="action-button"  >
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        Transfer
                                    </div>
                                </Link>

                                <a href="app-cards.html" className="action-button"  data-bs-toggle="modal" data-bs-target="#exchangeActionSheet" onClick={() => handleBackDrop()}>
                                    <div className="in">
                                        <div className="iconbox">
                                            <ion-icon name="swap-vertical-outline"></ion-icon>
                                        </div>
                                        Swap
                                    </div>
                                </a>

                            </div>
                            

                            
                            <div className="listview-title mt-1">Menu</div>
                            <ul className="listview flush transparent no-line image-listview">

                                {/* <li>
                                    <Link to="/bank-account" className="item" >
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="home-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Bank Account
                                        </div>
                                    </Link>
                                </li> */}

                                <li>
                                    <Link to="/" className="item" >
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="home-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Home
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="transactions" className="item" >
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="document-text-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Transactions
                                        </div>
                                    </Link>
                                </li>

                                <li>
                                    <Link onClick={() => handleComingSoon()} className="item bg-transparent shadow-none border-0">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Bills Payment
                                        </div>
                                    </Link>
                                </li>


                                <li>
                                    <Link onClick={() => handleComingSoon()} className="item bg-transparent shadow-none border-0">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Spin & Win
                                        </div>
                                    </Link>
                                </li>


                                <li>
                                    <Link onClick={() => handleComingSoon()} className="item bg-transparent shadow-none border-0">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Loan
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <LogOutButton position="sidebar"/>
                                    
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div> 


            {/* logout modal */}
            <div className="modal fade action-sheet" id="logoutActionSheet" tabIndex="-1" role="dialog">
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Logout</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <div>

                                    <div className="form-group basic">
                                        <p className="text-center">Are you sure you want to log out?</p>
                                    </div>

                                    <div className="form-group basic">
                                        <button type="button" className="btn btn-primary btn-lg w-25" onClick={handleLogout} >Yes</button>

                                        <button type="button" className="sidebar-close btn btn-primary btn-lg w-25" data-bs-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            
        </>
    )
}
