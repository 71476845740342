import React from 'react'
import { useNavigate } from "react-router-dom";

export default function Error404Page(props) {
    const navigate = useNavigate();

    return (
        <>
            <div className="section">
                <div className="splash-page mt-5 mb-5">
                    <h1>Error</h1>
                    <h2 className="mb-2">Request Break</h2>
                    <p>
                        Oops!☹️☹️ we cannot proccess your request, try again.
                    </p>
                </div>
            </div>

            <div className="fixed-bar mb-5">
                <div className="row">
                    <div className="col-6">
                        <button onClick={() => {navigate(-1);}} className="btn btn-lg btn-outline-secondary btn-block goBack">Go Back</button>
                        
                    </div>
                    <div className="col-6">
                        <button onClick={ () => window.location.reload() } className="btn btn-lg btn-primary btn-block">Try Again</button>
                    </div>
                </div>
            </div>
        </>
    )
}
