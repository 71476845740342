import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'
import env from "react-dotenv";

export default function RetrieveWalletPage(props) {
    const navigate = useNavigate();
    const [seedPhrase, setSeedPhrase] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('userToken')
    const id = localStorage.getItem('prospayId')
 


    const verifySeed = async() => {

        const seed = document.getElementById('seedPhrase').value.trim();
        console.log(seed)

        if (seed !== '') {
            setLoading(true);
            const header = {authorization: `Bearer ${token}`};
            axios.post(`${env.PROXY_URL}/api/users/retrieveWallet`, {seed}, header)
            .then(res => { 
                if(res.data.success){
                    console.log(res.data)
                    localStorage.setItem(`xpub${id}`, res.data.data.xpub)
                    localStorage.setItem(`privateKey${id}`, res.data.data.privateKey)
                    localStorage.setItem(`sourceAddress${id}`, res.data.data.sourceAddress)
                    setSeedPhrase(res.data.data.mnemonic);

                    const userSeed = `prospaySeed${id}`;
                    localStorage.setItem(userSeed, res.data.data.mnemonic)
                    navigate('/');
                    setLoading(false);
                    
                }else{
                    toast(res.data.message)
                    
                }
            })
            .catch(err => {
                console.error(err);
                
            });
        }else {
            toast('Enter your seed phrase in the area below')
        }
    
    }


    return (
        <div className="section mt-3 text-center">
            <h1 >Enter your seed phrase</h1>
            <div className="wide-block pt-2 pb-2" style={{fontSize : "20px", marginTop:'30px'}}>
                <form action="">
                    <textarea name="" id="seedPhrase" cols="30" rows="5">

                    </textarea>
                </form>
            </div>

            <button disabled={loading} onClick={() => {verifySeed()}} className="btn btn-primary btn-block btn-lg" style={{position:'fixed', bottom: '80px',left:'0px', right:'0px', textAlign: 'center'}}>
            {loading 
            ? 
                (
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="5"
                        color="#4fa94d" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                )
            :
                'IMPORT'
            }
            </button>
        </div>
    )
}

 