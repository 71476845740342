import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import UpgradeBankAccount from '../components/UpgradeBankAccount';
import axiosPrivate from '../utils/axiosPrivate';
import { CREATE_BANK_ACCOUNT } from '../utils/constants';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from 'react-loader-spinner';
import axios from '../utils/axios';
import env from "react-dotenv";


export default function BankAccountPage(props) {
    const [submitting, setSubmitting] = useState(false)

    const [error, setError] = useState(null)
    const [accountData, setAccountData] = useState()
    const [loading, setLoading] = useState(false)
    const [userHasAccount, setUserHasAccount] = useState(false)
    const userID = localStorage.getItem('prospayId')

    
    useEffect(() => {
        const accountId = localStorage.getItem(`account_id${userID}`)

        if (false) {
            setLoading(true)

            const fetchBankAccount = async() => {
                try {
                    const response = await axiosPrivate.get(`accounts/${accountId}`);
                    const { data } = response;
                    if (data.statusCode === 200) {
                      console.log(data.data);
                    
                      setAccountData(data.data)
                      setUserHasAccount(true)
                      setLoading(false);
                    
                    } else {
                      console.log(data);
                      setLoading(false);
                      toast(data.message);
                      setError('Account not fetched.');
                    }
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                    setError('Account not fetched.');

                    if (error.response) {
                        toast(error.response.data.message);
                    }else {
                        toast(error.message);
                    }
                }
            }

            fetchBankAccount()
            
        } else {
            setUserHasAccount(false)

            const refreshToken = () => {
            

                const options = {
                method: 'POST',
                url: 'https://api.safehavenmfb.com/oauth2/token',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                data: {
                  grant_type: 'client_credentials',
                  client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
                  client_id: 'b82a504fe6f4cf19515eb15880417f40',
                  client_assertion: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwcC5wcm9zcGF5LmlvIiwic3ViIjoiYjgyYTUwNGZlNmY0Y2YxOTUxNWViMTU4ODA0MTdmNDAiLCJhdWQiOiJodHRwczovL2FwaS5zYWZlaGF2ZW5tZmIuY29tIiwiaWF0IjoxNzAwNzU0NTA0LCJleHAiOjE4OTAxMzQ4NTl9.MZjBrWZy1ADFdgcFN0C-8KdRNk9u9IfcRayA44M-Ex50OWez_C8KGhIeXHRjLzHH-EPNwScVH0Mc82gExi81RQ4X72BesIppcOot2-Vhrb11gDudFDnO5D1jLL3ZtkA3zK-Mp7ayzgEiQXCsLxOTcAGZ94MsA-4ODaXtyW3IoEs',
                  refresh_token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5zYWZlaGF2ZW5tZmIuY29tIiwic3ViIjoiYjgyYTUwNGZlNmY0Y2YxOTUxNWViMTU4ODA0MTdmNDAiLCJhdWQiOiJodHRwczovL2FwcC5wcm9zcGF5LmlvIiwianRpIjoiNDJhMGUwYmIyZmEwMjQ0N2E2MWZmNThlZDRmNTU3NTQiLCJncmFudF90eXBlIjoicmVmcmVzaF90b2tlbiIsInNjb3BlcyI6WyJSRUFEIiwiV1JJVEUiLCJQQVkiXSwiaWJzX2NsaWVudF9pZCI6IjY1NDUwNjUyY2MyZGQyMDAyNDYzNmJmYyIsImlic191c2VyX2lkIjoiNjU0NTA2NTJjYzJkZDIwMDI0NjM2YzA1IiwiaWF0IjoxNzAwODIxMjY1LCJleHAiOjE3MDg1OTcyNjV9.tqBtvSfXJGMtbSiHGS_raidWalDBOm4FpjwXxdG86AlbIDvL9W2OfPtY6xxh5xTfVn_JwJfGPTu1WgceP5Wc-LjmqjqmmJhgYvYiIxIe7F1BHT6FjfjleG3haNClBdTbt_4X2AEquFV4BHnn8stnP207S697HS0epYYJgOAlo3U'
                }
                };
                
                
                axios
                .request(options)
                .then(function (response) {
                    console.log(response.data);
                })
                .catch(function (error) {
                    console.error(error);
                });
            }

            refreshToken()

        }

        
    }, [userHasAccount])
    
      
    // const body = document.getElementsByTagName("body")[0];
    // var backdrop = document.createElement("div"); // <div></div>
    
    // const handleBackDrop = () => {
    //     backdrop = document.querySelector('.modal-backdrop');
    //     body.removeChild(backdrop);
    // }


    const createAccount = async() => {
       const payload = {
            firstName:localStorage.getItem('firstname'),
            lastName:localStorage.getItem('lastname'),
            emailAddress:localStorage.getItem('email'),
            phoneNumber:localStorage.getItem('phone'),
            externalReference:`${Date.now()}`,
       }

       setSubmitting(true);

       try {
        const response = await axiosPrivate.post(`${CREATE_BANK_ACCOUNT}`, payload);
        const { data, status } = response;
        if (data.statusCode === 200) {
          console.log(data, data.data._id);
          localStorage.setItem(`account_id${userID}`, data.data._id)
        
            axios
            .post(`${env.PROXY_URL}/api/users/addBankAccountId`, {account_id:data.data._id, id:localStorage.getItem('prospayId')})
            .then(res => { 
                console.log(res.data);

                if(res.data.success){
                    setSubmitting(false);
                    toast('Account created successfully')
                    setUserHasAccount(true)
                }else{
                    toast(res.data.data)
                    setSubmitting(false);
                }
            })
            .catch(err => {
                console.error(err);
                setSubmitting(false);
            });

        } else {
          console.log(data);
          setSubmitting(false);
          toast(data.message);
        }
      } catch (error) {
        setSubmitting(false);
        console.log(error);

        if (error.response) {
          toast(error.response.data.message);
        }else {
          toast(error.message);
        }
      }


            // axios
            // .post(`${env.PROXY_URL}/api/users/addBankAccountId`, {account_id:'655f9a330a4da10024a5e7cf', id:localStorage.getItem('prospayId')})
            // .then(res => { 
            //     console.log(res.data);

            //     if(res.data.success){
            //         setSubmitting(false);
            //         toast(res.data.data)
            //         setUserHasAccount(true)
            //     }else{
            //         toast(res.data.data)
            //         setSubmitting(false);
            //     }
            // })
            // .catch(err => {
            //     console.error(err);
            //     toast(err.message)
            //     setSubmitting(false);
            // });
  
    }


    return (
        <>
            {/* modal */}
            <UpgradeBankAccount />

            {/* {loading ? */}
            {loading || error ?

            <div id="loader">
                <img src="assets/img/loading-icon.png" alt="icon" className="loading-icon"/>
            </div>
            :
            <>
              {userHasAccount && accountData ? (
                <> 
                    <div className="listview-title mt-1">Account Details</div>
                    <ul className="listview image-listview text mb-2 inset">
                        {/* <li>
                            <Link to={'#'} className="item">
                                <div className="in">
                                    <div>Level 1</div>
                                </div>
                            </Link>
                        </li> */}
                        <div className="listview-title mt-1">Account Name</div>
                        <li>
                            <Link to={'#'} className="item">
                                <div className="in">
                                    <div>{accountData.accountName}</div>
                                </div>
                            </Link>
                        </li>

                        <div className="listview-title mt-1">Account Number</div>
                        <li>
                            <Link to={'#'} className="item">
                                <div className="in">
                                    <div>{accountData.accountNumber}</div>
                                </div>
                            </Link>
                        </li>

                        <div className="listview-title mt-1">Bank Name</div>
                        <li>
                            <Link to={'#'} className="item">
                                <div className="in">
                                    <div>Safe Haven Microfinance Bank</div>
                                </div>
                            </Link>
                        </li>
                    </ul>
    
                    {/* <div className="listview-title mt-1">Account Level</div>
                    <div className="listview-title">(Enjoy higher amount transactions when you upgrade your account) </div>
                    <ul className="listview image-listview text mb-2 inset">
                        <li>
                            <Link to={'#'} className="item">
                                <div className="in">
                                    <div>Amarachi Ugwu</div>
                                </div>
                            </Link>
                        </li>
                    </ul>
    
                    <ul className="listview image-listview text mb-3 inset">
                        <button data-bs-toggle="modal" data-bs-target="#UpgradeAccountActionSheet" className="btn btn-primary btn-block btn-lg" onClick={() => handleBackDrop()}>
                            Upgrade Account
                        </button> 
                    </ul> */}
    
                </>
              ) : (

                <>
                    <div className="listview-title mt-1">Your Account</div>
                    <div className="listview-title">(You do not have an account yet, create account below) </div>
                    <ul className="listview image-listview text mb-3 inset">
                            {submitting 
                            ? 
                                (
                                    <button className="btn btn-primary btn-block btn-lg">
                                        {submitting 
                                        ? 
                                            (
                                                <ThreeDots 
                                                    height="40" 
                                                    width="40" 
                                                    radius="5"
                                                    color="#4fa94d" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )
                                        :
                                            'Create Account'
                                        }
                                    </button> 
                                )
                            :
                                <button className="btn btn-primary btn-block btn-lg" onClick={() =>{ createAccount()}}>
                                    Create Account
                                </button> 
                            }
                    </ul>
        
                </>
              )}
            </>
               }
            
        </>
    )
}
