import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import env from "react-dotenv";
import { ethers } from 'ethers';
import axios from 'axios';
import { formatDate, getDate, maskedAddresses } from '../utils/helpers';



export default function TransactionsItemComponent(props) {

    const [isFiatVisible, setFiatVisible] = useState(false);
    const [isCryptoVisible, setCryptoVisible] = useState(true);
    const [isEthVisible, setEthVisible] = useState(true);
    const [isUsdtVisible, setUsdtVisible] = useState(false);
    const [isBtcVisible, setBtcVisible] = useState(false);
    const apiKey = env.ETHERSCAN_API;
    const provider = new ethers.providers.EtherscanProvider(null, apiKey);
    const id = localStorage.getItem('prospayId')
    const userSeed = `prospaySeed${id}`;
    const passPhrase = localStorage.getItem(userSeed);
    const wallet = ethers.Wallet.fromMnemonic(passPhrase);
    const address = wallet.address
    // const address = '0x5569c5cad920412150E0a36CfD2fAA3dEeE93737'
    const [ethTransactions, setEthTransactions] = useState([])
    const [usdtTransactions, setUsdtTransactions] = useState([])
    const [btcTransactions, setBtcTransactions] = useState([])
    const [fiatTransactions, setFiatTransactions] = useState([])
    const userID = localStorage.getItem('prospayId')
    const app_running = env.APP_RUNNING;



    const getEthTransactions = useCallback(
        async() => {

            if (ethTransactions.length < 1) {
                try {
                    const history = await provider.getHistory(address);
                    setEthTransactions(history);
                    // console.log('Transaction History:', history);
                } catch (error) {
                    console.error('Error fetching transaction history:', error.message);
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ethTransactions])
    

    useEffect(() => {

        getEthTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEthTransactions])

    


    const getUsdtTransactions = useCallback(

        async function getTokenTransactionHistory() {

            if (usdtTransactions.length < 1) {
              
                try {
                    const response = await axios.get(`https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=${env.USDT_CONTRACT_ADDRS_LIVE}&address=${address}&startblock=0&endblock=999999999&sort=desc&apikey=${env.ETHERSCAN_API}`);

                    setUsdtTransactions(response.data.result);
                    // console.log(response.data.result);
                    // setLoading(false);
                  } catch (error) {
                    console.error('Error fetching transaction history:', error);
                    // setLoading(false);
                  }
            }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [usdtTransactions]
        
    )
    

    useEffect(() => {

        getUsdtTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUsdtTransactions])

    

    const getBtcTransactions = useCallback(
        async function getBtcTransactionHistory() {

            if (btcTransactions.length < 1) {
                const version = "v1"
                const protocol = "bitcoin"
                // const network = "mainnet"
                const network =  app_running === 'development' ? 'testnet' : 'mainnet';

                const address = localStorage.getItem(`sourceAddress${id}`)
                const HISTORY_ENDPOINT = `https://svc.blockdaemon.com/universal/${version}/${protocol}/${network}/account/${address}/txs`;

                axios.post(`${env.PROXY_URL}/api/users/getBTCHistory`, {UBIQUITY_API_KEY:env.UBIQUITY_API_KEY, HISTORY_ENDPOINT})
                .then(response => {
                    console.log('data:', response);
                    setBtcTransactions(response.data.data.data);
                })
                .catch(error => {
                    console.error('Error ', error.response);
                    return false;
                });
            }
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [btcTransactions]
    )
    
    

    useEffect(() => {

        getBtcTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBtcTransactions])




    // 090286231204035643453681033968

    const getFiatTransactions = useCallback(
        async() => {

            if (fiatTransactions.length < 1) {
                try{
                    const res = await axios.post(`${env.PROXY_URL}/api/users/getFiatTransactions`, {id:userID} )
                    const {data} = res;
        
                    if (res.data.success) {
                        setFiatTransactions(data.data);
                    } else {
                        setFiatTransactions([])
                    }
        
                }catch(error){
                    console.log(error);
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fiatTransactions])
    

    useEffect(() => {

        getFiatTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFiatTransactions])




    const handleCryptoClick = () => {
        setFiatVisible(false);
        setCryptoVisible(true);
    };

    const handleFiatClick = () => {
    setFiatVisible(true);
    setCryptoVisible(false);
    };

    const handleEthClick = () => {
        setEthVisible(true);
        setUsdtVisible(false);
        setBtcVisible(false);
    };

    const handleUsdtClick = () => {
        setEthVisible(false);
        setUsdtVisible(true);
        setBtcVisible(false);
    };

    const handleBtcClick = () => {
        setEthVisible(false);
        setUsdtVisible(false);
        setBtcVisible(true);
    };

    function convertToKSats(value) {
        if (value >= 1000000) {
            return Math.floor(value / 1000000) + 'M SATS';
        } else if (value >= 1000) {
            return Math.floor(value / 1000) + 'k SATS';
        } else {
            return value + ' SATS';
        }
    }

       


    return (
        <>
            <div className="row">
                <strong className="col-6 item" style={{ cursor:'pointer', backgroundColor: isCryptoVisible ? '#fff' : '#ccc' }} onClick={() => handleCryptoClick()}>Crypto</strong>
                <strong className="col-6 item" style={{ cursor:'pointer', marginBottom: '10px', backgroundColor: isFiatVisible ? '#fff' : '#ccc'   }}  onClick={() => handleFiatClick()}>Fiat</strong>

                {/* coin tabs */}
                {isCryptoVisible && <ul className="listview flush transparent simple-listview no-space mt-3" style={{ padding:'5px 10px' }}>
                    <li>
                        <span onClick={() => handleEthClick()} style={{ display:'inline-block', padding:'10px', border:'1px solid #6236FF', borderRadius:'3px', cursor:'pointer', backgroundColor: isEthVisible ? '#6236FF' : 'transparent' }}>
                            ETH
                        </span>

                        <span onClick={() => handleUsdtClick()} style={{ display:'inline-block', padding:'10px', border:'1px solid #6236FF', borderRadius:'3px', cursor:'pointer', backgroundColor: isUsdtVisible ? '#6236FF' : 'transparent' }}>
                            <span>USDT</span>
                        </span>

                        <span onClick={() => handleBtcClick()} style={{ display:'inline-block', padding:'10px', border:'1px solid #6236FF', borderRadius:'3px', cursor:'pointer', backgroundColor: isBtcVisible ? '#6236FF' : 'transparent' }}>
                            <span style={{ marginRight:"2px" }}>BTC</span>

                        </span>
                    </li>
                </ul>}

            </div>

            {/* eth transactions */}
            <div style={{ display: isCryptoVisible && isEthVisible ? 'block' : 'none' }}>

            {
                (ethTransactions.map((transaction, id) => {
                    return ( <Link key={id} to={`/transaction-crypto?txid=${transaction.hash}&from=${transaction.from}&to=${transaction.to}&date=${getDate(transaction.timestamp)}&amount=${transaction ? parseFloat(ethers.utils.formatEther(transaction.value._hex)).toFixed(4) : ''}&currency=ETH`} className="item">
                          <div className="detail">
                             {transaction.from === address ? 
                             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="16" cy="16" r="15.5" transform="rotate(-90 16 16)" fill="#FFECEE" stroke="#FCC4CA"/>
                                  <path d="M19.2497 14.0078L19.256 18.5144L21.0284 18.5144L21.0284 10.9719L13.486 10.9719L13.486 12.7444L17.9926 12.7507L10.3433 20.4L11.6003 21.6571L19.2497 14.0078Z" fill="#EB5757"/>
                              </svg> : 
                              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="16" cy="16" r="15.5" fill="#F0F9F1" stroke="#A6DDAC"/>
                                  <rect width="21.3333" height="21.3333" transform="translate(16.0001 0.915039) rotate(45)" fill="#F0F9F1"/>
                                  <path d="M12.7506 17.9925L12.7443 13.4858L10.9718 13.4858L10.9718 21.0283L18.5143 21.0283V19.2558L14.0077 19.2495L21.657 11.6002L20.3999 10.3431L12.7506 17.9925Z" fill="#6FCF97"/>
                              </svg>}
  
                              <div style={{ marginLeft:'10px'}}>
                                  <strong>{transaction.from === address ? 'Send' : 'Recieve'}</strong>
                                  <p>{transaction.from === address ? "To:" :"From:"} {transaction.from === address ? maskedAddresses(transaction.to ): maskedAddresses(transaction.from)}</p>
                              </div>
                          </div>
                          <div className="right">
                              <div className={`price text-${transaction.from === address ? "danger" :"success"}`}>{transaction.from === address ? "-" :"+"}  {transaction ? parseFloat(ethers.utils.formatEther(transaction.value._hex)).toFixed(4) : ''} ETH</div>
                              <p>{getDate(transaction.timestamp)}</p>
                          </div>
                      </Link>)
                })) 
                // : 
                // (<p style={{ textAlign:'center', margin:'10px 0' }}>No ETH transactions yet</p>)
                }
            </div>

            {/* usdt transactions */}
            <div style={{ display: isCryptoVisible && isUsdtVisible ? 'block' : 'none' }}>

                {

                (usdtTransactions.map((transaction, id) => {
                    return ( <Link key={id} to={`/transaction-crypto?txid=${transaction.hash}&from=${transaction.from}&to=${transaction.to}&date=${getDate(transaction.timeStamp)}&amount=${transaction.value ? parseFloat(ethers.utils.formatUnits(transaction.value, 6)).toFixed(2) : ''}&currency=USDT`} className="item">
                          <div className="detail">
                             {transaction.from === address ? 
                             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="16" cy="16" r="15.5" transform="rotate(-90 16 16)" fill="#FFECEE" stroke="#FCC4CA"/>
                                  <path d="M19.2497 14.0078L19.256 18.5144L21.0284 18.5144L21.0284 10.9719L13.486 10.9719L13.486 12.7444L17.9926 12.7507L10.3433 20.4L11.6003 21.6571L19.2497 14.0078Z" fill="#EB5757"/>
                              </svg> : 
                              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="16" cy="16" r="15.5" fill="#F0F9F1" stroke="#A6DDAC"/>
                                  <rect width="21.3333" height="21.3333" transform="translate(16.0001 0.915039) rotate(45)" fill="#F0F9F1"/>
                                  <path d="M12.7506 17.9925L12.7443 13.4858L10.9718 13.4858L10.9718 21.0283L18.5143 21.0283V19.2558L14.0077 19.2495L21.657 11.6002L20.3999 10.3431L12.7506 17.9925Z" fill="#6FCF97"/>
                              </svg>}
                              <div style={{ marginLeft:'10px'}}>
                                  <strong>{transaction.from === address ? 'Send' : 'Recieve'}</strong>
                                  <p>{transaction.from === address ? "To:" :"From:"} {transaction.from === address ? maskedAddresses(transaction.from ): maskedAddresses(transaction.to)}</p>
                              </div>
                          </div>
                          <div className="right">
                              <div className={`price text-${transaction.from === address ? "danger" :"success"}`}>{transaction.from === address ? "-" :"+"}  {transaction.value ? parseFloat(ethers.utils.formatUnits(transaction.value, 6)).toFixed(2) : ''} USDT</div>
                              <p>{getDate(transaction.timeStamp)}</p>
                          </div>
                      </Link>)
                }))
                // : 
                // (<p style={{ textAlign:'center', margin:'10px 0' }}>No USDT transactions yet</p>)
                }

            </div>

            {/* btc transactions */}
            <div style={{ display: isCryptoVisible && isBtcVisible ? 'block' : 'none' }}>
                {

                    (btcTransactions.map((transaction, index) => {
                        const events = transaction.events;
                        const inputEvents = events.filter(event => event.type === 'utxo_input');
                        const outputEvents = events.filter(event => event.type === 'utxo_output');
                        const inputMoreThanOne = inputEvents.length > 1;
                        const inputCount = inputEvents.length;
                        const outputCount = outputEvents.length;
                        const from = inputEvents.map(event => event.source);
                        const inputAmount = inputEvents.reduce((total, event) => total + event.amount, 0);
                        const outputAmount = outputEvents.reduce((total, event) => total + event.amount, 0);
                        const fee = events.find(event => event.type === 'fee').amount;
                        const fromVal = inputMoreThanOne ? inputCount +' inputs' : from[0];
                        const toVal = outputCount +' outputs';
                        const address = localStorage.getItem(`sourceAddress${id}`)
                        const expenses = outputEvents
                        .filter(event => event.destination !== address)
                        .reduce((total, event) => total + event.amount, 0);                        
                        const totalExpenses = expenses + fee;
                        const change = outputEvents
                        .filter(event => event.destination === address)
                        .reduce((total, event) => total + event.amount, 0);
                        const changePlusTotalExpenses = inputAmount + change;
                        console.log(expenses, fee, totalExpenses, change, address, id, changePlusTotalExpenses)


                        return ( <Link key={id} to={`/transaction-btc?txid=${transaction.id}&from=${fromVal}&to=${toVal}&fee=${fee}&date=${getDate(transaction.date)}&amount=${ false ? '-'+(change)/100000000 : '+'+(change)/100000000}`} className="item">
                            <div className="detail">
                                {false ? 
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="15.5" transform="rotate(-90 16 16)" fill="#FFECEE" stroke="#FCC4CA"/>
                                    <path d="M19.2497 14.0078L19.256 18.5144L21.0284 18.5144L21.0284 10.9719L13.486 10.9719L13.486 12.7444L17.9926 12.7507L10.3433 20.4L11.6003 21.6571L19.2497 14.0078Z" fill="#EB5757"/>
                                </svg> : 
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="15.5" fill="#F0F9F1" stroke="#A6DDAC"/>
                                    <rect width="21.3333" height="21.3333" transform="translate(16.0001 0.915039) rotate(45)" fill="#F0F9F1"/>
                                    <path d="M12.7506 17.9925L12.7443 13.4858L10.9718 13.4858L10.9718 21.0283L18.5143 21.0283V19.2558L14.0077 19.2495L21.657 11.6002L20.3999 10.3431L12.7506 17.9925Z" fill="#6FCF97"/>
                                </svg>}
                                <div style={{ marginLeft:'10px'}}>
                                    <div className={`price text-${false ? "danger" :"success"}`}>{false ? "-" :"+"}  {false ? (change)/100000000 : (change)/100000000} BTC</div>
                                    <p><span>ID:</span> {maskedAddresses(transaction.id)}</p>
                                    <p>{getDate(transaction.date)}</p>
                                </div>
                            </div>
                            <div className="right">
                                <p><span>FEE:</span> {convertToKSats(fee)}</p>
                                <p><span>FROM:</span> {fromVal.length > 15 ? maskedAddresses(fromVal) : fromVal}</p>
                                <p><span>TO:</span> {toVal}</p>
                            </div>
                        </Link>)
                    }))
                } 
            </div>

            <div style={{ display: isFiatVisible ? 'block' : 'none' }}>
                {fiatTransactions.map((transaction, id) => {
                    
                  return ( <Link key={id} to={`transaction-fiat?txid=${transaction.sessionid}`} className="item">
                        <div className="detail">
                           {transaction.type === 'Debit' ? 
                           <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="15.5" transform="rotate(-90 16 16)" fill="#FFECEE" stroke="#FCC4CA"/>
                                <path d="M19.2497 14.0078L19.256 18.5144L21.0284 18.5144L21.0284 10.9719L13.486 10.9719L13.486 12.7444L17.9926 12.7507L10.3433 20.4L11.6003 21.6571L19.2497 14.0078Z" fill="#EB5757"/>
                            </svg> : 
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="15.5" fill="#F0F9F1" stroke="#A6DDAC"/>
                                <rect width="21.3333" height="21.3333" transform="translate(16.0001 0.915039) rotate(45)" fill="#F0F9F1"/>
                                <path d="M12.7506 17.9925L12.7443 13.4858L10.9718 13.4858L10.9718 21.0283L18.5143 21.0283V19.2558L14.0077 19.2495L21.657 11.6002L20.3999 10.3431L12.7506 17.9925Z" fill="#6FCF97"/>
                            </svg>}

                            <div style={{ marginLeft:'10px'}}>
                                <strong>{transaction.type}</strong>
                                <p>{transaction.type === 'Debit' ? "To" :"From"} {transaction.account}</p>
                            </div>
                        </div>
                        <div className="right">
                            <div className={`price text-${transaction.type === 'Debit' ? "danger" :"success"}`}>{transaction.type === 'Debit' ? "-" :"+"} N {transaction.amount}</div>
                            <p>{formatDate(transaction.created_at)}</p>
                        </div>
                    </Link>)
                })}
            </div>

            {ethTransactions.length === 0 && isEthVisible && isCryptoVisible && <p style={{ textAlign:'center', margin:'10px 0' }}>No ETH transactions yet</p>}

            {usdtTransactions.length === 0 && isUsdtVisible && isCryptoVisible && <p style={{ textAlign:'center', margin:'10px 0' }}>No USDT transactions yet</p>}

        </>
    )
}
