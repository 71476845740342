import React from 'react'
import TransactionsItemComponent from './TransactionsItemComponent'

export default function TransactionItemsBoxCpmponent(props) {
    

    return (
        <div className="transactions">
            <TransactionsItemComponent/>
        </div>
    )
}
