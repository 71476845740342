// require('dotenv').config()
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const checkTokenValidity = (token) => {
  try {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (err) {
    return false;
  }
};


const ProtectedRoute = ({ component: Component }) => {
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      if (!checkTokenValidity(token)) {
        localStorage.removeItem('userToken');
        navigate('/login');
      }
    }else{
      navigate('/login');
    }
  }, [navigate]);

  return <Component />;
};

export default ProtectedRoute;