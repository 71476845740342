import React, { useEffect } from 'react'
import {maskedAddresses } from '../utils/helpers';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

export default function TransactionsCryptoDetailsPage() {

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    
    const txid = queryParams.get('txid');
    const from = queryParams.get('from');
    const to = queryParams.get('to');
    const date = queryParams.get('date');
    const amount = queryParams.get('amount');
    const currency = queryParams.get('currency');

    useEffect(() => {
        console.log(from, txid, to, amount, currency, date )
    }, [])
    return (
        <div className="section mt-2 mb-2">


            <div className="listed-detail mt-3">
                {/* <p className='text-center'>You recieved</p> */}
                <h3 className="text-center mt-2">{amount} {currency}</h3>
            </div>

            <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                    <strong>Status</strong>
                    <span className="text-success">completed</span>
                </li>
                <li>
                    <strong>From</strong>
                    <span>{maskedAddresses(from)}</span>
                </li>
                <li>
                    <strong>To</strong>
                    <span>{maskedAddresses(to)}</span>
                </li>
                <li>
                    <strong>Transaction Hash</strong>
                    <span>{maskedAddresses(txid)}</span>
                </li>
                <li>
                    <strong>Date</strong>
                    <span>{date}</span>
                </li>

            </ul>

            {/* <ul className="listview flush transparent simple-listview no-space mt-3">
                <li>
                    <span style={{ display:'inline-block', padding:'4px 3px', border:'1px solid #6236FF', borderRadius:'3px', cursor:'pointer' }}>
                        <span>view on explorer</span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3194 5.63341L11.3241 8.00008H12.6667L12.6667 3.33341H8.00008V4.67608L10.3667 4.68075L3.33341 11.7141L4.28608 12.6667L11.3194 5.63341Z" fill="#6236FF"/>
                        </svg>
                    </span>
                    <span style={{ display:'inline-block', padding:'4px 3px', border:'1px solid #6236FF', borderRadius:'3px', cursor:'pointer' }}>
                        <span>Share</span>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1666 14.6666C11.4452 14.675 10.7615 14.3454 10.3187 13.7758C9.87598 13.2062 9.7252 12.4623 9.91123 11.7653L5.7379 9.37997C5.01675 10.0407 3.95969 10.1805 3.09156 9.73004C2.22343 9.27959 1.72915 8.33479 1.85414 7.36478C1.97914 6.39476 2.69678 5.60613 3.65075 5.39047C4.60471 5.17481 5.5918 5.57805 6.1219 6.39997L9.91056 4.23397C9.86155 4.04876 9.83557 3.85821 9.83323 3.66664C9.82368 2.55112 10.5984 1.58207 11.6887 1.34585C12.779 1.10964 13.8853 1.67114 14.3383 2.69058C14.7913 3.71003 14.4666 4.90746 13.5605 5.55831C12.6545 6.20917 11.4161 6.1347 10.5946 5.37997L6.4939 7.72331C6.48984 7.89619 6.46567 8.06801 6.4219 8.23531L10.5946 10.62C11.3627 9.915 12.5057 9.80618 13.3932 10.3535C14.2806 10.9009 14.6963 11.9711 14.4111 12.974C14.1259 13.9769 13.2092 14.6682 12.1666 14.6666ZM12.1666 11.3333C11.6143 11.3333 11.1666 11.781 11.1666 12.3333C11.1666 12.8856 11.6143 13.3333 12.1666 13.3333C12.7188 13.3333 13.1666 12.8856 13.1666 12.3333C13.1666 11.781 12.7188 11.3333 12.1666 11.3333ZM4.16656 6.66664C3.61428 6.66664 3.16656 7.11436 3.16656 7.66664C3.16656 8.21892 3.61428 8.66664 4.16656 8.66664C4.71885 8.66664 5.16656 8.21892 5.16656 7.66664C5.16656 7.11436 4.71885 6.66664 4.16656 6.66664ZM12.1666 2.66664C11.6143 2.66664 11.1666 3.11435 11.1666 3.66664C11.1666 4.21892 11.6143 4.66664 12.1666 4.66664C12.7188 4.66664 13.1666 4.21892 13.1666 3.66664C13.1666 3.11435 12.7188 2.66664 12.1666 2.66664Z" fill="#6236FF"/>
                        </svg>

                    </span>

                    <span style={{ display:'inline-block', padding:'4px 3px', border:'1px solid #6236FF', borderRadius:'3px', cursor:'pointer' }}>
                        <span style={{ marginRight:"2px" }}>save</span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1103_8398)">
                            <path d="M14 8H12C11.2647 8 10.6667 8.598 10.6667 9.33333C10.6667 10.0687 10.0687 10.6667 9.33333 10.6667H6.66667C5.93133 10.6667 5.33333 10.0687 5.33333 9.33333C5.33333 8.598 4.73533 8 4 8H2C0.897333 8 0 8.89733 0 10V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667V10C16 8.89733 15.1027 8 14 8ZM14.6667 12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V10C1.33333 9.632 1.632 9.33333 2 9.33333L4 9.332V9.33333C4 10.804 5.196 12 6.66667 12H9.33333C10.804 12 12 10.804 12 9.33333H14C14.368 9.33333 14.6667 9.632 14.6667 10V12.6667ZM4.862 4.74733C4.60133 4.48667 4.60133 4.06533 4.862 3.80467C5.12267 3.544 5.544 3.544 5.80467 3.80467L7.33333 5.33333V0.666667C7.33333 0.298 7.63133 0 8 0C8.36867 0 8.66667 0.298 8.66667 0.666667V5.33333L10.1953 3.80467C10.456 3.544 10.8773 3.544 11.138 3.80467C11.3987 4.06533 11.3987 4.48667 11.138 4.74733L8.94267 6.94267C8.68467 7.20067 8.34533 7.33067 8.006 7.332L8 7.33333L7.994 7.332C7.65467 7.33067 7.31533 7.20067 7.05733 6.94267L4.862 4.74733Z" fill="#6236FF"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1103_8398">
                            <rect width="16" height="16" fill="#6236FF"/>
                            </clipPath>
                            </defs>
                        </svg>

                    </span>
                </li>
            </ul> */}



        </div>
    )
}
