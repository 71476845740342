import React from 'react'
import { Outlet, useLocation } from "react-router-dom";
import AppFooterCardComponent from '../components/AppFooterCardComponent';

export default function Layout(props) {
    let location = useLocation()

    return (
        <div id="appCapsule">

            <Outlet />
            <>
                {location.pathname === '/login' || location.pathname === '/register'  || location.pathname === '/verifyMail'  || location.pathname === '/verifyAccount'  || location.pathname === '/forgotPassword' || location.pathname === '/passwordReset' || location.pathname === '/ChangePassword' || location.pathname === '/chooseWallet' || location.pathname === '/newWallet'  || location.pathname === '/retriveWallet' ? '' : <AppFooterCardComponent/>}
            </>
            
        </div>
    )
}
