import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import env from "react-dotenv";


export default function ChangePasswordPage(props) {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");

    const initialValues = {
        password: '',
        id: id
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/,
            'Password must contain at least one capital letter, one special character, one number, and be at least 8 characters long'
        )
        .required('Password is required')
    });

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        axios
        .post(`${env.PROXY_URL}/api/users/forgotPassword`, values)
        .then(res => { 
            console.log(res.data);

            if(res.data.success){
                setSubmitting(false);
                // resetForm();
                toast('Password reset Succesful')
                setTimeout(() => {
                    toast('you will be redirected to login shortly')
                }, 2000)
                setTimeout(() => {
                    navigate('/login')
                }, 5000)
            }else{
                toast(res.data.data)
                setSubmitting(false);
            }
            
        })
        .catch(err => {
            console.error(err);
            setSubmitting(false);
        });
                
    };

    return (
        <>
            <div className="section mt-2 text-center">
            <h1>Change Password</h1>
            <h4>Enter a new password</h4>
        </div>
        <div className="section mb-5 p-2">

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="card">
                            <div className="card-body pb-1">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="password">New Password</label>
                                        <Field type={showPassword ? "text" : "password"} className="form-control" id="password" placeholder="Your new password" name="password"/>
                                        <i className="clear-input" style={{display:'block'}} onClick={() => setShowPassword(!showPassword)}>
                                            <ion-icon name={showPassword ? "eye-off" : "eye"}></ion-icon>
                                        </i>
                                        <ErrorMessage name="password" component="div" style={{color:'#FF396F'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="card-body pb-1"> 
                            <Link to="/login" className="headerButton">
                                    Login
                            </Link>                        
                        </div>

                        <div className="form-button-group transparent">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg">Change</button>
                        </div>

                    </Form>
                )}
            </Formik>
        </div>
        </>
    )
}

