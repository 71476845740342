import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'
import env from "react-dotenv";


const registrationSchema = Yup.object({
    firstname: Yup.string().required('Firstname is required'),
    lastname: Yup.string().required('Lastname is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string()
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s\./0-9]*$/, 'Invalid phone number, see required see format eg:(+234 906 7754 345)')
      .required('Phone number is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    acceptPrivacyPolicy: Yup.boolean()
        .oneOf([true], 'Acceptance of privacy policy is required')
        .required('Acceptance of privacy policy is required'),
});

// return `${<EmailTemplateComponent subject={subject} firstname={firstname} text={mailBody} />}`
const mailContent = (subject, firstname, mailBody) => {
    return (
        `
            <html>

                <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                    <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0;" />
                    <link href='https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700' rel="stylesheet" />
                    <link href='https://fonts.googleapis.com/css?family=Quicksand:300,400,700' rel="stylesheet" />

                    <title>${subject}</title>

                    <style>
                        body {
                            width: 100%;
                            background-color: #ffffff;
                            margin: 0;
                            padding: 0;
                            -webkit-font-smoothing: antialiased;
                            mso-margin-top-alt: 0px;
                            mso-margin-bottom-alt: 0px;
                            mso-padding-alt: 0px 0px 0px 0px;
                        }p,
                        h1,
                        h2,
                        h3,
                        h4 {
                            margin-top: 0;
                            margin-bottom: 0;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        
                        span.preheader {
                            display: none;
                            font-size: 1px;
                        }
                        
                        html {
                            width: 100%;
                        }
                        
                        table {
                            font-size: 14px;
                            border: 0;
                        }
                        /* ----------- responsivity ----------- */
                        
                        @media only screen and (max-width: 640px) {
                            /*------ top header ------ */
                            .main-header {
                                font-size: 20px !important;
                            }
                            .main-section-header {
                                font-size: 28px !important;
                            }
                            .show {
                                display: block !important;
                            }
                            .hide {
                                display: none !important;
                            }
                            .align-center {
                                text-align: center !important;
                            }
                            .no-bg {
                                background: none !important;
                            }
                            /*----- main image -------*/
                            .main-image img {
                                width: 440px !important;
                                height: auto !important;
                            }
                            /* ====== divider ====== */
                            .divider img {
                                width: 440px !important;
                            }
                            /*-------- container --------*/
                            .container590 {
                                width: 440px !important;
                            }
                            .container580 {
                                width: 400px !important;
                            }
                            .main-button {
                                width: 220px !important;
                            }
                            /*-------- secions ----------*/
                            .section-img img {
                                width: 320px !important;
                                height: auto !important;
                            }
                            .team-img img {
                                width: 100% !important;
                                height: auto !important;
                            }
                        }
                        
                        @media only screen and (max-width: 479px) {
                            /*------ top header ------ */
                            .main-header {
                                font-size: 18px !important;
                            }
                            .main-section-header {
                                font-size: 26px !important;
                            }
                            /* ====== divider ====== */
                            .divider img {
                                width: 280px !important;
                            }
                            /*-------- container --------*/
                            .container590 {
                                width: 280px !important;
                            }
                            .container590 {
                                width: 280px !important;
                            }
                            .container580 {
                                width: 260px !important;
                            }
                            /*-------- secions ----------*/
                            .section-img img {
                                width: 280px !important;
                                height: auto !important;
                            }
                        }
                    </style>
                    
                </head>

                <body className="respond" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">
                    <table style="display:none!important;">
                        <tr>
                            <td>
                                <div style="overflow:hidden;display:none;font-size:1px;color:#ffffff;line-height:1px;font-family:Arial;maxheight:0px;max-width:0px;opacity:0;">
                                    ${subject}
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff">

                        <tr>
                            <td align="center">
                                <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">

                                    <tr>
                                        <td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="center">

                                            <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">

                                                <tr>
                                                    <td align="center" height="70" style="height:70px;">
                                                        <a href="" style="display: block; border-style: none !important; border: 0 !important;"><img width="100" border="0" style="display: block; width: 300px;" src="https://prospay.io/assets/app_img/logo.png" alt="" /></a>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                    </table>


                    <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff" className="bg_color">

                        <tr>
                            <td align="center">
                                <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">

                                    <tr>
                                        <td align="center" style="color: #343434; font-size: 24px; font-family: Quicksand, Calibri, sans-serif; font-weight:700;letter-spacing: 3px; line-height: 35px;"
                                            className="main-header">

                                            <div style="line-height: 35px">

                                                ${subject}

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="center">
                                            <table border="0" width="40" align="center" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                                <tr>
                                                    <td height="2" style="font-size: 2px; line-height: 2px;">&nbsp;</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="20" style="font-size: 20px; line-height: 20px;">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left">
                                            <table border="0" width="590" align="center" cellpadding="0" cellspacing="0" className="container590">
                                                <tr>
                                                    <td align="left" style="color: #888888; font-size: 16px; font-family: 'Work Sans', Calibri, sans-serif; line-height: 24px;">

                                                        <p style="line-height: 24px; margin-bottom:15px;">

                                                            Dear ${firstname},

                                                        </p>

                                                        ${mailBody}

                                                        <p style="line-height: 24px">
                                                            Love,<br/>
                                                            The Prospay team.
                                                        </p>

                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>





                                </table>

                            </td>
                        </tr>

                        <tr>
                            <td height="40" style="font-size: 40px; line-height: 40px;">&nbsp;</td>
                        </tr>

                    </table>



                    <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="2a2e36">

                        <tr>
                            <td align="center" style="background-image: url(https://prospay.io/assets/app_img/footer-card.png); background-size: cover; background-position: top center; background-repeat: no-repeat;"
                                background="https://prospay.io/assets/app_img/footer-card.png">

                                <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" className="container590">

                                    <tr>
                                        <td height="50" style="font-size: 50px; line-height: 50px;">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="center">
                                            <table border="0" width="380" align="center" cellpadding="0" cellspacing="0" style="border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;"
                                                className="container590">

                                                <tr>
                                                    <td align="center">
                                                        <table border="0" align="center" cellpadding="0" cellspacing="0" className="container580">
                                                            <tr>
                                                                <td align="center" style="color: #cccccc; font-size: 16px; font-family: 'Work Sans', Calibri, sans-serif; line-height: 26px;">

                                                                    <div style="line-height: 26px">

                                                                        Visit out Website

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="center">
                                            <table border="0" align="center" width="250" cellpadding="0" cellspacing="0" style="border:2px solid #ffffff;">

                                                <tr>
                                                    <td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>
                                                </tr>

                                                <tr>
                                                    <td align="center" style="color: #ffffff; font-size: 14px; font-family: 'Work Sans', Calibri, sans-serif; line-height: 22px; letter-spacing: 2px;">

                                                        <div style="line-height: 22px;">
                                                            <a target="_blank" href="https://prospay.io" style="color: #fff; text-decoration: none;">Go to prospay.io</a>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td height="50" style="font-size: 50px; line-height: 50px;">&nbsp;</td>
                                    </tr>

                                </table>
                            </td>
                        </tr>

                    </table>


                    

                    

                </body>

            </html>
        `
    )
}

export default function RegisterPage(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [code, setCode] = useState(null);
    const navigate = useNavigate();

    function generateCode() {
        setCode(Math.floor(100000 + Math.random() * 900000).toString());
        return code
    }

    const initialValues = { 
        firstname: '', 
        lastname: '', 
        email: '', 
        phone: '', 
        password: '', 
        acceptPrivacyPolicy: false 
    }

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        // request to register user
        axios.post(`${env.PROXY_URL}/api/users`, values)
        .then(function (res) {

            if(res.data.success){
                const code = Math.floor(100000 + Math.random() * 900000).toString()
                setCode(code)
                if (code) {
                    const subject = "Welcome to Prospay"
                    const firstname = values.firstname
                    const email = values.email
                    const mailBody = 
                    `
                        <p style="line-height: 24px;margin-bottom:15px;">Thank you for signing up with Prospay. We need you to confirm your email address, so we know you are reachable at this address.</p>
                        <p style="line-height: 24px;margin-bottom:15px;">Your Email Verification Code is:</p>
                        <p style="line-height: 24px;margin-bottom:15px;">${code}</p>
                        <p style="line-height: 24px;margin-bottom:15px;">We are always available to answer your questions or provide you with clarifications. You can reach us via email at <a href="mailto:support@prospay.io">support@prospay.io</a>.</p>
                    `
                    const html = mailContent(subject, firstname, mailBody)
                    const insertID = res.data.data.insertId
                    const emailData = {
                        firstname: firstname,
                        subject: subject,
                        html : html, // mail content
                        email: email, // reciever
                        code: code, // verification code
                        id: insertID // current user id
                    }
                    toast('Registration successful.')
                    
                    // request to send welcome mail to user
                    axios.post(`${env.PROXY_URL}/api/users/welcome`, emailData)
                    .then(function (res) {
                        setTimeout(() => {
                            toast('Verification Email sent succefully. You will be redirected shortly.')
                        }, 2000);

                        setTimeout(() => {
                            navigate(`/verifyMail?id=${insertID}`)
                        }, 5000);
                    })
                    .catch(function (error) {
                        console.log(error);                    
                    });

                    resetForm({});
                    setSubmitting(false);
                } else {
                    console.log(code)
                }
                
            }else if(res.data.success === 0){
                toast(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }else {
                console.log(res)
            }
            // setSubmitting(false);
        })
        .catch(function (error) {
            console.log(error);
            toast('Something went wrong: ' + error.message + ', Try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            setSubmitting(false);
        });
        
    };

    return (
        <>
            <div className="section mt-2 text-center">
                <h1>Register now</h1>
                <h4>Create an account</h4>
            </div>
            <div className="section mb-5 p-2">

                <Formik
                    initialValues={initialValues}
                    validationSchema={registrationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="card">
                                <div className="card-body">                            
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="firstname">Firstname</label>
                                            <Field type="text" className="form-control" id="firstname" placeholder="Your Firstname" name="firstname" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <ErrorMessage name="firstname" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>     

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="lastname">Lastname</label>
                                            <Field type="text" className="form-control" id="lastname" placeholder="Your Lastname" name="lastname" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <ErrorMessage name="lastname" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="email1">E-mail</label>
                                            <Field type="email" className="form-control" id="email1" placeholder="Your e-mail" name="email" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <ErrorMessage name="email" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>  

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="phone">Phone</label>
                                            <Field type="text" className="form-control" id="phone" placeholder="eg : +234 906 7754 345" name="phone" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <ErrorMessage name="phone" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="password">Password</label>
                                            <Field type={showPassword ? "text" : "password"}  className="form-control" id="password" name="password" autoComplete="off" placeholder="Your password" />
                                            <i className="clear-input" style={{display:'block'}} onClick={() => setShowPassword(!showPassword)}>
                                                <ion-icon name={showPassword ? "eye-off" : "eye"}></ion-icon>
                                            </i>
                                            <ErrorMessage name="password" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>

                                    <div className="custom-control custom-checkbox mt-2 mb-1">
                                        <div className="form-check">
                                            <Field type="checkbox" 
                                            className="form-check-input" id="customCheckb1" name="acceptPrivacyPolicy"/>
                                            <label className="form-check-label" htmlFor="customCheckb1">
                                                I agree <a href="/" data-bs-toggle="modal" data-bs-target="#termsModal">terms and
                                                    conditions</a>
                                            </label>
                                            <ErrorMessage name="acceptPrivacyPolicy" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="form-button-group transparent">
                                <button  type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg">{isSubmitting 
                                    ? 
                                        (
                                            <ThreeDots 
                                                height="40" 
                                                width="40" 
                                                radius="5"
                                                color="#4fa94d" 
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )
                                    :
                                        'Register'
                                    }</button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>


            {/* Terms Modal */}
             <div className="modal fade modalbox" id="termsModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Terms and Conditions</h5>
                            <a href="/" data-bs-dismiss="modal">Close</a>
                        </div>
                        <div className="modal-body">
                            <h2>1. Account Registration</h2>
                            <ul>
                                <li>You must be at least 18 years old to use Prospay.</li>
                                <li>You must provide accurate and complete information during the registration process.</li>
                                <li>You are responsible for maintaining the confidentiality of your account login information.</li>
                            </ul>

                            <h2>2. Wallet Services</h2>
                            <ul>
                                <li>Prospay is a web wallet that allows you to buy, send and swap crypto currency.</li>
                                <li>We do not guarantee the accuracy of market information or exchange rates displayed on the platform.</li>
                                <li>We reserve the right to suspend or terminate your account and/or access to our services at any time for any reason, without liability to you.</li>
                            </ul>

                            <h2>3. Risks and Limitations</h2>
                            <ul>
                                <li>Crypto currency is a volatile and risky investment. You should carefully consider the risks and limitations of investing in crypto currency before using Prospay.</li>
                                <li>Prospay does not bear any responsibility for any loss, damages or expenses that may result from your use of our services.</li>
                                <li>You are solely responsible for any taxes that may be applicable to your transactions and investments.</li>
                            </ul>

                            <h2>4. Indemnification</h2>
                            <ul>
                                <li>You agree to indemnify and hold harmless Prospay, its affiliates, directors, officers, employees, agents, and licensors from any claims, damages, liabilities, and expenses arising out of your use of our services or breach of these terms and conditions.</li>
                            </ul>

                            <h2>5. Amendments</h2>
                            <ul>
                                <li>We reserve the right to amend these terms and conditions at any time, without prior notice to you.</li>
                                <li>Your continued use of Prospay after any amendments to these terms and conditions constitutes your acceptance of the amended terms and conditions.</li>
                            </ul>

                            <h2>6. Governing Law</h2>
                            <ul>
                                <li>These terms and conditions shall be governed by and construed in accordance with the laws of [insert governing law here].</li>
                                <li>Any disputes arising out of or in connection with these terms and conditions shall be resolved through arbitration in accordance with the rules of [insert arbitration body here].</li>
                            </ul>

                            <p>By checking "I Agree" box, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them.</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
