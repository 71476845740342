import React from 'react'
import AppFooterItemComponent from './AppFooterItemComponent'

export default function AppFooterCardComponent(props) {
    

    return (
        <div className="appBottomMenu">
            <AppFooterItemComponent/>
        </div>
    )
}
