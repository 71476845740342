import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import env from 'react-dotenv';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import bankCodes from '../utils/bankCodes.js'
import axiosPrivate from '../utils/axiosPrivate.js';
import { GET_BANKS, TRANSFER, VERIFY_ACCOUNT } from '../utils/constants.js';
import { Link } from 'react-router-dom';
import allBanks from '../utils/allBanks.js';


export default function TransferCashComponent(props) {
    const [loading, setLoading] = useState(true)
    const [isVerified, setIsVerified] = useState(false);
    const [acctName, setAcctName] = useState('');
    const [acctNumber, setAcctNumber] = useState('');
    const [acctBankCode, setAcctBankCode] = useState('');
    const [acctBankName, setAcctBankName] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [amount, setAmount] = useState('');
    const [bank, setBank] = useState()
    const [dropBank, setDropBank] = useState(false);
    const [selectedBankName, setSelectedBankName] = useState('');
    const [filteredBank, setFilteredBank] = useState([]);
    const [bankCode, setBankCode] = useState('');


    const getBanks =  useCallback(() => {
        if (acctBankCode) {
            const filteredBank = allBanks.filter(item => item.bankCode === acctBankCode)
            setBank(filteredBank[0].name)
            console.log(filteredBank)

        }
    }, [acctBankCode])

    useEffect(() => {
        getBanks()
    }, [getBanks])
    
    function commifyNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    function handleAmountChange(event) {
      const inputAmount = event.target.value.replace(/,/g, ''); // Remove existing commas
      const formattedAmount = commifyNumber(inputAmount);
      setAmount(formattedAmount);
    }

    const [errorBanks, setErrorBanks] = useState(null)
    const [bankList, setBankList] = useState([]);
    const [loadingBanks, setLoadingBanks] = useState(false)
    
    useEffect(() => {

        setLoadingBanks(true)

        const fetchBankList = async() => {
            // alert('Loading')
            try {
                const response = await axiosPrivate.get(`${GET_BANKS}`);
                const { data } = response;
                if (data.statusCode === 200) {
                    console.log(data.data);
                
                    setBankList(data.data)
                    setLoadingBanks(false);
                
                } else {
                    console.log(data);
                    setLoadingBanks(false);
                //   toast(data.message);
                    console.log(data.message);
                    setErrorBanks('Banks not fetched.');
                }
            } catch (error) {
                setLoadingBanks(false);
                console.log(error);
                setErrorBanks('Banks not fetched.');

                if (error.response) {
                    // toast(error.response.data.message);
                    console.log(error.response.data.message);
                }else {
                    // toast(error.message);
                    console.log(error.message);
                }
            }
        }

        fetchBankList()

    }, [])

    function filterBank(e) {
        const userInput = e.toLowerCase();
    
        const filteredAndSortedArray = bankList
          .filter((item) => {
            const itemName = item.name.toLowerCase();
            return itemName.includes(userInput);
          })
          .sort((a, b) => {
            const indexOfA = a.name.toLowerCase().indexOf(userInput);
            const indexOfB = b.name.toLowerCase().indexOf(userInput);
    
            if (indexOfA === indexOfB) {
              return a.name.length - b.name.length;
            }
            return indexOfA - indexOfB;
          });
    
        setFilteredBank(filteredAndSortedArray);
        // You can use the sorted array to update your UI or perform other actions
      }
    


    // const [errorAccount, setErrorAccount] = useState(null)
    // const [accountData, setAccountData] = useState()
    // const [loadingAccount, setLoadingAccount] = useState(false)
    // const [userHasAccount, setUserHasAccount] = useState(false)
    const userID = localStorage.getItem('prospayId')
    const naira_bal = localStorage.getItem('naira_bal')

    
    // useEffect(() => {
    //     const accountId = localStorage.getItem(`account_id${userID}`)

    //     if (accountId) {
    //         setLoadingAccount(true)
    //         setUserHasAccount(true)

    //         const fetchBankAccount = async() => {
    //             try {
    //                 const response = await axiosPrivate.get(`accounts/${accountId}`);
    //                 const { data } = response;
    //                 if (data.statusCode === 200) {
    //                   console.log(data.data);
                    
    //                   setAccountData(data.data)
    //                   setLoadingAccount(false);
                    
    //                 } else {
    //                   console.log(data);
    //                   setLoadingAccount(false);
    //                 //   toast(data.message);
    //                   console.log(data.message);
    //                   setErrorAccount('Account not fetched.');
    //                 }
    //             } catch (error) {
    //                 setLoadingAccount(false);
    //                 console.log(error);
    //                 setErrorAccount('Account not fetched.');

    //                 if (error.response) {
    //                     // toast(error.response.data.message);
    //                     console.log(error.response.data.message);
    //                 }else {
    //                     // toast(error.message);
    //                     console.log(error.message);
    //                 }
    //             }
    //         }

    //         fetchBankAccount()
            
    //     } else {
    //         setUserHasAccount(false)
    //         setAccountData({})
    //     }
        
    // }, [userHasAccount])

    

    const initialAccountValues = {
        bankCode: '',
        accountNumber: '',
    };

    const AcctVerificationSchema = Yup.object().shape({
        
        bankCode: Yup.string()
        .required('Select from the list')
        .test('bank-validation', 'Invalid bank selection', value => {
            return bankCodes.includes(value);
          }),
        accountNumber: Yup.string()
        .required('account number is required')
        .matches(/^[0-9]+$/, 'Account number must contain only numbers')
        .min(10, 'Account number not complete')
        .max(10, 'Account number can have a maximum of 10 digits'),

    });

    const verifyAccount = (values, { setSubmitting, resetForm }) => {
        axiosPrivate.post(`${VERIFY_ACCOUNT}`, values)
        .then(res => { 
            
            const { data } = res;
            if (data.statusCode === 200) {
                setIsVerified(true)
                setAcctNumber(res.data.data.accountNumber)
                setAcctBankCode(res.data.data.bankCode)
                setAcctName(res.data.data.accountName)
                setSessionId(res.data.data.sessionId)
                setSubmitting(false);
                console.log(isVerified)
                // console.log(res.data, isVerified, initialTranferValues);

                resetForm();
            }else{
                toast(res.data.message)
                setSubmitting(false);
            }
        })
        .catch(err => {
            console.log(err);
            setSubmitting(false);
        }); 
    }


    const initialTranferValues = {
        nameEnquiryReference:sessionId,
        debitAccountNumber:'8018289392',
        beneficiaryBankCode:acctBankCode,
        beneficiaryAccountNumber:acctNumber,
        saveBeneficiary:false,
        amount: '',
        narration: '',
        paymentReference:`${Date.now()}-${acctBankCode}-${acctNumber}`
    };
    const TransferCashSchema = Yup.object().shape({
        
        amount: Yup.number()
            .typeError('Amount must be a number')
            .min(10, 'Amount must be greater than or equal to 10')
            .max(naira_bal, `Amount must be less than or equal to ${naira_bal}`)
            .required('Amount is required'),
        narration: Yup.string().max(100, 'Description must not exceed 100 characters'),
        });

    const TransferCash = (values, { setSubmitting, resetForm }) => {
        console.log(values)
        axiosPrivate.post(`${TRANSFER}`, values)
        .then(res => { 
            console.log(res.data);
            const { data } = res;
            if (data.statusCode === 200) {
                console.log(res.data.data);

                const payload = {
                    type: "Debit",
                    userid: userID,
                    sessionid: data.data.sessionId,
                    account: data.data.creditAccountNumber,
                    amount: data.data.amount,
                }

                console.log (payload)

                // save transaction history
                axios.post(`${env.PROXY_URL}/api/users/addTransaction`, payload)
                .then(res => {
                    if (res.data.success) {
                        console.log (payload)
                    }
                })
                .catch(err => {
                    console.log(err)
                })


                // update naira balance
                axios.post(`${env.PROXY_URL}/api/users/nairaTopup`, {
                    naira_bal: naira_bal - values.amount,
                    id:userID
                })
                .then(res => {
                    if (res.data.success) {


                        localStorage.setItem('naira_bal', naira_bal - values.amount);

                        setSubmitting(false);
                        toast(`Transfer successful....`);
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                        
                    }
                })
                .catch(err => {
                    console.log(err)
                })

            }else{
                toast(res.data.message)
                setSubmitting(false);
            }
        })
        .catch(err => {
            console.log(err);
            setSubmitting(false);
        }); 
    }
    

    useEffect(()=> {
        console.log(loading)
        setLoading(false)
        console.log(loading)
    },[loading])

    

    return (
        <>

            {loading ?
            <div id="loader">
                <img src="assets/img/loading-icon.png" alt="icon" className="loading-icon"/>
            </div>
            :
            <>
            <div className="section mt-2">
                <h3 className="text-center">Withdraw Money</h3>
            
                <div className="action-sheet-content">
                    {!isVerified &&
                    <div id='verify'>
                        <Formik
                        initialValues={initialAccountValues}
                        validationSchema={AcctVerificationSchema}
                        onSubmit={verifyAccount}
                        >
                        {({ isSubmitting, setFieldValue }) => (

                            
                            <Form>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text11d">To Account</label>
                                        <Field type="text" className="form-control" id="text11d" placeholder="Account Number" name="accountNumber"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                    <ErrorMessage name="accountNumber" component="div" style={{color:'#FF396F'}}/>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text11d">To Bank</label>
                                    </div>
                                </div>

                                {/* <div className="listview-title mt-1" style={{ padding:'0px', color:'#fff', fontSize: '11px' }}>To Bank</div> */}

                                <ul className="listview image-listview text mb-2 inset" style={{ margin:'0px', borderBottom:'1px solid #372648', borderRadius:'0', cursor:'pointer' }} 
                                onClick={() => {
                                    setDropBank(!dropBank);
                                    }}>
                                    
                                    <li style={{ padding:'10px 0px' }} className="item">
                                    
                                        {
                                            // @ts-ignore
                                            selectedBankName ? selectedBankName : 'Select Bank'
                                        }

                                        <svg
                                            style={{ fill: '#6236FF' }} className="fill-white hover:fill-yellow-40 active:fill-gray-80"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <g clipPath="url(#clip0_872_770)">
                                                <path d="M12 16L6 10H18L12 16Z" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_872_770">
                                                <rect width="24" height="24" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </li>
                                </ul>

                                {loadingBanks ? '' : 

                                <div className="form-group basic" style={{ display:`${dropBank? 'block': 'none'}`,  }}>
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" id="bank" placeholder="Search Bank" 
                                        onChange={(e) => {
                                            filterBank(e.target.value);
                                        }}/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                    <ErrorMessage name="bankCode" component="div" style={{color:'#FF396F'}}/>

                                </div>}

                                <div className="form-group basic" style={{ display:`${dropBank? 'block': 'none'}`,  }}>
                                    <div className="input-group mb-2">
                                        {loadingBanks ? 'loading...' : 
                                        <>
                                            
                                            {
                                                <div className="relative my-3 w-full md:w-1/2 ">
                                                
                        
                                                <Field type="hidden" name="bankCode" id="bankCode" />
                        
                                                <Field type="hidden" name="bankName" id="bankName" />
                        
                                                
                        
                                                <div
                                                    style={{ 
                                                    height:'200px', overflow:'scroll'
                                                    }}
                                                >
                                                    <div className="flex flex-col">
                                                    
                                                    <ul style={{ textDecoration: 'none' }}>
                                                        {filteredBank.length === 0 &&
                                                        bankList.map((bank) => (
                                                            <li
                                                            onClick={() => {
                                                                setDropBank(!dropBank);
                                                                setSelectedBankName(bank.name);
                                                                setAcctBankName(bank.name)
                                                                setFieldValue('bankCode', bank.bankCode);
                                                                setFieldValue('bankName', bank.name);
                                                                setBankCode(bank.bankCode);
                                                            }}
                                                            style={{ marginBottom: '10px', padding: '0px' }}
                                                            key={bank.bankCode}
                                                            >
                                                            <p className="text-[14px] text-green-success">
                                                                <span className="inline-block pl-1">{bank.name}</span>
                                                            </p>
                                                            </li>
                                                        ))}

                                                        {filteredBank.length > 0 &&
                                                        filteredBank.map((bank) => (
                                                            <li
                                                            onClick={() => {
                                                                setDropBank(!dropBank);
                                                                setSelectedBankName(bank.name);
                                                                setAcctBankName(bank.name)
                                                                setFieldValue('bankCode', bank.bankCode);
                                                                setFieldValue('bankName', bank.name);
                                                                setBankCode(bank.bankCode);
                                                            }}
                                                            style={{ marginBottom: '10px', padding: '0px' }}
                                                            key={bank.bankCode}
                                                            >
                                                            <p className="text-[14px] text-green-success">
                                                                <span className="inline-block pl-1">{bank.name}</span>
                                                            </p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    </div>
                                                </div>
                        
                                                </div>
                                            }
                                        </>}

                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <button className="btn btn-primary btn-block btn-lg" type="submit" disabled={isSubmitting} >verify</button>
                                </div>
                            </Form>
                            )}
                        </Formik>
                    </div>}
                    

                    {isVerified &&
                        <div id='transfer' >

                        <Formik
                            initialValues={initialTranferValues}
                            validationSchema={TransferCashSchema}
                            onSubmit={TransferCash}
                        >
                        

                        {({ isSubmitting, setFieldValue }) => (
                            <Form>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="account2d">To</label>
                                        <input readOnly value={acctName} className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="account2d">Account Number</label>
                                        <input readOnly value={acctNumber} className="form-control"/>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="account2d">Bank Name</label>
                                        <input readOnly value={bank} className="form-control"/>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <label className="label" htmlFor="amount">Amount</label>
                                    <div className="input-group mb-2">
                                        <Field name="amount" id="amount" type="number" className="form-control" placeholder="Enter an amount"
                                        // onBlur={(e) => {
                                        //     handleAmountChange(e)
                                        // }} 
                                        />
                                    </div>
                                    <ErrorMessage name="amount" component="div" style={{color:'#FF396F'}}/>
                                </div>

                                <div className="form-group basic">
                                    <label className="label" htmlFor="narration">Description</label>
                                    <div className="input-group mb-2">
                                        <Field name="narration" id="narration" type="text" className="form-control" placeholder="optional"
                                            />
                                    </div>
                                    <ErrorMessage name="narration" component="div" style={{color:'#FF396F'}}/>
                                </div>

                                <div className="form-group basic">
                                    <button type="submit" name="submit" disabled={isSubmitting}  className="btn btn-primary btn-block btn-lg" >Withdraw</button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </div>}

                </div>
                            
            </div>
            </>}
            
        </>
    )
}
