import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppHeaderComponent from "./components/AppHeaderComponent";
import Layout from "./pages/Layout";
import ProtectedRoute from './pages/ProtectedRoute';
import DarkMode from './DarkMoodSetting.js'
import HomePage from "./pages/HomePage";
import Error404Page from "./pages/Error404Page";
import SettingsPage from "./pages/SettingsPage";
import TransactionsPage from "./pages/TransactionsPage";
import TransactionsCryptoDetailsPage from "./pages/TransactionsCryptoDetailsPage";
import TransactionsBtcDetailsPage from "./pages/TransactionsBtcDetailsPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import { ToastContainer } from 'react-toastify';
import VerifyMailPage from "./pages/VerifyMailPage";
import VerifyAccountPage from "./pages/VerifyAccountPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import ChooseWalletPage from "./pages/ChooseWalletPage";
import NewWalletPage from "./pages/NewWalletPage";
import BankAccountPage from "./pages/BankAccountPage.jsx";
import TransactionsFiatDetailsPage from "./pages/TransactionsFiatDetailsPage.jsx";
import RetrieveWalletPage from "./pages/RetrieveWalletPage.jsx";
import TrasferCashPage from "./pages/TrasferCashPage.jsx";


function App() {
  const Dark_Mode = DarkMode;
  var pageBody = document.querySelector("body");

  // Check if enable as default
  if (Dark_Mode.default) {
    pageBody.classList.add("dark-mode");
  }

  return (
    
    <>

      <BrowserRouter>
        <AppHeaderComponent />
        <Routes>
          <Route path="/" element={<Layout />}>

            <Route path="/login" element={<LoginPage />} />

            <Route path="/register" element={<RegisterPage />} />

            <Route path="/verifyMail" element={<VerifyMailPage />} />

            <Route path="/verifyAccount" element={<VerifyAccountPage />} />

            <Route path="/PasswordReset" element={<PasswordResetPage />} />

            <Route path="/ForgotPassword" element={<ForgotPasswordPage />} />

            <Route path="/ChangePassword" element={<ChangePasswordPage />} />

            <Route index element={<ProtectedRoute component={HomePage}/>} />

            <Route path="/chooseWallet" element={<ProtectedRoute component={ChooseWalletPage}/>} />

            <Route path="/retriveWallet" element={<ProtectedRoute component={ RetrieveWalletPage}/>} />

            <Route path="/newWallet" element={<ProtectedRoute component={NewWalletPage}/>} />

            <Route path="/settings" element={<ProtectedRoute component={SettingsPage}/>} />

            <Route path="/transactions" element={<ProtectedRoute component={TransactionsPage}/>} />

            <Route path="/bank-account" element={<ProtectedRoute component={BankAccountPage}/>} />

            <Route path="/transaction-crypto" element={<ProtectedRoute component={TransactionsCryptoDetailsPage}/>} />

            <Route path="/transaction-btc" element={<ProtectedRoute component={TransactionsBtcDetailsPage}/>} />

            <Route path="/transaction-fiat" element={<ProtectedRoute component={TransactionsFiatDetailsPage}/>} />

            <Route path="/transfer" element={<ProtectedRoute component={TrasferCashPage}/>} />

            <Route path="*" element={<Error404Page />} />
            
          </Route>
        </Routes>
      
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
