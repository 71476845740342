import React from 'react'
import CoinItemsComponent from './CoinItemsComponent'

export default function  CoinsCardComponent(props) {
    

    return (
        <div className="section" style={{ paddingBottom:'50px' }}>
            <div className="coins" id="coins">

                <CoinItemsComponent/>
                
            </div>



            <div className="currency" id="currencies" style={{display: 'none'}}>

                <div className="row mt-2">
                <div className="col-6">
                    <div className="stat-box">
                        <div className="title">USD</div>
                        <div className="value">$ 552.95</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="stat-box">
                        <div className="title">NGN</div>
                        <div className="value">₦ 86.45</div>
                    </div>
                </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <div className="stat-box">
                            <div className="title">GHC</div>
                            <div className="value">₵ 53.25</div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="stat-box">
                            <div className="title">ZAR</div>
                            <div className="value">R 120.99</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
