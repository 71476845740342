
import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom';

export default function ChooseWalletPage(props) {
    

    return (
        <>

            <Splide hasTrack={ false } aria-label="wallet slide">
                <div className="custom-wrapper">
                    <SplideTrack>
                        <SplideSlide>
                            <img style={{margin: '0 auto'}} src="assets/img/secure.png" alt="slide 1"/>
                            <h1 style={{paddingLeft: '40px'}} >Private and secure</h1>
                            <p style={{paddingLeft: '40px'}}>Your key never leaves your device</p>
                        </SplideSlide>
                        <SplideSlide>
                            <img src="assets/img/secure.png" alt="slide 2"/>
                            <h1 style={{paddingLeft: '40px'}} >Trade assets</h1>
                            <p style={{paddingLeft: '40px'}}>Trade your assets</p>
                        </SplideSlide>
                        <SplideSlide>
                            <img src="assets/img/secure.png" alt="slide 3"/>
                            <h1 style={{paddingLeft: '40px'}} >Currency/Coin swap</h1>
                            <p style={{paddingLeft: '40px'}}>Swap your currency to coin and coins to currency</p>
                        </SplideSlide>
                    </SplideTrack>
                </div>
                
                <div className="form-button-group transparent" style={{bottom: '40px'}}>
                    <Link to="/newWallet" className="btn btn-primary btn-block btn-lg">
                    CREATE A NEW WALLET
                    </Link>
                    <Link to="/retriveWallet" style={{position:'fixed', bottom: '20px',left:'0px', right:'0px', textAlign: 'center'}}>I already have a wallet</Link>
                </div>
                

            </Splide>
            
        </>
    )
}
