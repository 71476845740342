const allBanks = [
    {
        "name": "SIGNATURE BANK",
        "routingKey": "000034",
        "logoImage": null,
        "bankCode": "000034",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "OPTIMUS BANK",
        "routingKey": "000036",
        "logoImage": null,
        "bankCode": "000036",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "STERLING BANK",
        "routingKey": "000001",
        "logoImage": null,
        "bankCode": "000001",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "KEYSTONE BANK",
        "routingKey": "000002",
        "logoImage": null,
        "bankCode": "000002",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "FIRST CITY MONUMENT BANK",
        "routingKey": "000003",
        "logoImage": null,
        "bankCode": "000003",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "UNITED BANK FOR AFRICA",
        "routingKey": "000004",
        "logoImage": null,
        "bankCode": "000004",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "JAIZ BANK",
        "routingKey": "000006",
        "logoImage": null,
        "bankCode": "000006",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "FIDELITY BANK",
        "routingKey": "000007",
        "logoImage": null,
        "bankCode": "000007",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "POLARIS BANK",
        "routingKey": "000008",
        "logoImage": null,
        "bankCode": "000008",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "CITI BANK",
        "routingKey": "000009",
        "logoImage": null,
        "bankCode": "000009",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "ECOBANK",
        "routingKey": "000010",
        "logoImage": null,
        "bankCode": "000010",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "UNITY BANK",
        "routingKey": "000011",
        "logoImage": null,
        "bankCode": "000011",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "STANBIC IBTC BANK",
        "routingKey": "000012",
        "logoImage": null,
        "bankCode": "000012",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "GTBANK PLC",
        "routingKey": "000013",
        "logoImage": null,
        "bankCode": "000013",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "ACCESS BANK",
        "routingKey": "000014",
        "logoImage": null,
        "bankCode": "000014",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "ZENITH BANK",
        "routingKey": "000015",
        "logoImage": null,
        "bankCode": "000015",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "FIRST BANK OF NIGERIA",
        "routingKey": "000016",
        "logoImage": null,
        "bankCode": "000016",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "WEMA BANK",
        "routingKey": "000017",
        "logoImage": null,
        "bankCode": "000017",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "UNION BANK",
        "routingKey": "000018",
        "logoImage": null,
        "bankCode": "000018",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "ENTERPRISE BANK",
        "routingKey": "000019",
        "logoImage": null,
        "bankCode": "000019",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "STANDARD CHARTERED BANK",
        "routingKey": "000021",
        "logoImage": null,
        "bankCode": "000021",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "SUNTRUST BANK",
        "routingKey": "000022",
        "logoImage": null,
        "bankCode": "000022",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "PROVIDUS BANK",
        "routingKey": "000023",
        "logoImage": null,
        "bankCode": "000023",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "CORONATION MERCHANT BANK",
        "routingKey": "060001",
        "logoImage": null,
        "bankCode": "060001",
        "categoryId": "6",
        "nubanCode": null
    },
    {
        "name": "NPF MICROFINANCE BANK",
        "routingKey": "070001",
        "logoImage": null,
        "bankCode": "070001",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FORTIS MICROFINANCE BANK",
        "routingKey": "070002",
        "logoImage": null,
        "bankCode": "070002",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PAGE MFBANK",
        "routingKey": "070008",
        "logoImage": null,
        "bankCode": "070008",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ASO SAVINGS",
        "routingKey": "090001",
        "logoImage": null,
        "bankCode": "090001",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "JUBILEE LIFE",
        "routingKey": "090003",
        "logoImage": null,
        "bankCode": "090003",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SAFETRUST",
        "routingKey": "090006",
        "logoImage": null,
        "bankCode": "090006",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FIRST TRUST MORTGAGE BANK PLC",
        "routingKey": "090107",
        "logoImage": null,
        "bankCode": "090107",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NEW PRUDENTIAL BANK",
        "routingKey": "090108",
        "logoImage": null,
        "bankCode": "090108",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PAGA",
        "routingKey": "100002",
        "logoImage": null,
        "bankCode": "100002",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "PARKWAY-READYCASH",
        "routingKey": "100003",
        "logoImage": null,
        "bankCode": "100003",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "CELLULANT",
        "routingKey": "100005",
        "logoImage": null,
        "bankCode": "100005",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "ETRANZACT",
        "routingKey": "100006",
        "logoImage": null,
        "bankCode": "100006",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "STANBIC IBTC @EASE WALLET",
        "routingKey": "100007",
        "logoImage": null,
        "bankCode": "100007",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "ECOBANK XPRESS ACCOUNT",
        "routingKey": "100008",
        "logoImage": null,
        "bankCode": "100008",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "GT MOBILE",
        "routingKey": "100009",
        "logoImage": null,
        "bankCode": "100009",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "TEASY MOBILE",
        "routingKey": "100010",
        "logoImage": null,
        "bankCode": "100010",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "KUDA MICROFINANCE BANK",
        "routingKey": "090267",
        "logoImage": null,
        "bankCode": "090267",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "VT NETWORKS",
        "routingKey": "100012",
        "logoImage": null,
        "bankCode": "100012",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "KEGOW(CHAMSMOBILE)",
        "routingKey": "100036",
        "logoImage": null,
        "bankCode": "100036",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "PAYSTACK-TITAN",
        "routingKey": "100039",
        "logoImage": null,
        "bankCode": "100039",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "FORTIS MOBILE",
        "routingKey": "100016",
        "logoImage": null,
        "bankCode": "100016",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "HEDONMARK",
        "routingKey": "100017",
        "logoImage": null,
        "bankCode": "100017",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "ZENITH MOBILE",
        "routingKey": "100018",
        "logoImage": null,
        "bankCode": "100018",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "FIDELITY MOBILE",
        "routingKey": "100019",
        "logoImage": null,
        "bankCode": "100019",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "MONEY BOX",
        "routingKey": "100020",
        "logoImage": null,
        "bankCode": "100020",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "EARTHOLEUM",
        "routingKey": "100021",
        "logoImage": null,
        "bankCode": "100021",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "STERLING MOBILE",
        "routingKey": "100022",
        "logoImage": null,
        "bankCode": "100022",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "TAGPAY",
        "routingKey": "100023",
        "logoImage": null,
        "bankCode": "100023",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "IMPERIAL HOMES MORTGAGE BANK",
        "routingKey": "100024",
        "logoImage": null,
        "bankCode": "100024",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "NIP VIRTUAL BANK",
        "routingKey": "999999",
        "logoImage": null,
        "bankCode": "999999",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "FINATRUST MICROFINANCE BANK",
        "routingKey": "090111",
        "logoImage": null,
        "bankCode": "090111",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SEED CAPITAL MICROFINANCE BANK",
        "routingKey": "090112",
        "logoImage": null,
        "bankCode": "090112",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TCF MICROFINANCE BANK",
        "routingKey": "090115",
        "logoImage": null,
        "bankCode": "090115",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EMPIRE TRUST MICROFINANCE BANK",
        "routingKey": "090114",
        "logoImage": null,
        "bankCode": "090114",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MICROVIS MICROFINANCE BANK ",
        "routingKey": "090113",
        "logoImage": null,
        "bankCode": "090113",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AMML MICROFINANCE BANK ",
        "routingKey": "090116",
        "logoImage": null,
        "bankCode": "090116",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BOCTRUST MICROFINANCE BANK LIMITED",
        "routingKey": "090117",
        "logoImage": null,
        "bankCode": "090117",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "WETLAND  MICROFINANCE BANK",
        "routingKey": "090120",
        "logoImage": null,
        "bankCode": "090120",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IBILE MICROFINANCE BANK",
        "routingKey": "090118",
        "logoImage": null,
        "bankCode": "090118",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "REGENT MICROFINANCE BANK",
        "routingKey": "090125",
        "logoImage": null,
        "bankCode": "090125",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NDIORAH MICROFINANCE BANK",
        "routingKey": "090128",
        "logoImage": null,
        "bankCode": "090128",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BC KASH MICROFINANCE BANK",
        "routingKey": "090127",
        "logoImage": null,
        "bankCode": "090127",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HASAL MICROFINANCE BANK",
        "routingKey": "090121",
        "logoImage": null,
        "bankCode": "090121",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FBNQUEST MERCHANT BANK",
        "routingKey": "060002",
        "logoImage": null,
        "bankCode": "060002",
        "categoryId": "6",
        "nubanCode": null
    },
    {
        "name": "RICHWAY MICROFINANCE BANK",
        "routingKey": "090132",
        "logoImage": null,
        "bankCode": "090132",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PERSONAL TRUST MICROFINANCE BANK",
        "routingKey": "090135",
        "logoImage": null,
        "bankCode": "090135",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MICROCRED MICROFINANCE BANK",
        "routingKey": "090136",
        "logoImage": null,
        "bankCode": "090136",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GOWANS MICROFINANCE BANK",
        "routingKey": "090122",
        "logoImage": null,
        "bankCode": "090122",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RAND MERCHANT BANK",
        "routingKey": "000024",
        "logoImage": null,
        "bankCode": "000024",
        "categoryId": "6",
        "nubanCode": null
    },
    {
        "name": "YES MICROFINANCE BANK",
        "routingKey": "090142",
        "logoImage": null,
        "bankCode": "090142",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SAGAMU MICROFINANCE BANK",
        "routingKey": "090140",
        "logoImage": null,
        "bankCode": "090140",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MONEY TRUST MICROFINANCE BANK",
        "routingKey": "090129",
        "logoImage": null,
        "bankCode": "090129",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LAGOS BUILDING AND INVESTMENT COMPANY",
        "routingKey": "070012",
        "logoImage": null,
        "bankCode": "070012",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "GATEWAY MORTGAGE BANK",
        "routingKey": "070009",
        "logoImage": null,
        "bankCode": "070009",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "ABBEY MORTGAGE BANK",
        "routingKey": "070010",
        "logoImage": null,
        "bankCode": "070010",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "FIRST GENERATION MORTGAGE BANK",
        "routingKey": "070014",
        "logoImage": null,
        "bankCode": "070014",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "PLATINUM MORTGAGE BANK",
        "routingKey": "070013",
        "logoImage": null,
        "bankCode": "070013",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "INFINITY TRUST MORTGAGE BANK",
        "routingKey": "070016",
        "logoImage": null,
        "bankCode": "070016",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "OHAFIA MICROFINANCE BANK",
        "routingKey": "090119",
        "logoImage": null,
        "bankCode": "090119",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "XSLNCE MICROFINANCE BANK",
        "routingKey": "090124",
        "logoImage": null,
        "bankCode": "090124",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CONSUMER MICROFINANCE BANK",
        "routingKey": "090130",
        "logoImage": null,
        "bankCode": "090130",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ALLWORKERS MICROFINANCE BANK",
        "routingKey": "090131",
        "logoImage": null,
        "bankCode": "090131",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ACCION MICROFINANCE BANK",
        "routingKey": "090134",
        "logoImage": null,
        "bankCode": "090134",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "VISA MICROFINANCE BANK",
        "routingKey": "090139",
        "logoImage": null,
        "bankCode": "090139",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CHIKUM MICROFINANCE BANK",
        "routingKey": "090141",
        "logoImage": null,
        "bankCode": "090141",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "APEKS MICROFINANCE BANK",
        "routingKey": "090143",
        "logoImage": null,
        "bankCode": "090143",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CIT MICROFINANCE BANK",
        "routingKey": "090144",
        "logoImage": null,
        "bankCode": "090144",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FULLRANGE MICROFINANCE BANK",
        "routingKey": "090145",
        "logoImage": null,
        "bankCode": "090145",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FFS MICROFINANCE BANK",
        "routingKey": "090153",
        "logoImage": null,
        "bankCode": "090153",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ADDOSSER MICROFINANCE BANK",
        "routingKey": "090160",
        "logoImage": null,
        "bankCode": "090160",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FIDFUND MICROFINANCE BANK",
        "routingKey": "090126",
        "logoImage": null,
        "bankCode": "090126",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AG MORTGAGE BANK",
        "routingKey": "100028",
        "logoImage": null,
        "bankCode": "100028",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "PECANTRUST MICROFINANCE BANK",
        "routingKey": "090137",
        "logoImage": null,
        "bankCode": "090137",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BOWEN MICROFINANCE BANK",
        "routingKey": "090148",
        "logoImage": null,
        "bankCode": "090148",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FUTO MICROFINANCE BANK",
        "routingKey": "090158",
        "logoImage": null,
        "bankCode": "090158",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "REFUGE MORTGAGE BANK",
        "routingKey": "070011",
        "logoImage": null,
        "bankCode": "070011",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "BRENT MORTGAGE BANK",
        "routingKey": "070015",
        "logoImage": null,
        "bankCode": "070015",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "ROYAL EXCHANGE MICROFINANCE BANK",
        "routingKey": "090138",
        "logoImage": null,
        "bankCode": "090138",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HACKMAN MICROFINANCE BANK",
        "routingKey": "090147",
        "logoImage": null,
        "bankCode": "090147",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TRIDENT MICROFINANCE BANK",
        "routingKey": "090146",
        "logoImage": null,
        "bankCode": "090146",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "INFINITY MICROFINANCE BANK",
        "routingKey": "090157",
        "logoImage": null,
        "bankCode": "090157",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CREDIT AFRIQUE MICROFINANCE BANK",
        "routingKey": "090159",
        "logoImage": null,
        "bankCode": "090159",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "E-BARCS MICROFINANCE BANK",
        "routingKey": "090156",
        "logoImage": null,
        "bankCode": "090156",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "VFD MFB",
        "routingKey": "090110",
        "logoImage": null,
        "bankCode": "090110",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ECOMOBILE",
        "routingKey": "100030",
        "logoImage": null,
        "bankCode": "100030",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "INNOVECTIVES KESH",
        "routingKey": "100029",
        "logoImage": null,
        "bankCode": "100029",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "EKONDO MICROFINANCE BANK",
        "routingKey": "090097",
        "logoImage": null,
        "bankCode": "090097",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "VIRTUE MICROFINANCE BANK",
        "routingKey": "090150",
        "logoImage": null,
        "bankCode": "090150",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IRL MICROFINANCE BANK",
        "routingKey": "090149",
        "logoImage": null,
        "bankCode": "090149",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FCMB MOBILE",
        "routingKey": "100031",
        "logoImage": null,
        "bankCode": "100031",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "MUTUAL TRUST MICROFINANCE BANK",
        "routingKey": "090151",
        "logoImage": null,
        "bankCode": "090151",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OKPOGA MICROFINANCE BANK",
        "routingKey": "090161",
        "logoImage": null,
        "bankCode": "090161",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NOVA MERCHANT BANK",
        "routingKey": "060003",
        "logoImage": null,
        "bankCode": "060003",
        "categoryId": "6",
        "nubanCode": null
    },
    {
        "name": "CEMCS MICROFINANCE BANK",
        "routingKey": "090154",
        "logoImage": null,
        "bankCode": "090154",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "DAYLIGHT MICROFINANCE BANK",
        "routingKey": "090167",
        "logoImage": null,
        "bankCode": "090167",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HAGGAI MORTGAGE BANK LIMITED",
        "routingKey": "070017",
        "logoImage": null,
        "bankCode": "070017",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "MAINSTREET MICROFINANCE BANK",
        "routingKey": "090171",
        "logoImage": null,
        "bankCode": "090171",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GREENBANK MICROFINANCE BANK",
        "routingKey": "090178",
        "logoImage": null,
        "bankCode": "090178",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FAST MICROFINANCE BANK",
        "routingKey": "090179",
        "logoImage": null,
        "bankCode": "090179",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LAPO MICROFINANCE BANK",
        "routingKey": "090177",
        "logoImage": null,
        "bankCode": "090177",
        "categoryId": "0",
        "nubanCode": null
    },
    {
        "name": "HERITAGE BANK",
        "routingKey": "000020",
        "logoImage": null,
        "bankCode": "000020",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "UNIVERSITY OF NIGERIA, NSUKKA MICROFINANCE BANK",
        "routingKey": "090251",
        "logoImage": null,
        "bankCode": "090251",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PENNYWISE MICROFINANCE BANK ",
        "routingKey": "090196",
        "logoImage": null,
        "bankCode": "090196",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ABU MICROFINANCE BANK ",
        "routingKey": "090197",
        "logoImage": null,
        "bankCode": "090197",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NIRSAL NATIONAL MICROFINANCE BANK",
        "routingKey": "090194",
        "logoImage": null,
        "bankCode": "090194",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BOSAK MICROFINANCE BANK",
        "routingKey": "090176",
        "logoImage": null,
        "bankCode": "090176",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ASTRAPOLARIS MICROFINANCE BANK",
        "routingKey": "090172",
        "logoImage": null,
        "bankCode": "090172",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "QUICKFUND MICROFINANCE BANK",
        "routingKey": "090261",
        "logoImage": null,
        "bankCode": "090261",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ALEKUN MICROFINANCE BANK",
        "routingKey": "090259",
        "logoImage": null,
        "bankCode": "090259",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RENMONEY MICROFINANCE BANK ",
        "routingKey": "090198",
        "logoImage": null,
        "bankCode": "090198",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "STELLAS MICROFINANCE BANK ",
        "routingKey": "090262",
        "logoImage": null,
        "bankCode": "090262",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NEW DAWN MICROFINANCE BANK",
        "routingKey": "090205",
        "logoImage": null,
        "bankCode": "090205",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ALPHA KAPITAL MICROFINANCE BANK ",
        "routingKey": "090169",
        "logoImage": null,
        "bankCode": "090169",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AUCHI MICROFINANCE BANK ",
        "routingKey": "090264",
        "logoImage": null,
        "bankCode": "090264",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AB MICROFINANCE BANK ",
        "routingKey": "090270",
        "logoImage": null,
        "bankCode": "090270",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NIGERIAN NAVY MICROFINANCE BANK ",
        "routingKey": "090263",
        "logoImage": null,
        "bankCode": "090263",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IMO STATE MICROFINANCE BANK",
        "routingKey": "090258",
        "logoImage": null,
        "bankCode": "090258",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TRUSTFUND MICROFINANCE BANK ",
        "routingKey": "090276",
        "logoImage": null,
        "bankCode": "090276",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GROOMING MICROFINANCE BANK",
        "routingKey": "090195",
        "logoImage": null,
        "bankCode": "090195",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ABOVE ONLY MICROFINANCE BANK ",
        "routingKey": "090260",
        "logoImage": null,
        "bankCode": "090260",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OLABISI ONABANJO UNIVERSITY MICROFINANCE ",
        "routingKey": "090272",
        "logoImage": null,
        "bankCode": "090272",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ADEYEMI COLLEGE STAFF MICROFINANCE BANK",
        "routingKey": "090268",
        "logoImage": null,
        "bankCode": "090268",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MEGAPRAISE MICROFINANCE BANK",
        "routingKey": "090280",
        "logoImage": null,
        "bankCode": "090280",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TAJ BANK",
        "routingKey": "000026",
        "logoImage": null,
        "bankCode": "000026",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "ARISE MICROFINANCE BANK",
        "routingKey": "090282",
        "logoImage": null,
        "bankCode": "090282",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PRESTIGE MICROFINANCE BANK",
        "routingKey": "090274",
        "logoImage": null,
        "bankCode": "090274",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GLORY MICROFINANCE BANK",
        "routingKey": "090278",
        "logoImage": null,
        "bankCode": "090278",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BAINES CREDIT MICROFINANCE BANK",
        "routingKey": "090188",
        "logoImage": null,
        "bankCode": "090188",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ACCESS(DIAMOND) BANK",
        "routingKey": "000005",
        "logoImage": null,
        "bankCode": "000005",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "PILLAR MICROFINANCE BANK",
        "routingKey": "090289",
        "logoImage": null,
        "bankCode": "090289",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SAFE HAVEN MICROFINANCE BANK",
        "routingKey": "090286",
        "logoImage": null,
        "bankCode": "090286",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AFEKHAFE MICROFINANCE BANK",
        "routingKey": "090292",
        "logoImage": null,
        "bankCode": "090292",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GLOBUS BANK",
        "routingKey": "000027",
        "logoImage": null,
        "bankCode": "000027",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "FIRST OPTION MICROFINANCE BANK",
        "routingKey": "090285",
        "logoImage": null,
        "bankCode": "090285",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "POLYUNWANA MICROFINANCE BANK",
        "routingKey": "090296",
        "logoImage": null,
        "bankCode": "090296",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OMIYE MICROFINANCE BANK",
        "routingKey": "090295",
        "logoImage": null,
        "bankCode": "090295",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ASSETMATRIX MICROFINANCE BANK",
        "routingKey": "090287",
        "logoImage": null,
        "bankCode": "090287",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TITAN TRUST BANK",
        "routingKey": "000025",
        "logoImage": null,
        "bankCode": "000025",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "LAVENDER MICROFINANCE BANK",
        "routingKey": "090271",
        "logoImage": null,
        "bankCode": "090271",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FCT MICROFINANCE BANK",
        "routingKey": "090290",
        "logoImage": null,
        "bankCode": "090290",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IKIRE MICROFINANCE BANK",
        "routingKey": "090279",
        "logoImage": null,
        "bankCode": "090279",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PURPLEMONEY MICROFINANCE BANK",
        "routingKey": "090303",
        "logoImage": null,
        "bankCode": "090303",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ACCESS YELLO & BETA",
        "routingKey": "100052",
        "logoImage": null,
        "bankCode": "100052",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TRUSTBANC J6 MICROFINANCE BANK LIMITED",
        "routingKey": "090123",
        "logoImage": null,
        "bankCode": "090123",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SULSPAP MICROFINANCE BANK",
        "routingKey": "090305",
        "logoImage": null,
        "bankCode": "090305",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ESO-E MICROFINANCE BANK",
        "routingKey": "090166",
        "logoImage": null,
        "bankCode": "090166",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EMERALD MICROFINANCE BANK",
        "routingKey": "090273",
        "logoImage": null,
        "bankCode": "090273",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ACCESS MONEY",
        "routingKey": "100013",
        "logoImage": null,
        "bankCode": "100013",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "ALERT MICROFINANCE BANK",
        "routingKey": "090297",
        "logoImage": null,
        "bankCode": "090297",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BRIGHTWAY MICROFINANCE BANK",
        "routingKey": "090308",
        "logoImage": null,
        "bankCode": "090308",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PALMPAY",
        "routingKey": "100033",
        "logoImage": null,
        "bankCode": "100033",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "SPARKLE",
        "routingKey": "090325",
        "logoImage": null,
        "bankCode": "090325",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BALOGUN GAMBARI MICROFINANCE BANK",
        "routingKey": "090326",
        "logoImage": null,
        "bankCode": "090326",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PATRICKGOLD MICROFINANCE BANK",
        "routingKey": "090317",
        "logoImage": null,
        "bankCode": "090317",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MAYFRESH MORTGAGE BANK",
        "routingKey": "070019",
        "logoImage": null,
        "bankCode": "070019",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "TRUST MICROFINANCE BANK",
        "routingKey": "090327",
        "logoImage": null,
        "bankCode": "090327",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AL-BARAKAH MICROFINANCE BANK",
        "routingKey": "090133",
        "logoImage": null,
        "bankCode": "090133",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EYOWO",
        "routingKey": "090328",
        "logoImage": null,
        "bankCode": "090328",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EVANGEL MICROFINANCE BANK ",
        "routingKey": "090304",
        "logoImage": null,
        "bankCode": "090304",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EVERGREEN MICROFINANCE BANK",
        "routingKey": "090332",
        "logoImage": null,
        "bankCode": "090332",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OCHE MICROFINANCE BANK",
        "routingKey": "090333",
        "logoImage": null,
        "bankCode": "090333",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NUTURE MICROFINANCE BANK",
        "routingKey": "090364",
        "logoImage": null,
        "bankCode": "090364",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FIRSTMONIE WALLET",
        "routingKey": "100014",
        "logoImage": null,
        "bankCode": "100014",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "NEPTUNE MICROFINANCE BANK",
        "routingKey": "090329",
        "logoImage": null,
        "bankCode": "090329",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "U & C MICROFINANCE BANK",
        "routingKey": "090315",
        "logoImage": null,
        "bankCode": "090315",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNAAB MICROFINANCE BANK",
        "routingKey": "090331",
        "logoImage": null,
        "bankCode": "090331",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IKENNE MICROFINANCE BANK",
        "routingKey": "090324",
        "logoImage": null,
        "bankCode": "090324",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MAYFAIR MICROFINANCE BANK",
        "routingKey": "090321",
        "logoImage": null,
        "bankCode": "090321",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "REPHIDIM MICROFINANCE BANK",
        "routingKey": "090322",
        "logoImage": null,
        "bankCode": "090322",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KONTAGORA MICROFINANCE BANK",
        "routingKey": "090299",
        "logoImage": null,
        "bankCode": "090299",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CASHCONNECT MICROFINANCE BANK",
        "routingKey": "090360",
        "logoImage": null,
        "bankCode": "090360",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BIPC MICROFINANCE BANK",
        "routingKey": "090336",
        "logoImage": null,
        "bankCode": "090336",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MOLUSI MICROFINANCE BANK",
        "routingKey": "090362",
        "logoImage": null,
        "bankCode": "090362",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LEGEND MICROFINANCE BANK",
        "routingKey": "090372",
        "logoImage": null,
        "bankCode": "090372",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SEEDVEST MICROFINANCE BANK",
        "routingKey": "090369",
        "logoImage": null,
        "bankCode": "090369",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EAGLE FLIGHT MICROFINANCE BANK",
        "routingKey": "090294",
        "logoImage": null,
        "bankCode": "090294",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "THINK FINANCE MICROFINANCE BANK",
        "routingKey": "090373",
        "logoImage": null,
        "bankCode": "090373",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FETS",
        "routingKey": "100001",
        "logoImage": null,
        "bankCode": "100001",
        "categoryId": "12",
        "nubanCode": null
    },
    {
        "name": "COASTLINE MICROFINANCE BANK",
        "routingKey": "090374",
        "logoImage": null,
        "bankCode": "090374",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MINT-FINEX MFB",
        "routingKey": "090281",
        "logoImage": null,
        "bankCode": "090281",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HEADWAY MICROFINANCE BANK",
        "routingKey": "090363",
        "logoImage": null,
        "bankCode": "090363",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ISALEOYO MICROFINANCE BANK",
        "routingKey": "090377",
        "logoImage": null,
        "bankCode": "090377",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NEW GOLDEN PASTURES MICROFINANCE BANK",
        "routingKey": "090378",
        "logoImage": null,
        "bankCode": "090378",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FSDH",
        "routingKey": "400001",
        "logoImage": null,
        "bankCode": "400001",
        "categoryId": "4",
        "nubanCode": null
    },
    {
        "name": "CORESTEP MICROFINANCE BANK",
        "routingKey": "090365",
        "logoImage": null,
        "bankCode": "090365",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FEDPOLY NASARAWA MICROFINANCE BANK",
        "routingKey": "090298",
        "logoImage": null,
        "bankCode": "090298",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FIRMUS MICROFINANCE BANK",
        "routingKey": "090366",
        "logoImage": null,
        "bankCode": "090366",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MANNY MICROFINANCE BANK",
        "routingKey": "090383",
        "logoImage": null,
        "bankCode": "090383",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "DAVODANI  MICROFINANCE BANK",
        "routingKey": "090391",
        "logoImage": null,
        "bankCode": "090391",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EK-RELIABLE MICROFINANCE BANK",
        "routingKey": "090389",
        "logoImage": null,
        "bankCode": "090389",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GTI MICROFINANCE BANK",
        "routingKey": "090385",
        "logoImage": null,
        "bankCode": "090385",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "YOBE MICROFINANCE  BANK",
        "routingKey": "090252",
        "logoImage": null,
        "bankCode": "090252",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "9 PAYMENT SOLUTIONS BANK",
        "routingKey": "120001",
        "logoImage": null,
        "bankCode": "120001",
        "categoryId": "12",
        "nubanCode": null
    },
    {
        "name": "OPAY",
        "routingKey": "100004",
        "logoImage": null,
        "bankCode": "100004",
        "categoryId": "12",
        "nubanCode": null
    },
    {
        "name": "VENTURE GARDEN NIGERIA LIMITED",
        "routingKey": "110009",
        "logoImage": null,
        "bankCode": "110009",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RUBIES MICROFINANCE BANK",
        "routingKey": "090175",
        "logoImage": null,
        "bankCode": "090175",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MOZFIN MICROFINANCE BANK",
        "routingKey": "090392",
        "logoImage": null,
        "bankCode": "090392",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "INTERLAND MICROFINANCE BANK",
        "routingKey": "090386",
        "logoImage": null,
        "bankCode": "090386",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FINCA MICROFINANCE BANK",
        "routingKey": "090400",
        "logoImage": null,
        "bankCode": "090400",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KONGAPAY",
        "routingKey": "100025",
        "logoImage": null,
        "bankCode": "100025",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "ILISAN MICROFINANCE BANK",
        "routingKey": "090370",
        "logoImage": null,
        "bankCode": "090370",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NWANNEGADI MICROFINANCE BANK",
        "routingKey": "090399",
        "logoImage": null,
        "bankCode": "090399",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GIREI MICROFINANACE BANK",
        "routingKey": "090186",
        "logoImage": null,
        "bankCode": "090186",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OSCOTECH MICROFINANCE BANK",
        "routingKey": "090396",
        "logoImage": null,
        "bankCode": "090396",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BRIDGEWAY MICROFINANACE BANK",
        "routingKey": "090393",
        "logoImage": null,
        "bankCode": "090393",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KREDI MONEY MICROFINANCE BANK ",
        "routingKey": "090380",
        "logoImage": null,
        "bankCode": "090380",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SHERPERD TRUST MICROFINANCE BANK",
        "routingKey": "090401",
        "logoImage": null,
        "bankCode": "090401",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NOWNOW DIGITAL SYSTEMS LIMITED",
        "routingKey": "100032",
        "logoImage": null,
        "bankCode": "100032",
        "categoryId": "0",
        "nubanCode": null
    },
    {
        "name": "AMAC MICROFINANCE BANK",
        "routingKey": "090394",
        "logoImage": null,
        "bankCode": "090394",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LIVINGTRUST MORTGAGE BANK PLC",
        "routingKey": "070007",
        "logoImage": null,
        "bankCode": "070007",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "M36",
        "routingKey": "100035",
        "logoImage": null,
        "bankCode": "100035",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "NNEW WOMEN MICROFINANCE BANK ",
        "routingKey": "090283",
        "logoImage": null,
        "bankCode": "090283",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GMB MICROFINANCE BANK",
        "routingKey": "090408",
        "logoImage": null,
        "bankCode": "090408",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "3LINE CARD MANAGEMENT LIMITED",
        "routingKey": "110005",
        "logoImage": null,
        "bankCode": "110005",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "TEAMAPT LIMITED",
        "routingKey": "110007",
        "logoImage": null,
        "bankCode": "110007",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "INTERSWITCH LIMITED",
        "routingKey": "110003",
        "logoImage": null,
        "bankCode": "110003",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "PAYSTACK PAYMENT LIMITED",
        "routingKey": "110006",
        "logoImage": null,
        "bankCode": "110006",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "TRUSTBOND MORTGAGE BANK",
        "routingKey": "090005",
        "logoImage": null,
        "bankCode": "090005",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "NAGARTA MICROFINANCE BANK",
        "routingKey": "090152",
        "logoImage": null,
        "bankCode": "090152",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ADVANS LA FAYETTE  MICROFINANCE BANK",
        "routingKey": "090155",
        "logoImage": null,
        "bankCode": "090155",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "STANFORD MICROFINANCE BANK",
        "routingKey": "090162",
        "logoImage": null,
        "bankCode": "090162",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FIRST ROYAL MICROFINANCE BANK",
        "routingKey": "090164",
        "logoImage": null,
        "bankCode": "090164",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PETRA MICROFINANCE BANK",
        "routingKey": "090165",
        "logoImage": null,
        "bankCode": "090165",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GASHUA MICROFINANCE BANK",
        "routingKey": "090168",
        "logoImage": null,
        "bankCode": "090168",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RELIANCE MICROFINANCE BANK",
        "routingKey": "090173",
        "logoImage": null,
        "bankCode": "090173",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MALACHY MICROFINANCE BANK",
        "routingKey": "090174",
        "logoImage": null,
        "bankCode": "090174",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AMJU UNIQUE MICROFINANCE BANK",
        "routingKey": "090180",
        "logoImage": null,
        "bankCode": "090180",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ESAN MICROFINANCE BANK",
        "routingKey": "090189",
        "logoImage": null,
        "bankCode": "090189",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MUTUAL BENEFITS MICROFINANCE BANK",
        "routingKey": "090190",
        "logoImage": null,
        "bankCode": "090190",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KCMB MICROFINANCE BANK",
        "routingKey": "090191",
        "logoImage": null,
        "bankCode": "090191",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MIDLAND MICROFINANCE BANK",
        "routingKey": "090192",
        "logoImage": null,
        "bankCode": "090192",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNICAL MICROFINANCE BANK",
        "routingKey": "090193",
        "logoImage": null,
        "bankCode": "090193",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LOVONUS MICROFINANCE BANK",
        "routingKey": "090265",
        "logoImage": null,
        "bankCode": "090265",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNIBEN MICROFINANCE BANK",
        "routingKey": "090266",
        "logoImage": null,
        "bankCode": "090266",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GREENVILLE MICROFINANCE BANK",
        "routingKey": "090269",
        "logoImage": null,
        "bankCode": "090269",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AL-HAYAT MICROFINANCE BANK",
        "routingKey": "090277",
        "logoImage": null,
        "bankCode": "090277",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BRETHREN MICROFINANCE BANK",
        "routingKey": "090293",
        "logoImage": null,
        "bankCode": "090293",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EDFIN MICROFINANCE BANK",
        "routingKey": "090310",
        "logoImage": null,
        "bankCode": "090310",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FEDERAL UNIVERSITY DUTSE MICROFINANCE BANK",
        "routingKey": "090318",
        "logoImage": null,
        "bankCode": "090318",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KADPOLY MICROFINANCE BANK",
        "routingKey": "090320",
        "logoImage": null,
        "bankCode": "090320",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MAINLAND MICROFINANCE BANK",
        "routingKey": "090323",
        "logoImage": null,
        "bankCode": "090323",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "APPLE MICROFINANCE BANK",
        "routingKey": "090376",
        "logoImage": null,
        "bankCode": "090376",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BORGU  MICROFINANCE BANK",
        "routingKey": "090395",
        "logoImage": null,
        "bankCode": "090395",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FEDERAL POLYTECHNIC NEKEDE MICROFINANCE BANK",
        "routingKey": "090398",
        "logoImage": null,
        "bankCode": "090398",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OLOWOLAGBA MICROFINANCE BANK",
        "routingKey": "090404",
        "logoImage": null,
        "bankCode": "090404",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BUSINESS SUPPORT MICROFINANCE BANK",
        "routingKey": "090406",
        "logoImage": null,
        "bankCode": "090406",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MKUDI",
        "routingKey": "100011",
        "logoImage": null,
        "bankCode": "100011",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "INTELLIFIN",
        "routingKey": "100027",
        "logoImage": null,
        "bankCode": "100027",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "PAYATTITUDE ONLINE",
        "routingKey": "110001",
        "logoImage": null,
        "bankCode": "110001",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED",
        "routingKey": "110002",
        "logoImage": null,
        "bankCode": "110002",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "FIRST APPLE LIMITED",
        "routingKey": "110004",
        "logoImage": null,
        "bankCode": "110004",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "CYBERSPACE LIMITED",
        "routingKey": "110014",
        "logoImage": null,
        "bankCode": "110014",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "ACCELEREX NETWORK LIMITED",
        "routingKey": "090202",
        "logoImage": null,
        "bankCode": "090202",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "HOPEPSB",
        "routingKey": "120002",
        "logoImage": null,
        "bankCode": "120002",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "BAYERO UNIVERSITY MICROFINANCE BANK",
        "routingKey": "090316",
        "logoImage": null,
        "bankCode": "090316",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MARITIME MICROFINANCE BANK",
        "routingKey": "090410",
        "logoImage": null,
        "bankCode": "090410",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AGOSASA MICROFINANCE BANK",
        "routingKey": "090371",
        "logoImage": null,
        "bankCode": "090371",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ZENITH EASY WALLET",
        "routingKey": "100034",
        "logoImage": null,
        "bankCode": "100034",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "COOP MORTGAGE BANK",
        "routingKey": "070021",
        "logoImage": null,
        "bankCode": "070021",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "CARBON",
        "routingKey": "100026",
        "logoImage": null,
        "bankCode": "100026",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LINKS MICROFINANCE BANK",
        "routingKey": "090435",
        "logoImage": null,
        "bankCode": "090435",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RIGO MICROFINANCE BANK",
        "routingKey": "090433",
        "logoImage": null,
        "bankCode": "090433",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PEACE MICROFINANCE BANK",
        "routingKey": "090402",
        "logoImage": null,
        "bankCode": "090402",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SPECTRUM MICROFINANCE BANK ",
        "routingKey": "090436",
        "logoImage": null,
        "bankCode": "090436",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GREENWICH MERCHANT BANK",
        "routingKey": "060004",
        "logoImage": null,
        "bankCode": "060004",
        "categoryId": "6",
        "nubanCode": null
    },
    {
        "name": "LOTUS BANK",
        "routingKey": "000029",
        "logoImage": null,
        "bankCode": "000029",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "TANGERINE MONEY",
        "routingKey": "090426",
        "logoImage": null,
        "bankCode": "090426",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PARALLEX BANK",
        "routingKey": "000030",
        "logoImage": null,
        "bankCode": "000030",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "Moyofade MF Bank",
        "routingKey": "090448",
        "logoImage": null,
        "bankCode": "090448",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SLS  MF Bank",
        "routingKey": "090449",
        "logoImage": null,
        "bankCode": "090449",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Kwasu MF Bank",
        "routingKey": "090450",
        "logoImage": null,
        "bankCode": "090450",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ATBU  Microfinance Bank",
        "routingKey": "090451",
        "logoImage": null,
        "bankCode": "090451",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNILAG  Microfinance Bank",
        "routingKey": "090452",
        "logoImage": null,
        "bankCode": "090452",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Uzondu MF Bank",
        "routingKey": "090453",
        "logoImage": null,
        "bankCode": "090453",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Borstal Microfinance Bank",
        "routingKey": "090454",
        "logoImage": null,
        "bankCode": "090454",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Oluchukwu Microfinance Bank",
        "routingKey": "090471",
        "logoImage": null,
        "bankCode": "090471",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Caretaker Microfinance Bank",
        "routingKey": "090472",
        "logoImage": null,
        "bankCode": "090472",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Assets Microfinance Bank",
        "routingKey": "090473",
        "logoImage": null,
        "bankCode": "090473",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Verdant Microfinance Bank",
        "routingKey": "090474",
        "logoImage": null,
        "bankCode": "090474",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Giant Stride Microfinance Bank",
        "routingKey": "090475",
        "logoImage": null,
        "bankCode": "090475",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Anchorage Microfinance Bank",
        "routingKey": "090476",
        "logoImage": null,
        "bankCode": "090476",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Light Microfinance Bank",
        "routingKey": "090477",
        "logoImage": null,
        "bankCode": "090477",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Avuenegbe Microfinance Bank",
        "routingKey": "090478",
        "logoImage": null,
        "bankCode": "090478",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "First Heritage Microfinance Bank",
        "routingKey": "090479",
        "logoImage": null,
        "bankCode": "090479",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KOLOMONI MICROFINANCE BANK",
        "routingKey": "090480",
        "logoImage": null,
        "bankCode": "090480",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Prisco  Microfinance Bank",
        "routingKey": "090481",
        "logoImage": null,
        "bankCode": "090481",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ada Microfinance Bank",
        "routingKey": "090483",
        "logoImage": null,
        "bankCode": "090483",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Garki Microfinance Bank",
        "routingKey": "090484",
        "logoImage": null,
        "bankCode": "090484",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SAFEGATE MICROFINANCE BANK",
        "routingKey": "090485",
        "logoImage": null,
        "bankCode": "090485",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Fortress Microfinance Bank",
        "routingKey": "090486",
        "logoImage": null,
        "bankCode": "090486",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Kingdom College  Microfinance Bank",
        "routingKey": "090487",
        "logoImage": null,
        "bankCode": "090487",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ibu-Aje Microfinance",
        "routingKey": "090488",
        "logoImage": null,
        "bankCode": "090488",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Alvana Microfinance Bank",
        "routingKey": "090489",
        "logoImage": null,
        "bankCode": "090489",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MKOBO MICROFINANCE BANK LTD",
        "routingKey": "090455",
        "logoImage": null,
        "bankCode": "090455",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ospoly Microfinance Bank",
        "routingKey": "090456",
        "logoImage": null,
        "bankCode": "090456",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Nice Microfinance Bank",
        "routingKey": "090459",
        "logoImage": null,
        "bankCode": "090459",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Oluyole Microfinance Bank",
        "routingKey": "090460",
        "logoImage": null,
        "bankCode": "090460",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Uniibadan Microfinance Bank",
        "routingKey": "090461",
        "logoImage": null,
        "bankCode": "090461",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Monarch Microfinance Bank",
        "routingKey": "090462",
        "logoImage": null,
        "bankCode": "090462",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Rehoboth Microfinance Bank",
        "routingKey": "090463",
        "logoImage": null,
        "bankCode": "090463",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNIMAID MICROFINANCE BANK",
        "routingKey": "090464",
        "logoImage": null,
        "bankCode": "090464",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Maintrust Microfinance Bank",
        "routingKey": "090465",
        "logoImage": null,
        "bankCode": "090465",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "YCT MICROFINANCE BANK",
        "routingKey": "090466",
        "logoImage": null,
        "bankCode": "090466",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Good Neighbours Microfinance Bank",
        "routingKey": "090467",
        "logoImage": null,
        "bankCode": "090467",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Olofin Owena Microfinance Bank",
        "routingKey": "090468",
        "logoImage": null,
        "bankCode": "090468",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Aniocha Microfinance Bank",
        "routingKey": "090469",
        "logoImage": null,
        "bankCode": "090469",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SUPPORT MICROFINANCE BANK",
        "routingKey": "090446",
        "logoImage": null,
        "bankCode": "090446",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CBN",
        "routingKey": "000028",
        "logoImage": null,
        "bankCode": "000028",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "FEDETH MICROFINANCE BANK",
        "routingKey": "090482",
        "logoImage": null,
        "bankCode": "090482",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "DOT MICROFINANCE BANK",
        "routingKey": "090470",
        "logoImage": null,
        "bankCode": "090470",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ZIKORA MICROFINANCE BANK",
        "routingKey": "090504",
        "logoImage": null,
        "bankCode": "090504",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SOLID ALLIANZE MICROFINANCE BANK",
        "routingKey": "090506",
        "logoImage": null,
        "bankCode": "090506",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PREMIUM TRUST  BANK",
        "routingKey": "000031",
        "logoImage": null,
        "bankCode": "000031",
        "categoryId": "2",
        "nubanCode": null
    },
    {
        "name": "SMARTCASH PAYMENT SERVICE BANK",
        "routingKey": "120004",
        "logoImage": null,
        "bankCode": "120004",
        "categoryId": "0",
        "nubanCode": null
    },
    {
        "name": "MONIEPOINT MICROFINANCE BANK",
        "routingKey": "090405",
        "logoImage": null,
        "bankCode": "090405",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HOMEBASE MORTGAGE BANK",
        "routingKey": "070024",
        "logoImage": null,
        "bankCode": "070024",
        "categoryId": "7",
        "nubanCode": null
    },
    {
        "name": "MOMO PAYMENT SERVICE BANK ",
        "routingKey": "120003",
        "logoImage": null,
        "bankCode": "120003",
        "categoryId": "12",
        "nubanCode": null
    },
    {
        "name": "Chukwunenye  Microfinance Bank",
        "routingKey": "090490",
        "logoImage": null,
        "bankCode": "090490",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Nsuk  Microfinance Bank",
        "routingKey": "090491",
        "logoImage": null,
        "bankCode": "090491",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Oraukwu  Microfinance Bank",
        "routingKey": "090492",
        "logoImage": null,
        "bankCode": "090492",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Iperu Microfinance Bank",
        "routingKey": "090493",
        "logoImage": null,
        "bankCode": "090493",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Boji Boji Microfinance Bank",
        "routingKey": "090494",
        "logoImage": null,
        "bankCode": "090494",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GOODNEWS MICROFINANCE BANK",
        "routingKey": "090495",
        "logoImage": null,
        "bankCode": "090495",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Radalpha Microfinance Bank",
        "routingKey": "090496",
        "logoImage": null,
        "bankCode": "090496",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Palmcoast Microfinance Bank",
        "routingKey": "090497",
        "logoImage": null,
        "bankCode": "090497",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Catland Microfinance Bank",
        "routingKey": "090498",
        "logoImage": null,
        "bankCode": "090498",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Pristine Divitis Microfinance Bank",
        "routingKey": "090499",
        "logoImage": null,
        "bankCode": "090499",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FEWCHORE FINANCE COMPANY LIMITED",
        "routingKey": "050002",
        "logoImage": null,
        "bankCode": "050002",
        "categoryId": "5",
        "nubanCode": null
    },
    {
        "name": "COVENANT MICROFINANCE BANK",
        "routingKey": "070006",
        "logoImage": null,
        "bankCode": "070006",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Gwong Microfinance Bank",
        "routingKey": "090500",
        "logoImage": null,
        "bankCode": "090500",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Boromu Microfinance Bank",
        "routingKey": "090501",
        "logoImage": null,
        "bankCode": "090501",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Shalom Microfinance Bank",
        "routingKey": "090502",
        "logoImage": null,
        "bankCode": "090502",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Projects Microfinance Bank",
        "routingKey": "090503",
        "logoImage": null,
        "bankCode": "090503",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Nigeria Prisons Microfinance Bank",
        "routingKey": "090505",
        "logoImage": null,
        "bankCode": "090505",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Fims Microfinance Bank",
        "routingKey": "090507",
        "logoImage": null,
        "bankCode": "090507",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Borno Renaissance Microfinance Bank",
        "routingKey": "090508",
        "logoImage": null,
        "bankCode": "090508",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Capitalmetriq Swift Microfinance Bank",
        "routingKey": "090509",
        "logoImage": null,
        "bankCode": "090509",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Umunnachi Microfinance Bank",
        "routingKey": "090510",
        "logoImage": null,
        "bankCode": "090510",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Cloverleaf  Microfinance Bank",
        "routingKey": "090511",
        "logoImage": null,
        "bankCode": "090511",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Bubayero Microfinance Bank",
        "routingKey": "090512",
        "logoImage": null,
        "bankCode": "090512",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Seap Microfinance Bank",
        "routingKey": "090513",
        "logoImage": null,
        "bankCode": "090513",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Umuchinemere Procredit Microfinance Bank",
        "routingKey": "090514",
        "logoImage": null,
        "bankCode": "090514",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Rima Growth Pathway Microfinance Bank ",
        "routingKey": "090515",
        "logoImage": null,
        "bankCode": "090515",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Numo Microfinance Bank",
        "routingKey": "090516",
        "logoImage": null,
        "bankCode": "090516",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Uhuru Microfinance Bank",
        "routingKey": "090517",
        "logoImage": null,
        "bankCode": "090517",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Afemai Microfinance Bank",
        "routingKey": "090518",
        "logoImage": null,
        "bankCode": "090518",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ibom Fadama Microfinance Bank",
        "routingKey": "090519",
        "logoImage": null,
        "bankCode": "090519",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IC Globalmicrofinance Bank",
        "routingKey": "090520",
        "logoImage": null,
        "bankCode": "090520",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Foresight Microfinance Bank",
        "routingKey": "090521",
        "logoImage": null,
        "bankCode": "090521",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Chase Microfinance Bank",
        "routingKey": "090523",
        "logoImage": null,
        "bankCode": "090523",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Solidrock Microfinance Bank",
        "routingKey": "090524",
        "logoImage": null,
        "bankCode": "090524",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Triple A Microfinance Bank",
        "routingKey": "090525",
        "logoImage": null,
        "bankCode": "090525",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Crescent Microfinance Bank",
        "routingKey": "090526",
        "logoImage": null,
        "bankCode": "090526",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ojokoro Microfinance Bank",
        "routingKey": "090527",
        "logoImage": null,
        "bankCode": "090527",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Mgbidi Microfinance Bank",
        "routingKey": "090528",
        "logoImage": null,
        "bankCode": "090528",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Bankly Microfinance Bank",
        "routingKey": "090529",
        "logoImage": null,
        "bankCode": "090529",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Confidence Microfinance Bank Ltd",
        "routingKey": "090530",
        "logoImage": null,
        "bankCode": "090530",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Aku Microfinance Bank",
        "routingKey": "090531",
        "logoImage": null,
        "bankCode": "090531",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ibolo Micorfinance Bank Ltd",
        "routingKey": "090532",
        "logoImage": null,
        "bankCode": "090532",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PolyIbadan Microfinance Bank",
        "routingKey": "090534",
        "logoImage": null,
        "bankCode": "090534",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Nkpolu-Ust Microfinance",
        "routingKey": "090535",
        "logoImage": null,
        "bankCode": "090535",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ikoyi-Osun Microfinance Bank",
        "routingKey": "090536",
        "logoImage": null,
        "bankCode": "090536",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Lobrem Microfinance Bank",
        "routingKey": "090537",
        "logoImage": null,
        "bankCode": "090537",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Blue Investments Microfinance Bank",
        "routingKey": "090538",
        "logoImage": null,
        "bankCode": "090538",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Enrich Microfinance Bank",
        "routingKey": "090539",
        "logoImage": null,
        "bankCode": "090539",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Aztec Microfinance Bank",
        "routingKey": "090540",
        "logoImage": null,
        "bankCode": "090540",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Excellent Microfinance Bank",
        "routingKey": "090541",
        "logoImage": null,
        "bankCode": "090541",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Otuo Microfinance Bank Ltd",
        "routingKey": "090542",
        "logoImage": null,
        "bankCode": "090542",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Iwoama Microfinance Bank",
        "routingKey": "090543",
        "logoImage": null,
        "bankCode": "090543",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Aspire Microfinance Bank Ltd",
        "routingKey": "090544",
        "logoImage": null,
        "bankCode": "090544",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Abulesoro Microfinance Bank Ltd",
        "routingKey": "090545",
        "logoImage": null,
        "bankCode": "090545",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ijebu-Ife Microfinance Bank Ltd",
        "routingKey": "090546",
        "logoImage": null,
        "bankCode": "090546",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Rockshield Microfinance Bank",
        "routingKey": "090547",
        "logoImage": null,
        "bankCode": "090547",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ally Microfinance Bank",
        "routingKey": "090548",
        "logoImage": null,
        "bankCode": "090548",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Kc Microfinance Bank",
        "routingKey": "090549",
        "logoImage": null,
        "bankCode": "090549",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Green Energy Microfinance Bank Ltd",
        "routingKey": "090550",
        "logoImage": null,
        "bankCode": "090550",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Fairmoney Microfinance Bank Ltd",
        "routingKey": "090551",
        "logoImage": null,
        "bankCode": "090551",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ekimogun Microfinance Bank",
        "routingKey": "090552",
        "logoImage": null,
        "bankCode": "090552",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Consistent Trust Microfinance Bank Ltd",
        "routingKey": "090553",
        "logoImage": null,
        "bankCode": "090553",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Kayvee Microfinance Bank",
        "routingKey": "090554",
        "logoImage": null,
        "bankCode": "090554",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Bishopgate Microfinance Bank",
        "routingKey": "090555",
        "logoImage": null,
        "bankCode": "090555",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Egwafin Microfinance Bank Ltd",
        "routingKey": "090556",
        "logoImage": null,
        "bankCode": "090556",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Lifegate Microfinance Bank Ltd",
        "routingKey": "090557",
        "logoImage": null,
        "bankCode": "090557",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Shongom Microfinance Bank Ltd",
        "routingKey": "090558",
        "logoImage": null,
        "bankCode": "090558",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Shield Microfinance Bank Ltd",
        "routingKey": "090559",
        "logoImage": null,
        "bankCode": "090559",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TANADI MFB (CRUST)",
        "routingKey": "090560",
        "logoImage": null,
        "bankCode": "090560",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Akuchukwu Microfinance Bank Ltd",
        "routingKey": "090561",
        "logoImage": null,
        "bankCode": "090561",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Cedar Microfinance Bank Ltd",
        "routingKey": "090562",
        "logoImage": null,
        "bankCode": "090562",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Balera Microfinance Bank Ltd",
        "routingKey": "090563",
        "logoImage": null,
        "bankCode": "090563",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Supreme Microfinance Bank Ltd",
        "routingKey": "090564",
        "logoImage": null,
        "bankCode": "090564",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Oke-Aro Oredegbe Microfinance Bank Ltd",
        "routingKey": "090565",
        "logoImage": null,
        "bankCode": "090565",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Okuku Microfinance Bank Ltd",
        "routingKey": "090566",
        "logoImage": null,
        "bankCode": "090566",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Orokam Microfinance Bank Ltd",
        "routingKey": "090567",
        "logoImage": null,
        "bankCode": "090567",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Broadview Microfinance Bank Ltd",
        "routingKey": "090568",
        "logoImage": null,
        "bankCode": "090568",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Qube Microfinance Bank Ltd",
        "routingKey": "090569",
        "logoImage": null,
        "bankCode": "090569",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Iyamoye Microfinance Bank Ltd",
        "routingKey": "090570",
        "logoImage": null,
        "bankCode": "090570",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ilaro Poly Microfinance Bank Ltd",
        "routingKey": "090571",
        "logoImage": null,
        "bankCode": "090571",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ewt Microfinance Bank",
        "routingKey": "090572",
        "logoImage": null,
        "bankCode": "090572",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Snow Microfinance Bank",
        "routingKey": "090573",
        "logoImage": null,
        "bankCode": "090573",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GOLDMAN MICROFINANCE BANK",
        "routingKey": "090574",
        "logoImage": null,
        "bankCode": "090574",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Firstmidas Microfinance Bank Ltd",
        "routingKey": "090575",
        "logoImage": null,
        "bankCode": "090575",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Octopus Microfinance Bank Ltd",
        "routingKey": "090576",
        "logoImage": null,
        "bankCode": "090576",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Iwade Microfinance Bank Ltd",
        "routingKey": "090578",
        "logoImage": null,
        "bankCode": "090578",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Gbede Microfinance Bank",
        "routingKey": "090579",
        "logoImage": null,
        "bankCode": "090579",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Otech Microfinance Bank Ltd",
        "routingKey": "090580",
        "logoImage": null,
        "bankCode": "090580",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BANC CORP MICROFINANCE BANK",
        "routingKey": "090581",
        "logoImage": null,
        "bankCode": "090581",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "STATESIDE MFB",
        "routingKey": "090583",
        "logoImage": null,
        "bankCode": "090583",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Island MFB",
        "routingKey": "090584",
        "logoImage": null,
        "bankCode": "090584",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GOMBE MICROFINANCE BANK LTD",
        "routingKey": "090586",
        "logoImage": null,
        "bankCode": "090586",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Microbiz Microfinance Bank",
        "routingKey": "090587",
        "logoImage": null,
        "bankCode": "090587",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Orisun MFB",
        "routingKey": "090588",
        "logoImage": null,
        "bankCode": "090588",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Mercury MFB",
        "routingKey": "090589",
        "logoImage": null,
        "bankCode": "090589",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "WAYA MICROFINANCE BANK LTD",
        "routingKey": "090590",
        "logoImage": null,
        "bankCode": "090590",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Gabsyn Microfinance Bank",
        "routingKey": "090591",
        "logoImage": null,
        "bankCode": "090591",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KANO POLY MFB",
        "routingKey": "090592",
        "logoImage": null,
        "bankCode": "090592",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TASUED MICROFINANCE BANK LTD",
        "routingKey": "090593",
        "logoImage": null,
        "bankCode": "090593",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IBA MFB ",
        "routingKey": "090598",
        "logoImage": null,
        "bankCode": "090598",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Greenacres MFB",
        "routingKey": "090599",
        "logoImage": null,
        "bankCode": "090599",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AVE MARIA MICROFINANCE BANK LTD",
        "routingKey": "090600",
        "logoImage": null,
        "bankCode": "090600",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KENECHUKWU MICROFINANCE BANK",
        "routingKey": "090602",
        "logoImage": null,
        "bankCode": "090602",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Macrod MFB",
        "routingKey": "090603",
        "logoImage": null,
        "bankCode": "090603",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KKU Microfinance Bank",
        "routingKey": "090606",
        "logoImage": null,
        "bankCode": "090606",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Akpo Microfinance Bank",
        "routingKey": "090608",
        "logoImage": null,
        "bankCode": "090608",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Ummah Microfinance Bank ",
        "routingKey": "090609",
        "logoImage": null,
        "bankCode": "090609",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AMOYE MICROFINANCE BANK",
        "routingKey": "090610",
        "logoImage": null,
        "bankCode": "090610",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Creditville Microfinance Bank",
        "routingKey": "090611",
        "logoImage": null,
        "bankCode": "090611",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Medef Microfinance Bank",
        "routingKey": "090612",
        "logoImage": null,
        "bankCode": "090612",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Total Trust Microfinance Bank",
        "routingKey": "090613",
        "logoImage": null,
        "bankCode": "090613",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FLOURISH MFB",
        "routingKey": "090614",
        "logoImage": null,
        "bankCode": "090614",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Beststar Microfinance Bank",
        "routingKey": "090615",
        "logoImage": null,
        "bankCode": "090615",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RAYYAN Microfinance Bank",
        "routingKey": "090616",
        "logoImage": null,
        "bankCode": "090616",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Iyin Ekiti MFB",
        "routingKey": "090620",
        "logoImage": null,
        "bankCode": "090620",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GIDAUNIYAR ALHERI MICROFINANCE BANK",
        "routingKey": "090621",
        "logoImage": null,
        "bankCode": "090621",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Mab Allianz MFB",
        "routingKey": "090623",
        "logoImage": null,
        "bankCode": "090623",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CASHRITE MICROFINANCE BANK",
        "routingKey": "090649",
        "logoImage": null,
        "bankCode": "090649",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PYRAMID MICROFINANCE BANK",
        "routingKey": "090657",
        "logoImage": null,
        "bankCode": "090657",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MICHAEL OKPARA UNIAGRIC MICROFINANCE BANK",
        "routingKey": "090659",
        "logoImage": null,
        "bankCode": "090659",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "Nomba Financial Services Limited",
        "routingKey": "110028",
        "logoImage": null,
        "bankCode": "110028",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Woven Finance",
        "routingKey": "110029",
        "logoImage": null,
        "bankCode": "110029",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Kadick Integration Limited",
        "routingKey": "110008",
        "logoImage": null,
        "bankCode": "110008",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Interswitch Financial Inclusion Services (Ifis)",
        "routingKey": "110010",
        "logoImage": null,
        "bankCode": "110010",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Arca Payments",
        "routingKey": "110011",
        "logoImage": null,
        "bankCode": "110011",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Cellulant PSSP",
        "routingKey": "110012",
        "logoImage": null,
        "bankCode": "110012",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "QR Payments",
        "routingKey": "110013",
        "logoImage": null,
        "bankCode": "110013",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Vas2Nets Limited",
        "routingKey": "110015",
        "logoImage": null,
        "bankCode": "110015",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Crowdforce",
        "routingKey": "110017",
        "logoImage": null,
        "bankCode": "110017",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Microsystems Investment And Development Limited",
        "routingKey": "110018",
        "logoImage": null,
        "bankCode": "110018",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "NIBSS USSD Payments",
        "routingKey": "110019",
        "logoImage": null,
        "bankCode": "110019",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Bud Infrastructure Limited",
        "routingKey": "110021",
        "logoImage": null,
        "bankCode": "110021",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Koray Pay",
        "routingKey": "110022",
        "logoImage": null,
        "bankCode": "110022",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Capricorn Digital",
        "routingKey": "110023",
        "logoImage": null,
        "bankCode": "110023",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Resident Fintech Limited",
        "routingKey": "110024",
        "logoImage": null,
        "bankCode": "110024",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Netapps Technology Limited",
        "routingKey": "110025",
        "logoImage": null,
        "bankCode": "110025",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Spay Business",
        "routingKey": "110026",
        "logoImage": null,
        "bankCode": "110026",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "Yello Digital Financial Services",
        "routingKey": "110027",
        "logoImage": null,
        "bankCode": "110027",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "ABUCOOP  MICROFINANCE BANK",
        "routingKey": "090424",
        "logoImage": null,
        "bankCode": "090424",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AKWA SAVINGS & LOANS LIMITED",
        "routingKey": "070025",
        "logoImage": null,
        "bankCode": "070025",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ALTERNATIVE BANK LIMITED",
        "routingKey": "000037",
        "logoImage": null,
        "bankCode": "000037",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ARAMOKO MICROFINANCE BANK",
        "routingKey": "090307",
        "logoImage": null,
        "bankCode": "090307",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BALOGUN FULANI  MICROFINANCE BANK",
        "routingKey": "090181",
        "logoImage": null,
        "bankCode": "090181",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BANEX MICROFINANCE BANK",
        "routingKey": "090425",
        "logoImage": null,
        "bankCode": "090425",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BENYSTA MICROFINANCE BANK",
        "routingKey": "090413",
        "logoImage": null,
        "bankCode": "090413",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BLUEWHALES  MICROFINANCE BANK",
        "routingKey": "090431",
        "logoImage": null,
        "bankCode": "090431",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BOI MF BANK",
        "routingKey": "090444",
        "logoImage": null,
        "bankCode": "090444",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BONGHE MICROFINANCE BANK",
        "routingKey": "090319",
        "logoImage": null,
        "bankCode": "090319",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "BRANCH INTERNATIONAL FINANCIAL SERVICES",
        "routingKey": "050006",
        "logoImage": null,
        "bankCode": "050006",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CALABAR MICROFINANCE BANK",
        "routingKey": "090415",
        "logoImage": null,
        "bankCode": "090415",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CAPSTONE MF BANK",
        "routingKey": "090445",
        "logoImage": null,
        "bankCode": "090445",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CBN_TSA",
        "routingKey": "999001",
        "logoImage": null,
        "bankCode": "999001",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CHANELLE BANK",
        "routingKey": "090397",
        "logoImage": null,
        "bankCode": "090397",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CHERISH MICROFINANCE BANK",
        "routingKey": "090440",
        "logoImage": null,
        "bankCode": "090440",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CHIBUEZE MICROFINANCE BANK",
        "routingKey": "090416",
        "logoImage": null,
        "bankCode": "090416",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CITIZEN TRUST MICROFINANCE BANK LTD",
        "routingKey": "090343",
        "logoImage": null,
        "bankCode": "090343",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "COALCAMP MICROFINANCE BANK",
        "routingKey": "090254",
        "logoImage": null,
        "bankCode": "090254",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "COUNTY FINANCE LTD",
        "routingKey": "050001",
        "logoImage": null,
        "bankCode": "050001",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CROSSRIVER  MICROFINANCE BANK",
        "routingKey": "090429",
        "logoImage": null,
        "bankCode": "090429",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "CRUTECH  MICROFINANCE BANK",
        "routingKey": "090414",
        "logoImage": null,
        "bankCode": "090414",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "DELTA TRUST MORTGAGE BANK",
        "routingKey": "070023",
        "logoImage": null,
        "bankCode": "070023",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "DIGNITY FINANCE",
        "routingKey": "050013",
        "logoImage": null,
        "bankCode": "050013",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "EBSU MICROFINANCE BANK",
        "routingKey": "090427",
        "logoImage": null,
        "bankCode": "090427",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ENAIRA",
        "routingKey": "000033",
        "logoImage": null,
        "bankCode": "000033",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ENCO FINANCE",
        "routingKey": "050012",
        "logoImage": null,
        "bankCode": "050012",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FAME MICROFINANCE BANK",
        "routingKey": "090330",
        "logoImage": null,
        "bankCode": "090330",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FAST CREDIT",
        "routingKey": "050009",
        "logoImage": null,
        "bankCode": "050009",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FCMB MICROFINANCE BANK",
        "routingKey": "090409",
        "logoImage": null,
        "bankCode": "090409",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FHA MORTGAGE BANK LTD",
        "routingKey": "070026",
        "logoImage": null,
        "bankCode": "070026",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FIRST MULTIPLE MICROFINANCE BANK",
        "routingKey": "090163",
        "logoImage": null,
        "bankCode": "090163",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FUNDQUEST FINANCIAL SERVICES LTD",
        "routingKey": "050010",
        "logoImage": null,
        "bankCode": "050010",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "FUTMINNA MICROFINANCE BANK",
        "routingKey": "090438",
        "logoImage": null,
        "bankCode": "090438",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GIGINYA MICROFINANCE BANK",
        "routingKey": "090411",
        "logoImage": null,
        "bankCode": "090411",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GIWA MICROFINANCE BANK",
        "routingKey": "090441",
        "logoImage": null,
        "bankCode": "090441",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "GRANT MF BANK",
        "routingKey": "090335",
        "logoImage": null,
        "bankCode": "090335",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HALACREDIT MICROFINANCE BANK",
        "routingKey": "090291",
        "logoImage": null,
        "bankCode": "090291",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "HIGHLAND MICROFINANCE BANK",
        "routingKey": "090418",
        "logoImage": null,
        "bankCode": "090418",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "AAA FINANCE",
        "routingKey": "050005",
        "logoImage": null,
        "bankCode": "050005",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IBETO  MICROFINANCE BANK",
        "routingKey": "090439",
        "logoImage": null,
        "bankCode": "090439",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ILLORIN MICROFINANCE BANK",
        "routingKey": "090350",
        "logoImage": null,
        "bankCode": "090350",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ILORA MICROFINANCE BANK",
        "routingKey": "090430",
        "logoImage": null,
        "bankCode": "090430",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IMOWO MICROFINANCE BANK",
        "routingKey": "090417",
        "logoImage": null,
        "bankCode": "090417",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "INSIGHT MICROFINANCE BANK",
        "routingKey": "090434",
        "logoImage": null,
        "bankCode": "090434",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ISHIE  MICROFINANCE BANK",
        "routingKey": "090428",
        "logoImage": null,
        "bankCode": "090428",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ISUOFIA MICROFINANCE BANK",
        "routingKey": "090353",
        "logoImage": null,
        "bankCode": "090353",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "ITEX INTEGRATED SERVICES LIMITED",
        "routingKey": "090211",
        "logoImage": null,
        "bankCode": "090211",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IYERU OKIN MICROFINANCE BANK LTD",
        "routingKey": "090337",
        "logoImage": null,
        "bankCode": "090337",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "IZON MICROFINANCE BANK",
        "routingKey": "090421",
        "logoImage": null,
        "bankCode": "090421",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "JESSEFIELD MICROFINANCE BANK",
        "routingKey": "090352",
        "logoImage": null,
        "bankCode": "090352",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LANDGOLD  MICROFINANCE BANK",
        "routingKey": "090422",
        "logoImage": null,
        "bankCode": "090422",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LETSHEGO MFB",
        "routingKey": "090420",
        "logoImage": null,
        "bankCode": "090420",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MACROD MFB",
        "routingKey": "090603 ",
        "logoImage": null,
        "bankCode": "090603 ",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MAUTECH MICROFINANCE BANK",
        "routingKey": "090423",
        "logoImage": null,
        "bankCode": "090423",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MEMPHIS MICROFINANCE BANK",
        "routingKey": "090432",
        "logoImage": null,
        "bankCode": "090432",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "MERIDIAN MICROFINANCE BANK",
        "routingKey": "090275",
        "logoImage": null,
        "bankCode": "090275",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NASARAWA MICROFINANCE BANK",
        "routingKey": "090349",
        "logoImage": null,
        "bankCode": "090349",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "NEWEDGE FINANCE LTD",
        "routingKey": "050004",
        "logoImage": null,
        "bankCode": "050004",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OAKLAND MICROFINANCE BANK",
        "routingKey": "090437",
        "logoImage": null,
        "bankCode": "090437",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "OAU MICROFINANCE BANK LTD",
        "routingKey": "090345",
        "logoImage": null,
        "bankCode": "090345",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PARKWAY MF BANK",
        "routingKey": "090390",
        "logoImage": null,
        "bankCode": "090390",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PARRALEX MICROFINANCE BANK",
        "routingKey": "090004",
        "logoImage": null,
        "bankCode": "090004",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PENIEL MICORFINANCE BANK LTD",
        "routingKey": "090379",
        "logoImage": null,
        "bankCode": "090379",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "PREEMINENT MICROFINANCE BANK",
        "routingKey": "090412",
        "logoImage": null,
        "bankCode": "090412",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RAHAMA MICROFINANCE BANK",
        "routingKey": "090170",
        "logoImage": null,
        "bankCode": "090170",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "RIMA MICROFINANCE BANK",
        "routingKey": "090443",
        "logoImage": null,
        "bankCode": "090443",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SAGEGREY FINANCE LIMITED",
        "routingKey": "050003",
        "logoImage": null,
        "bankCode": "050003",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SIMPLE FINANCE LIMITED",
        "routingKey": "050008",
        "logoImage": null,
        "bankCode": "050008",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "STANDARD MICROFINANCE BANK",
        "routingKey": "090182",
        "logoImage": null,
        "bankCode": "090182",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "KEGOW",
        "routingKey": "100015",
        "logoImage": null,
        "bankCode": "100015",
        "categoryId": "10",
        "nubanCode": null
    },
    {
        "name": "STB MORTGAGE BANK",
        "routingKey": "070022",
        "logoImage": null,
        "bankCode": "070022",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "STOCKCORP  MICROFINANCE BANK",
        "routingKey": "090340",
        "logoImage": null,
        "bankCode": "090340",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "SUNBEAM MICROFINANCE BANK",
        "routingKey": "090302",
        "logoImage": null,
        "bankCode": "090302",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TAJWALLET",
        "routingKey": "080002",
        "logoImage": null,
        "bankCode": "080002",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TEKLA FINANCE LTD",
        "routingKey": "050007",
        "logoImage": null,
        "bankCode": "050007",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "TRINITY FINANCIAL SERVICES LIMITED",
        "routingKey": "050014",
        "logoImage": null,
        "bankCode": "050014",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UDA MICROFINANCE BANK",
        "routingKey": "090403",
        "logoImage": null,
        "bankCode": "090403",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNILORIN MICROFINANCE BANK",
        "routingKey": "090341",
        "logoImage": null,
        "bankCode": "090341",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "UNIUYO MICROFINANCE BANK",
        "routingKey": "090338",
        "logoImage": null,
        "bankCode": "090338",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "VALE FINANCE LIMITED",
        "routingKey": "050020",
        "logoImage": null,
        "bankCode": "050020",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "WINVIEW BANK",
        "routingKey": "090419",
        "logoImage": null,
        "bankCode": "090419",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "XPRESS PAYMENTS",
        "routingKey": "090201",
        "logoImage": null,
        "bankCode": "090201",
        "categoryId": "9",
        "nubanCode": null
    },
    {
        "name": "LEADREMIT LIMITED",
        "routingKey": "110044",
        "logoImage": null,
        "bankCode": "110044",
        "categoryId": "11",
        "nubanCode": null
    },
    {
        "name": "MONEY MASTER PSB",
        "routingKey": "120005",
        "logoImage": null,
        "bankCode": "120005",
        "categoryId": "12",
        "nubanCode": null
    }
];

export default allBanks;

// var bankCodes = [];
// allBanks.map((bank, id) => {
//     return bankCodes.push(bank.bankCode)
// })
// console.log(bankCodes)