import React from 'react'
import WalletBalanceComponent from './WalletBalanceComponent'
import WalletFooterComponent from './walletFooterComponent'

export default function WalletCardComponent(props) {
    

    return (
        <div className="section wallet-card-section pt-1">
            <div className="wallet-card">
                <WalletBalanceComponent/>
                <WalletFooterComponent/>
            </div>
        </div>
    )
}
