import React from 'react'
import { Link } from 'react-router-dom';

export default function WalletFooterItemComponent(props) {
    
    const body = document.getElementsByTagName("body")[0];
    var backdrop = document.createElement("div"); // <div></div>
    
    const handleBackDrop = () => {

        backdrop = document.querySelector('.modal-backdrop');
        const appHeader = document.getElementById('appHeader');
        body.removeChild(backdrop);
        appHeader.appendChild(backdrop);

    }
    

    return (
        <>
            <div className="item">
                <a href="/" data-bs-toggle="modal" data-bs-target="#sendActionSheet" onClick={() => handleBackDrop()}>
                    <div className="icon-wrapper bg-danger">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                    </div>
                    <strong>Send</strong>
                </a>
            </div>
            <div className="item">
                <a href="/" data-bs-toggle="modal" data-bs-target="#depositActionSheet" onClick={() => handleBackDrop()}>
                    <div className="icon-wrapper">
                        <ion-icon name="arrow-down-outline"></ion-icon>
                    </div>
                    <strong>Receive</strong>
                </a>
            </div>
            <div className="item">

                {/* <a href="/" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet" onClick={() => handleBackDrop()}> */}
                <Link to="/transfer">

                    <div className="icon-wrapper bg-success">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <strong>Transfer</strong>
                </Link>
                
            </div>
            <div className="item">
                <a href="/" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet" onClick={() => handleBackDrop()}>
                    <div className="icon-wrapper bg-warning">
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                    </div>
                    <strong>Swap</strong>
                </a>
            </div>
        </>
    )
}
