import React from 'react'

export default function PageTitleComponent(props) {
    

    return (
        <div className="pageTitle">
            <img src="assets/img/logo.png" alt="logo" className="logo" />
        </div>
    )
}
