import React from 'react'
import { Link } from 'react-router-dom'
import TransactionItemsBoxComponent from './TransactionItemsBoxComponent'
export default function TransactionsCardComponent(props) {
    

    return (
        <div className="section mt-4">
            <div className="section-heading">
                <h2 className="title">Transactions</h2>
                <Link to="transactions" className="link">View All</Link>
            </div>
                <TransactionItemsBoxComponent/>
        </div>
    )
}
