const bankCodes = 
[
    "000034",
    "000036",
    "000001",
    "000002",
    "000003",
    "000004",
    "000006",
    "000007",
    "000008",
    "000009",
    "000010",
    "000011",
    "000012",
    "000013",
    "000014",
    "000015",
    "000016",
    "000017",
    "000018",
    "000019",
    "000021",
    "000022",
    "000023",
    "060001",
    "070001",
    "070002",
    "070008",
    "090001",
    "090003",
    "090006",
    "090107",
    "090108",
    "100002",
    "100003",
    "100005",
    "100006",
    "100007",
    "100008",
    "100009",
    "100010",
    "090267",
    "100012",
    "100036",
    "100039",
    "100016",
    "100017",
    "100018",
    "100019",
    "100020",
    "100021",
    "100022",
    "100023",
    "100024",
    "999999",
    "090111",
    "090112",
    "090115",
    "090114",
    "090113",
    "090116",
    "090117",
    "090120",
    "090118",
    "090125",
    "090128",
    "090127",
    "090121",
    "060002",
    "090132",
    "090135",
    "090136",
    "090122",
    "000024",
    "090142",
    "090140",
    "090129",
    "070012",
    "070009",
    "070010",
    "070014",
    "070013",
    "070016",
    "090119",
    "090124",
    "090130",
    "090131",
    "090134",
    "090139",
    "090141",
    "090143",
    "090144",
    "090145",
    "090153",
    "090160",
    "090126",
    "100028",
    "090137",
    "090148",
    "090158",
    "070011",
    "070015",
    "090138",
    "090147",
    "090146",
    "090157",
    "090159",
    "090156",
    "090110",
    "100030",
    "100029",
    "090097",
    "090150",
    "090149",
    "100031",
    "090151",
    "090161",
    "060003",
    "090154",
    "090167",
    "070017",
    "090171",
    "090178",
    "090179",
    "090177",
    "000020",
    "090251",
    "090196",
    "090197",
    "090194",
    "090176",
    "090172",
    "090261",
    "090259",
    "090198",
    "090262",
    "090205",
    "090169",
    "090264",
    "090270",
    "090263",
    "090258",
    "090276",
    "090195",
    "090260",
    "090272",
    "090268",
    "090280",
    "000026",
    "090282",
    "090274",
    "090278",
    "090188",
    "000005",
    "090289",
    "090286",
    "090292",
    "000027",
    "090285",
    "090296",
    "090295",
    "090287",
    "000025",
    "090271",
    "090290",
    "090279",
    "090303",
    "100052",
    "090123",
    "090305",
    "090166",
    "090273",
    "100013",
    "090297",
    "090308",
    "100033",
    "090325",
    "090326",
    "090317",
    "070019",
    "090327",
    "090133",
    "090328",
    "090304",
    "090332",
    "090333",
    "090364",
    "100014",
    "090329",
    "090315",
    "090331",
    "090324",
    "090321",
    "090322",
    "090299",
    "090360",
    "090336",
    "090362",
    "090372",
    "090369",
    "090294",
    "090373",
    "100001",
    "090374",
    "090281",
    "090363",
    "090377",
    "090378",
    "400001",
    "090365",
    "090298",
    "090366",
    "090383",
    "090391",
    "090389",
    "090385",
    "090252",
    "120001",
    "100004",
    "110009",
    "090175",
    "090392",
    "090386",
    "090400",
    "100025",
    "090370",
    "090399",
    "090186",
    "090396",
    "090393",
    "090380",
    "090401",
    "100032",
    "090394",
    "070007",
    "100035",
    "090283",
    "090408",
    "110005",
    "110007",
    "110003",
    "110006",
    "090005",
    "090152",
    "090155",
    "090162",
    "090164",
    "090165",
    "090168",
    "090173",
    "090174",
    "090180",
    "090189",
    "090190",
    "090191",
    "090192",
    "090193",
    "090265",
    "090266",
    "090269",
    "090277",
    "090293",
    "090310",
    "090318",
    "090320",
    "090323",
    "090376",
    "090395",
    "090398",
    "090404",
    "090406",
    "100011",
    "100027",
    "110001",
    "110002",
    "110004",
    "110014",
    "090202",
    "120002",
    "090316",
    "090410",
    "090371",
    "100034",
    "070021",
    "100026",
    "090435",
    "090433",
    "090402",
    "090436",
    "060004",
    "000029",
    "090426",
    "000030",
    "090448",
    "090449",
    "090450",
    "090451",
    "090452",
    "090453",
    "090454",
    "090471",
    "090472",
    "090473",
    "090474",
    "090475",
    "090476",
    "090477",
    "090478",
    "090479",
    "090480",
    "090481",
    "090483",
    "090484",
    "090485",
    "090486",
    "090487",
    "090488",
    "090489",
    "090455",
    "090456",
    "090459",
    "090460",
    "090461",
    "090462",
    "090463",
    "090464",
    "090465",
    "090466",
    "090467",
    "090468",
    "090469",
    "090446",
    "000028",
    "090482",
    "090470",
    "090504",
    "090506",
    "000031",
    "120004",
    "090405",
    "070024",
    "120003",
    "090490",
    "090491",
    "090492",
    "090493",
    "090494",
    "090495",
    "090496",
    "090497",
    "090498",
    "090499",
    "050002",
    "070006",
    "090500",
    "090501",
    "090502",
    "090503",
    "090505",
    "090507",
    "090508",
    "090509",
    "090510",
    "090511",
    "090512",
    "090513",
    "090514",
    "090515",
    "090516",
    "090517",
    "090518",
    "090519",
    "090520",
    "090521",
    "090523",
    "090524",
    "090525",
    "090526",
    "090527",
    "090528",
    "090529",
    "090530",
    "090531",
    "090532",
    "090534",
    "090535",
    "090536",
    "090537",
    "090538",
    "090539",
    "090540",
    "090541",
    "090542",
    "090543",
    "090544",
    "090545",
    "090546",
    "090547",
    "090548",
    "090549",
    "090550",
    "090551",
    "090552",
    "090553",
    "090554",
    "090555",
    "090556",
    "090557",
    "090558",
    "090559",
    "090560",
    "090561",
    "090562",
    "090563",
    "090564",
    "090565",
    "090566",
    "090567",
    "090568",
    "090569",
    "090570",
    "090571",
    "090572",
    "090573",
    "090574",
    "090575",
    "090576",
    "090578",
    "090579",
    "090580",
    "090581",
    "090583",
    "090584",
    "090586",
    "090587",
    "090588",
    "090589",
    "090590",
    "090591",
    "090592",
    "090593",
    "090598",
    "090599",
    "090600",
    "090602",
    "090603",
    "090606",
    "090608",
    "090609",
    "090610",
    "090611",
    "090612",
    "090613",
    "090614",
    "090615",
    "090616",
    "090620",
    "090621",
    "090623",
    "090649",
    "090657",
    "090659",
    "110028",
    "110029",
    "110008",
    "110010",
    "110011",
    "110012",
    "110013",
    "110015",
    "110017",
    "110018",
    "110019",
    "110021",
    "110022",
    "110023",
    "110024",
    "110025",
    "110026",
    "110027",
    "090424",
    "070025",
    "000037",
    "090307",
    "090181",
    "090425",
    "090413",
    "090431",
    "090444",
    "090319",
    "050006",
    "090415",
    "090445",
    "999001",
    "090397",
    "090440",
    "090416",
    "090343",
    "090254",
    "050001",
    "090429",
    "090414",
    "070023",
    "050013",
    "090427",
    "000033",
    "050012",
    "090330",
    "050009",
    "090409",
    "070026",
    "090163",
    "050010",
    "090438",
    "090411",
    "090441",
    "090335",
    "090291",
    "090418",
    "050005",
    "090439",
    "090350",
    "090430",
    "090417",
    "090434",
    "090428",
    "090353",
    "090211",
    "090337",
    "090421",
    "090352",
    "090422",
    "090420",
    "090603 ",
    "090423",
    "090432",
    "090275",
    "090349",
    "050004",
    "090437",
    "090345",
    "090390",
    "090004",
    "090379",
    "090412",
    "090170",
    "090443",
    "050003",
    "050008",
    "090182",
    "100015",
    "070022",
    "090340",
    "090302",
    "080002",
    "050007",
    "050014",
    "090403",
    "090341",
    "090338",
    "050020",
    "090419",
    "090201",
    "110044",
    "120005"
]

export default bankCodes