import React, { useEffect, useState } from 'react'
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {ethers} from "ethers";
import env from "react-dotenv";
import USDTContract from "../json/USDT.json"
import { useNavigate } from 'react-router-dom';

export default function SwapCryptoComponent(props) {
    const app_running = env.APP_RUNNING;
    const connection = app_running === 'development' ? new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER) : new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER_LIVE)
    const id = localStorage.getItem('prospayId')
    const userSeed = `prospaySeed${id}`;
    const passPhrase = localStorage.getItem(userSeed);

    const navigate = useNavigate();
    var wallet;
    var signer;
    if(!passPhrase){
        navigate('/register');
    }else if(!id){
        navigate('/login');
    }else {
        wallet = ethers.Wallet.fromMnemonic(passPhrase);
        signer = wallet.connect(connection)
    }
    const naira_bal = localStorage.getItem('naira_bal')
    var [nairaBal, setnairaBal] = useState(naira_bal)
    var actualNBal;
    
    const initialValues = {
        coin: '',
        currency: '',
        amount: '',
    };
    
    const validationSchema = Yup.object().shape({
    
        coin: Yup.string()
            .required()
            .oneOf(["BTC", "USDT", "ETH"])
            .label("From"),
        currency: Yup.string()
            .required()
            .oneOf(["naira", "rand", "cidi"])
            .label("To"),
        amount: Yup.string()
        .required('Amount cannot be empty')
        // allows only decimals and integers
        .test(
            'is-decimal',
            'Enter a valid amount',
            value => (value + "")
            .match(/^[0-9]\d*(\.\d+)?$/)
        ),
    
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        var admin_addresses;
        await axios.post(`${env.PROXY_URL}/api/users/getAddreses`, {})
        .then(res => {
            
            if (res.data.success) {
                admin_addresses = res.data.data;
                // console.log(admin_addresses[0].erc20_usdt_addrs)

            } else {
                console.log(res.data)
            }
        })
        .catch(err => {
            console.error(err);
        })

        if (values.coin === 'BTC') {
            
            values.passPhrase = passPhrase
            values.xpub = localStorage.getItem('xpub')
            values.privateKey = localStorage.getItem('privateKey')
            values.sourceAddress = localStorage.getItem(`sourceAddress${id}`)
            values.toaddress = app_running === 'development' ? admin_addresses[0].btc_addrs_test : admin_addresses[0].btc_addrs_main;
            values.UBIQUITY_API_KEY = env.UBIQUITY_API_KEY;

            console.log(admin_addresses)
            const naira_rate = admin_addresses[0].naira_rate;

            const getNairaAmount = async() => {
                await axios
                .get(`https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd`)
                .then(res => { 
    
                    if(res.data){

                        let new_naira_bal = res.data.tether.usd * parseInt(values.amount) * naira_rate;
                        console.log(res.data.tether.usd * parseInt(values.amount) * naira_rate);
                        actualNBal = parseInt(nairaBal) + parseInt(new_naira_bal);
                        console.log(actualNBal);
                        setnairaBal(actualNBal);
                        // console.log(nairaBal);
                        // console.log(new_naira_bal);
                        return actualNBal;

                    }else{
                        console.log(res.data);
                        return false
                    }
                
                })
                .catch(err => {
                    console.error(err);
                });
            }
            const curr_naira_bal = await getNairaAmount()


           axios
            .post(`${env.PROXY_URL}/api/users/sendCrypto`, values)
            .then(res => { 
                console.log(res.data);
            
                if(res.data.success === 1){
                    // Bitcoin 
                    toast("Transaction submitted")
                    setSubmitting(false);
                    resetForm();


                    console.log(curr_naira_bal, nairaBal, actualNBal, id);
                    axios.post(`${env.PROXY_URL}/api/users/nairaTopup`, {
                        naira_bal: actualNBal,
                        id:id
                    })
                    .then(res => {
                        if (res.data.success) {
                            setnairaBal(actualNBal)
                            console.log(curr_naira_bal, nairaBal, actualNBal, id);
                            localStorage.setItem('naira_bal', actualNBal);
                            toast(`swap successful....`);
                            console.log(curr_naira_bal, nairaBal, actualNBal, id);
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    
                }
                else{
                    // toast('swap not successful')
                    toast(res.data.data)
                    setSubmitting(false);
                }
                
            })
            .catch(err => {
                console.error(err);
                setSubmitting(false);
            });

            
            
        }else if (values.coin === 'USDT') {
            values.toaddress = admin_addresses[0].erc20_usdt_addrs;
            const naira_rate = admin_addresses[0].naira_rate;

            // console.log(admin_addresses[0].erc20_usdt_addrs)
            const amt = ethers.utils.parseUnits(values.amount, env.DECIMAL);

            const getNairaAmount = async() => {
                await axios
                .get(`https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd`)
                .then(res => { 
    
                if(res.data){

                    let new_naira_bal = res.data.tether.usd * parseInt(values.amount) * naira_rate;
                    console.log(res.data.tether.usd * parseInt(values.amount) * naira_rate);
                    actualNBal = parseInt(nairaBal) + parseInt(new_naira_bal);
                    console.log(actualNBal);
                    setnairaBal(actualNBal);
                    // console.log(nairaBal);
                    // console.log(new_naira_bal);
                    return actualNBal;

                }else{
                    console.log(res.data);
                    return false
                }
            
            })
        .catch(err => {
            console.error(err);
        });
            }

            const app_running = env.APP_RUNNING;
            const contract = app_running === 'development' ? new ethers.Contract(env.USDT_CONTRACT_ADDRS, USDTContract.abi, signer) : new ethers.Contract(env.USDT_CONTRACT_ADDRS_LIVE, USDTContract.abi, signer);
            // const gasEstimate = await contract.transfer(values.toaddress, amt).estimateGas();
            // console.log(gasEstimate)
            const txn = await contract.transfer(values.toaddress, amt);
            console.log(txn, "transaction pending....");
            const confirm = await txn.wait();

            if (confirm) {
                const curr_naira_bal = await getNairaAmount()
                console.log(curr_naira_bal, nairaBal, actualNBal, id);
                axios.post(`${env.PROXY_URL}/api/users/nairaTopup`, {
                    naira_bal: actualNBal,
                    id:id
                })
                .then(res => {
                    if (res.data.success) {
                        setnairaBal(actualNBal)
                        console.log(curr_naira_bal, nairaBal, actualNBal, id);
                        localStorage.setItem('naira_bal', actualNBal);
                        toast(`swap successful....`);
                        console.log(curr_naira_bal, nairaBal, actualNBal, id);
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            }else{
                console.log(txn, "swap failed");
            }

        }else if (values.coin === 'ETH') {
            setSubmitting(true);
            values.toaddress = admin_addresses[0].eth_address;
            console.log(values.toaddress)
            const recipient = values.toaddress;
            const gasPrice = connection.getGasPrice();
            const tx = {
                from: wallet.address,
                to: recipient,
                value: ethers.utils.parseUnits(values.amount, 'ether'),
                gasPrice:gasPrice,
                gasLimit: ethers.utils.hexlify(100000), // 100 gwei
                nonce: connection.getTransactionCount(wallet.address, 'latest')
            }

            // const gasEstimate = await signer.sendTransaction(tx).estimateGas();;
            // console.log(gasEstimate)
            const transaction = await signer.sendTransaction(tx)
            if (transaction){
                console.log(transaction)
                // toast("swap successfully")
                // setSubmitting(false);

                values.toaddress = admin_addresses[0].eth_address;
                const naira_rate = admin_addresses[0].naira_rate;

                const getNairaAmount = async() => {
                    await axios
                    .get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`)
                    .then(res => { 
                        console.log(res)
                    if(res.data){

                        let new_naira_bal = res.data.ethereum.usd * values.amount * naira_rate;
                        console.log(res.data.ethereum.usd , values.amount , naira_rate);
                        actualNBal = parseInt(nairaBal) + parseInt(new_naira_bal);
                        console.log(actualNBal);
                        setnairaBal(actualNBal);
                        // console.log(nairaBal);
                        // console.log(new_naira_bal);
                        return actualNBal;

                    }else{
                        console.log(res.data);
                        return false
                    }
                    
                    })
                    .catch(err => {
                        console.error(err);
                    });
                }

                const curr_naira_bal = await getNairaAmount()
                console.log(curr_naira_bal, nairaBal, actualNBal, id);
                axios.post(`${env.PROXY_URL}/api/users/nairaTopup`, {
                    naira_bal: actualNBal,
                    id:id
                })
                .then(res => {
                    if (res.data.success) {
                        setnairaBal(actualNBal)
                        console.log(curr_naira_bal, nairaBal, actualNBal, id);
                        localStorage.setItem('naira_bal', actualNBal);
                        console.log(curr_naira_bal, nairaBal, actualNBal, id);
                        setSubmitting(false);
                        toast(`swap successful....`);
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                    }
                })
                .catch(err => {
                    console.log(err)
                })

            }else{
                console.log(transaction, "swap failed");
            }
            
        } else {
            
        }
   
    };

    useEffect(() => {
        // console.log(nairaBal)
    }, [nairaBal])

    return (
        <>
            <div className="modal fade action-sheet" id="exchangeActionSheet" tabIndex="-1" role="dialog" >
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">SWAP CRYPTO</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                            <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                            

                                    {({ isSubmitting, setFieldValue }) => (
                                    <Form>                                    
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" htmlFor="currency1">From</label>
                                                    <Field as="select" name="coin" className="form-control custom-select" id="currency1" onChange={(event) => {const { name, value } = event.target; setFieldValue(name, value); }}>
                                                        <option value="">Select</option>
                                                        <option value="BTC">BTC</option>
                                                        <option value="USDT">USDT</option>
                                                        <option value="ETH">ETH</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <ErrorMessage name="coin" component="div" style={{color:'#FF396F'}} />

                                        </div>
                                        <div className="col-6">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" htmlFor="currency2">To</label>
                                                    <Field as="select" name="currency" onChange={(event) => {const { name, value } = event.target; setFieldValue(name, value); }} className="form-control custom-select" id="currency2">
                                                        <option value="">Select</option>
                                                        <option value="naira">Naira</option>
                                                        {/* <option value="USDT">USDT</option>
                                                        <option value="ETH">ETH</option> */}
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="currency" component="div" style={{color:'#FF396F'}} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <label className="label">SWAP AMOUNT</label>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text" id="basic-addon2"></span>
                                            <Field type="text" name="amount" className="form-control" placeholder="Enter an amount"
                                                />
                                        </div>
                                    </div>
                                    <ErrorMessage name="amount" component="div" style={{color:'#FF396F'}} />
                                    

                                    <div className="form-group basic">
                                        {/* <button type="button" className="btn btn-primary btn-block btn-lg"
                                            data-bs-dismiss="modal">SWAP<ion-icon name="lock-closed-outline"></ion-icon></button> */}
                                        <button name="submit" type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg"
                                            >SWAP</button>
                                            {/* data-bs-dismiss="modal" */}
                                    </div>
                                    </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
