export const getDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

    // Get individual components
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
}

export const formatDate = (_date) => {
    const date = new Date(_date);

    // Adjust for GMT+1
    date.setHours(date.getHours() + 1);

    // Format the date in the desired format
    const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false, // 24-hour format
    });

    return(formattedDate);

}

export const maskedAddresses = (address) => {

    // Mask the address
    const maskedAddress = address.slice(0, 8) + '****' + address.slice(-6);

    return(maskedAddress);

}