import axios from './axios';
import env from "react-dotenv";

const axiosPrivate = axios.create();

const deploymentStatus = env.DEPLOYMENT;

// Function to refresh the access token
const refreshToken = (refresh_token) => {
  const refreshOptions = {
    method: 'POST',
    url: 'https://api.safehavenmfb.com/oauth2/token',
    headers: { accept: 'application/json', 'content-type': 'application/json' },
    // data: {
    //   grant_type: 'refresh_token',
    //   client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
    //   client_assertion: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwcC5wcm9zcGF5LmlvIiwic3ViIjoiYjgyYTUwNGZlNmY0Y2YxOTUxNWViMTU4ODA0MTdmNDAiLCJhdWQiOiJodHRwczovL2FwaS5zYWZlaGF2ZW5tZmIuY29tIiwiaWF0IjoxNzAwNzU0NTA0LCJleHAiOjE4OTAxMzQ4NTl9.MZjBrWZy1ADFdgcFN0C-8KdRNk9u9IfcRayA44M-Ex50OWez_C8KGhIeXHRjLzHH-EPNwScVH0Mc82gExi81RQ4X72BesIppcOot2-Vhrb11gDudFDnO5D1jLL3ZtkA3zK-Mp7ayzgEiQXCsLxOTcAGZ94MsA-4ODaXtyW3IoEs',
    //   client_id: 'b82a504fe6f4cf19515eb15880417f40',
    //   refresh_token: refresh_token, // Use the current refresh token
    // },

    data: {
      grant_type: 'client_credentials',
      client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
      client_id: 'b82a504fe6f4cf19515eb15880417f40',
      client_assertion: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwcC5wcm9zcGF5LmlvIiwic3ViIjoiYjgyYTUwNGZlNmY0Y2YxOTUxNWViMTU4ODA0MTdmNDAiLCJhdWQiOiJodHRwczovL2FwaS5zYWZlaGF2ZW5tZmIuY29tIiwiaWF0IjoxNzAwNzU0NTA0LCJleHAiOjE4OTAxMzQ4NTl9.MZjBrWZy1ADFdgcFN0C-8KdRNk9u9IfcRayA44M-Ex50OWez_C8KGhIeXHRjLzHH-EPNwScVH0Mc82gExi81RQ4X72BesIppcOot2-Vhrb11gDudFDnO5D1jLL3ZtkA3zK-Mp7ayzgEiQXCsLxOTcAGZ94MsA-4ODaXtyW3IoEs',
      refresh_token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5zYWZlaGF2ZW5tZmIuY29tIiwic3ViIjoiYjgyYTUwNGZlNmY0Y2YxOTUxNWViMTU4ODA0MTdmNDAiLCJhdWQiOiJodHRwczovL2FwcC5wcm9zcGF5LmlvIiwianRpIjoiNDJhMGUwYmIyZmEwMjQ0N2E2MWZmNThlZDRmNTU3NTQiLCJncmFudF90eXBlIjoicmVmcmVzaF90b2tlbiIsInNjb3BlcyI6WyJSRUFEIiwiV1JJVEUiLCJQQVkiXSwiaWJzX2NsaWVudF9pZCI6IjY1NDUwNjUyY2MyZGQyMDAyNDYzNmJmYyIsImlic191c2VyX2lkIjoiNjU0NTA2NTJjYzJkZDIwMDI0NjM2YzA1IiwiaWF0IjoxNzAwODIxMjY1LCJleHAiOjE3MDg1OTcyNjV9.tqBtvSfXJGMtbSiHGS_raidWalDBOm4FpjwXxdG86AlbIDvL9W2OfPtY6xxh5xTfVn_JwJfGPTu1WgceP5Wc-LjmqjqmmJhgYvYiIxIe7F1BHT6FjfjleG3haNClBdTbt_4X2AEquFV4BHnn8stnP207S697HS0epYYJgOAlo3U'
    }
  };

  return axios.request(refreshOptions);
};

// Function to check if the token is expired
const isTokenExpired = (updated_at, expires_at) => {
  if (!updated_at || !expires_at) {
    // console.log("in isTokenExpired empty", true)
    // alert("in isTokenExpired")
    return true; // Treat as expired if the token or expiration is not provided
  }

  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const lastUpdatedInSeconds = Math.floor(new Date(updated_at).getTime() / 1000);
  const expirationTimeInSeconds = parseInt(lastUpdatedInSeconds) + parseInt(expires_at);

  // Treat as expired if the current time is greater than or equal to the expiration time
  // console.log("in isTokenExpired", currentTimeInSeconds >= expirationTimeInSeconds, currentTimeInSeconds, lastUpdatedInSeconds, expirationTimeInSeconds) 
  // alert("in isTokenExpired")
  return currentTimeInSeconds >= expirationTimeInSeconds;

};

const fetchTokens = async() => {
    var api_credentials;
    var access_token;
    var refresh_token;
    var updated_at;
    var expires_at;

    await axios.post(`${env.PROXY_URL}/api/users/getTokens`, {})
    .then(res => {
        // console.log('in fetchtoken', res)
        // alert('in fetchtoken')
        if (res.data.success) {
            api_credentials = res.data.data;
            access_token = api_credentials[0].access_token
            refresh_token = api_credentials[0].refresh_token
            updated_at = api_credentials[0].updated_at
            expires_at = api_credentials[0].expires_at
            // console.log('in fetchtoken set', access_token, refresh_token, updated_at, expires_at)
        } else {
            console.log(res.data)
        }
    })
    .catch(err => {
        console.error(err);
    })

    return {access_token, refresh_token, updated_at, expires_at}
}

axiosPrivate.interceptors.request.use(async(config) => {
  // const accessToken = deploymentStatus === 'LIVE' ? env.ACCESS_TOKEN_LIVE : env.ACCESS_TOKEN_SANDBOX;
  const clientId = deploymentStatus === 'LIVE' ? env.IBS_CLIENT_ID_LIVE: env.IBS_CLIENT_ID_SANDBOX;
  const apiUrl = deploymentStatus === 'LIVE' ? env.API_URL_LIVE: env.API_URL_SANDBOX;
  config.headers['ClientID'] =  clientId;
  config.baseURL = apiUrl;

  const {access_token, refresh_token, updated_at, expires_at} = await fetchTokens()
  // console.log('token fetched before refreshToken', access_token, updated_at, refresh_token, expires_at)


   // Check if the access token is expired
  // if (isTokenExpired(updated_at, expires_at)) {
    if (true) {
    try {
      // Refresh the access token
      const response = await refreshToken(refresh_token);
      // console.log("refreshToken called", response)
      // alert("refreshToken called")
      if(response.status === 200 || response.status === 201) {
        const data = response.data;

        // Update the access token in the Axios instance
        config.headers['Authorization'] = 'Bearer ' + data.access_token;

        await axios.post(`${env.PROXY_URL}/api/users/updateTokens`, {
          access_token: data.access_token,
          expires_at: data.expires_in,
          refresh_token: data.refresh_token,
          id:1
        })
        .then(res => {
          // console.log("updateTokens called", res)
          // alert("updateTokens called")
            if (res.data.success) {
              // console.log(res.data)
            } else {
                console.log(res.data)
            }
        })
        .catch(err => {
            console.error(err);
        })
      }else{
        console.log(response.data)
      }
    } catch (error) {
      // Handle error refreshing token (e.g., redirect to login)
      console.error('Error refreshing token', error);
    }
  }else{
    console.log("Tokens are still valid")
    config.headers['Authorization'] = 'Bearer ' + access_token;
  }


  // Check if the custom header is present in the request
  const formDataHeader = config.headers.hasOwnProperty('Custom-Formdata-Header');

  // Set the Content-Type header based on the presence of the custom header
  if (formDataHeader) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

export default axiosPrivate;
