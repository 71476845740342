import React, { useState } from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from 'react-loader-spinner'
import env from "react-dotenv";


export default function LoginPage() {
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Password is required'),
    });


    const onSubmit = (values, { setSubmitting, resetForm }) => {
        axios
        .post(`${env.PROXY_URL}/api/users/login`, values)
        .then(res => { 
            // console.log(res.data);

            if(res.data.success){
                localStorage.setItem('userToken', res.data.token);
                localStorage.setItem('firstname', res.data.userData.firstname);
                localStorage.setItem('lastname', res.data.userData.lastname);
                localStorage.setItem('email', res.data.userData.email);
                localStorage.setItem('phone', res.data.userData.phone);
                localStorage.setItem('account_id', res.data.userData.account_id);
                localStorage.setItem('naira_bal', res.data.userData.naira_bal);
                const token = localStorage.getItem('userToken');
                setSubmitting(false);
                resetForm();
                if (token) {                        
                    localStorage.setItem('prospayId', res.data.id)
                    const userSeed = `prospaySeed${res.data.id}`;
                    if(localStorage.getItem(`${userSeed}`)){
                        navigate('/');
                    }else{
                        navigate('/chooseWallet');
                    }
                }
            }else{
                toast(res.data.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setSubmitting(false);
            }
            
        })
        .catch(err => {
            console.error(err);
            setSubmitting(false);
        });
                
    };

    return (
        <>
            <div className="section mt-2 text-center">
                <h1>Log in</h1>
                <h4>Fill the form to log in</h4>
            </div>
            <div className="section mb-5 p-2">
                
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                

                    {({ isSubmitting }) => (

                        
                        <Form>
                            <div className="card">
                                <div className="card-body pb-1">
                                    <div className="form-group basic">
                                        <div className="input-wrapper">

                                            <label className="label" htmlFor="email">E-mail</label>

                                            <Field type="email" className="form-control" id="email" name="email" placeholder="Your e-mail" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>

                                            <ErrorMessage name="email" component="div" style={{color:'#FF396F'}}/>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            
                                            <label className="label" htmlFor="password">Password</label>

                                            <Field type={showPassword ? "text" : "password"} className="form-control" id="password" name="password" autoComplete="off" placeholder="Your password" />
                                            <i className="clear-input" style={{display:'block'}} onClick={() => setShowPassword(!showPassword)}>
                                                <ion-icon name={showPassword ? "eye-off" : "eye"}></ion-icon>
                                            </i>

                                            <ErrorMessage name="password" component="div" style={{color:'#FF396F'}}/>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="form-links mt-2">
                                <div> 
                                    <Link to="/verifyAccount" className="headerButton">
                                         Verify Account.
                                    </Link>                        
                                </div>
                                <div>
                                    <Link to="/forgotPassword" className="headerButton">
                                        Forgot Password?
                                    </Link> 
                                </div>
                            </div>

                            <div className="form-button-group  transparent">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg">
                                    {isSubmitting 
                                    ? 
                                        (
                                            <ThreeDots 
                                                height="40" 
                                                width="40" 
                                                radius="5"
                                                color="#4fa94d" 
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )
                                    :
                                        'Log in'
                                    }
                                
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </>

    )
}
