import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppHeaderLeftComponent from './AppHeaderLeftComponent'
import AppHeaderRightComponent from './AppHeaderRightComponent'
import PageTitleComponent from './PageTitleComponent'

export default function AppHeaderComponent(props) {
    const location = useLocation();
    const navigate = useNavigate();

    let appHeader

    if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/verifyMail'  || location.pathname === '/verifyAccount'  || location.pathname === '/forgotPassword' || location.pathname === '/passwordReset' || location.pathname === '/ChangePassword' || location.pathname === '/chooseWallet' || location.pathname === '/newWallet' || location.pathname === '/retriveWallet') {
        appHeader = 
        <div className="appHeader no-border transparent position-absolute">
            <div className="left">
                <button onClick={() => {navigate(-1);}} className="headerButton goBack bg-transparent shadow-none border-0">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </button>
            </div>
            <div className="pageTitle"></div>
            <div className="right">
                {/* if */}
                { location.pathname === '/register' ?
                <Link to="/login" className="headerButton">
                    Login
                {/* else if */}
                </Link>: location.pathname === '/login' ?
                <Link to="/register" className="headerButton">
                    Register
                {/* else */}
                </Link>:
                ""}
            </div>
        </div>
    } else {
        appHeader = 
        <div id="appHeader" className="appHeader bg-primary text-light">
            <AppHeaderLeftComponent />
            <PageTitleComponent/>
            <AppHeaderRightComponent/>
        </div>
    }

    return (
        <>
            {appHeader}
        </>
    )
}
