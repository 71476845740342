import React,{ useState, useEffect } from 'react'
import DarkMode from '../DarkMoodSetting.js'
import env from "react-dotenv";
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


export default function SettingsPage(props) {
    
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)

    // Dark Mode Settings
    const Dark_Mode = DarkMode;

    const toggleDarkMode = () => {
        var pageBody = document.querySelector("body");
        var checkDarkModeStatus = localStorage.getItem("FinappDarkmode");
        var switchDarkMode = document.querySelectorAll(".dark-mode-switch");
        var pageBodyActive = pageBody.classList.contains("dark-mode");


        // Local Dark Mode
        if (Dark_Mode.local_mode.enable) {
            var nightStart = Dark_Mode.local_mode.start_time;
            var nightEnd = Dark_Mode.local_mode.end_time;
            var currentDate = new Date();
            var currentHour = currentDate.getHours();
            if (currentHour >= nightStart || currentHour < nightEnd) {
                // It is night time
                pageBody.classList.add("dark-mode");
            }
        }

        // Auto Detect Dark Mode
        if (Dark_Mode.auto_detect.enable)
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                pageBody.classList.add("dark-mode");
            }

        function switchDarkModeCheck(value) {
            switchDarkMode.forEach(function (el) {
                el.checked = value
            })
        }
        // if dark mode on
        if (checkDarkModeStatus === 1 || checkDarkModeStatus === "1" || pageBody.classList.contains('dark-mode')) {
            switchDarkModeCheck(true);
            if (pageBodyActive) {
                // dark mode already activated
            }
            else {
                pageBody.classList.add("dark-mode")
            }
        }
        else {
            switchDarkModeCheck(false);
        }
        
        switchDarkMode.forEach(function (el) {
            el.addEventListener("click", function () {
                var darkmodeCheck = localStorage.getItem("FinappDarkmode");
                var bodyCheck = pageBody.classList.contains('dark-mode');
                if (darkmodeCheck === 1 || darkmodeCheck === "1" || bodyCheck) {
                    pageBody.classList.remove("dark-mode");
                    localStorage.setItem("FinappDarkmode", "0");
                    switchDarkModeCheck(false);
                }
                else {
                    pageBody.classList.add("dark-mode")
                    switchDarkModeCheck(true);
                    localStorage.setItem("FinappDarkmode", "1");
                }
            })
        })
    }

    useEffect(()=> {
        console.log(loading)
        setLoading(false)
        console.log(loading)
    },[loading])


    const body = document.getElementsByTagName("body")[0];
    var backdrop = document.createElement("div"); // <div></div>
    
    const handleBackDrop = () => {
  
        backdrop = document.querySelector('.modal-backdrop');
        const appHeader = document.getElementById('appHeader');
        body.removeChild(backdrop);
        appHeader.appendChild(backdrop);
  
    }

    const prospayId = localStorage.getItem("prospayId");
    const seed = localStorage.getItem(`prospaySeed${prospayId}`)
    
    const copySeed = () => {
        const seedText = document.getElementById('seedText');
        // Select the text inside the input element
        seedText.select();
        seedText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Deselect the text (optional)
        window.getSelection().removeAllRanges();
        toast('copied');
        // setShow(false)

    };

    const verifyPassword = async() => {
        const password = document.getElementById('password').value
        try {

            const res = await axios.post(`${env.PROXY_URL}/api/users/verifyPassword`, {password, id:prospayId})
            console.log({res, password, id:prospayId})

            if(res.data.success){
                setShow(true)
            }else{
                toast("incorrect password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        } catch (err) {
            console.log(err);

        }
    }

    const changePassword = async() => {
        const password1 = document.getElementById('password1').value
        const password2 = document.getElementById('password2').value

        if (password1 === '' || password2 === '') {
            toast('fill all password fields')
        }else if (password1 !== password2) {
            toast('passwords do not match')
        } else {
            try {

                const res = await axios.post(`${env.PROXY_URL}/api/users/forgotPassword`, {password:password1, id:prospayId})
    
                if(res.data.success){
                    toast(res.data.message)
                }else{
                    toast(res.data.message)
                }
            } catch (err) {
                console.log(err);
    
            }
        }

        
    }

    return (
        <>

            {loading ?
            <div id="loader">
                <img src="assets/img/loading-icon.png" alt="icon" className="loading-icon"/>
            </div>
            :
            <>
                <div className="section mt-3 text-center">
                    <div className="avatar-section">
                        <a href="#">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w100 rounded"/>
                            <span className="button">
                                <ion-icon name="camera-outline"></ion-icon>
                            </span>
                        </a>
                    </div>
                </div>

                <div className="listview-title mt-1">Theme</div>
                <ul className="listview image-listview text inset no-line">
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Dark Mode
                                </div>
                                <div className="form-check form-switch  ms-2">
                                    <input className="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch"/>
                                    <label onClick={() => {toggleDarkMode()}} className="form-check-label" htmlFor="darkmodeSwitch"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                {/* <div className="listview-title mt-1">Notifications</div> */}
                {/* <ul className="listview image-listview text inset">
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Payment Alert
                                    <div className="text-muted">
                                        Send notification when new payment received
                                    </div>
                                </div>
                                <div className="form-check form-switch  ms-2">
                                    <input className="form-check-input" type="checkbox" id="SwitchCheckDefault1"/>
                                    <label className="form-check-label" for="SwitchCheckDefault1"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="/" className="item">
                            <div className="in">
                                <div>Notification Sound</div>
                                <span className="text-primary">Beep</span>
                            </div>
                        </a>
                    </li>
                </ul> */}

                {/* <div className="listview-title mt-1">Profile Settings</div> */}
                {/* <ul className="listview image-listview text inset"> */}
                    {/* <li>
                        <a href="/" className="item">
                            <div className="in">
                                <div>Change Username</div>
                            </div>
                        </a>
                    </li> */}
                    {/* <li>
                        <a href="/" className="item">
                            <div className="in">
                                <div>Update E-mail</div>
                            </div>
                        </a>
                    </li> */}
                    {/* <li>
                        <a href="/" className="item">
                            <div className="in">
                                <div>Address</div>
                                <span className="text-primary">Edit</span>
                            </div>
                        </a>
                    </li> */}
                    {/* <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Private Profile
                                </div>
                                <div className="form-check form-switch ms-2">
                                    <input className="form-check-input" type="checkbox" id="SwitchCheckDefault2"/>
                                    <label className="form-check-label" for="SwitchCheckDefault2"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul> */}

                <div className="listview-title mt-1">Security</div>
                <ul className="listview image-listview text mb-2 inset">
                    <li>
                        <a href={'/settings'} onClick={() => handleBackDrop()} className="item" data-bs-toggle="modal" data-bs-target="#changePassword">
                            <div className="in">
                                <div>Change password</div>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a href={'/settings'} onClick={() => handleBackDrop()} className="item" data-bs-toggle="modal" data-bs-target="#viewSeedPhrase">
                            <div className="in">
                                <div>View Seed Phrase</div>
                            </div>
                        </a>
                    </li>
                    {/* <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    2 Step Verification
                                </div>
                                <div className="form-check form-switch ms-2">
                                    <input className="form-check-input" type="checkbox" id="SwitchCheckDefault3" checked />
                                    <label className="form-check-label" for="SwitchCheckDefault3"></label>
                                </div>
                            </div>
                        </div>
                    </li> */}
                    {/* <li>
                        <a href="/" className="item">
                            <div className="in">
                                <div>Log out all devices</div>
                            </div>
                        </a>
                    </li> */}
                </ul>
            </>}



            {/* password change */}
            <div className="modal fade action-sheet" id="changePassword" tabIndex="-1" role="dialog" >
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Change your password</h5>

                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <form>

                                    <div className="form-group basic">
                                        <label className="label" htmlFor='password1'>Password</label>
                                        <div className="input-group mb-2">
                                            <input type="password" id='password1' className="form-control" placeholder="Enter a new password" />
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <label className="label" htmlFor='password2'>Password</label>
                                        <div className="input-group mb-2">
                                            <input type="password" id='password2' className="form-control" placeholder="confirm new password" />
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={() => changePassword()}>Change</button>
                                            {/* data-bs-dismiss="modal" */}
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* view seed phrase */}
            <div className="modal fade action-sheet" id="viewSeedPhrase" tabIndex="-1" role="dialog" >
                <div className="modal-dialog mb-5" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {!show && <h5 className="modal-title">Enter your password</h5>}
                            {show && <h5 className="modal-title">Your Seed Phrase</h5>}

                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <form>

                                    {!show && <div className="form-group basic">
                                        <label className="label" htmlFor='password'>Password</label>
                                        <div className="input-group mb-2">
                                            <input type="password" id='password' className="form-control" placeholder="Enter your password" />
                                        </div>
                                    </div>}


                                    {!show && <div className="form-group basic">
                                        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={() => verifyPassword()}>view seed phrase</button>
                                            {/* data-bs-dismiss="modal" */}
                                    </div>}

                                    {show && <div className="form-group basic">
                                        <textarea className="form-control text-center" id="seedText" readOnly={true}>{seed}</textarea>
                                    </div>}

                                    {show && <div className="form-group basic">
                                        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={copySeed}>copy seed</button>
                                            {/* data-bs-dismiss="modal" */}
                                    </div>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            </>
    )
}
