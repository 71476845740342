import React from 'react'
import { NavLink } from 'react-router-dom'
import LogOutButton from './LogOutButton'

export default function AppFooterItemComponent(props) {
    

    return (
        <>
            <NavLink to="/" className="item">
                <div className="col">
                    <ion-icon name="home-outline"></ion-icon>
                    <strong>Home</strong>
                </div>
            </NavLink>
            <NavLink to="/transactions" className="item">
                <div className="col">
                    <ion-icon name="document-text-outline"></ion-icon>
                    <strong>Transactions</strong>
                </div>
            </NavLink>
            <NavLink to="/settings" className="item">
                <div className="col">
                    <ion-icon name="settings-outline"></ion-icon>
                    <strong>Settings</strong>
                </div>
            </NavLink>
            <LogOutButton  position="footer" />
        </>
    )
}
