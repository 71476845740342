import { Link } from "react-router-dom";

const LogOutButton = (props) => {

  const body = document.getElementsByTagName("body")[0];
  var backdrop = document.createElement("div"); // <div></div>
  
  const handleBackDrop = () => {

      backdrop = document.querySelector('.modal-backdrop');
      const appHeader = document.getElementById('appHeader');
      body.removeChild(backdrop);
      appHeader.appendChild(backdrop);

  }

  return (
    <>
        {props.position === "sidebar"?
            
            <Link data-bs-toggle="modal" data-bs-target="#logoutActionSheet" onClick={() => handleBackDrop()} className="item bg-transparent shadow-none border-0">
                <div className="icon-box bg-primary">
                    <ion-icon name="log-out-outline"></ion-icon>
                </div>
                <div className="in">
                    Log out
                </div>
            </Link>

        :

            <Link data-bs-toggle="modal" data-bs-target="#logoutActionSheet" onClick={() => handleBackDrop()} className="item bg-transparent shadow-none border-0">
                <div className="col">
                    <ion-icon name="log-out-outline"></ion-icon>
                    <strong>Logout</strong>
                </div>
            </Link>
            
        }


    </>

    
  );
};

export default LogOutButton;