import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import env from "react-dotenv";


export default function PasswordResetPage(props) {
    const navigate = useNavigate();
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");

    const initialValues = {
        code: '',
        id: id
    };

    const validationSchema = Yup.object().shape({
        code: Yup.string()
        .min(6, "Must be up to 6 digits")
        .required("This field is requried")
    });

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        axios
        .post(`${env.PROXY_URL}/api/users/verifyPassCode`, values)
        .then(res => { 
            console.log(res.data);

            if(res.data.success){
                setSubmitting(false);
                // resetForm();
                toast('Verification Succesful')
                setTimeout(() => {
                    toast('you will be redirected shortly')
                }, 2000)
                setTimeout(() => {
                    navigate(`/ChangePassword?id=${res.data.id}`)
                }, 5000)
            }else{
                toast(res.data.data)
                setSubmitting(false);
            }
            
        })
        .catch(err => {
            console.error(err);
            setSubmitting(false);
        });
                
    };

    return (
        <>
            <div className="section mt-2 text-center">
            <h1>Enter Password Reset Code</h1>
            <h4>Enter 6 digit Reset code</h4>
        </div>
        <div className="section mb-5 p-2">

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    <Form >
                        <div className="form-group basic">
                            <Field type="text" className="form-control verification-input" id="code" name="code" placeholder="••••••" maxLength="6"/>
                            <ErrorMessage name="code" component="div" style={{color:'#FF396F'}}/>
                        </div>

                        <div className="text-center"> 
                            <Link to="/forgotPassword" className="headerButton text-center">
                                    Didn't get code?.
                            </Link>                        
                        </div>

                        <div className="form-button-group transparent">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block btn-lg">Verify</button>
                        </div>

                    </Form>
                )}
            </Formik>
        </div>
        </>
    )
}

