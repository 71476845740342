import React,{ useState, useEffect } from 'react'
import CoinsCardComponent from '../components/CoinsCardComponent'
import TransactionsCardComponent from '../components/TransactionsCardComponent'
import WalletCardComponent from '../components/WalletCardComponent'


export default function HomePage(props) {
    const [loading, setLoading] = useState(true)

    useEffect(()=> {
        // console.log(loading)
        setLoading(false)
        // console.log(loading)
    },[loading])

    
    return (
        <>

            {loading ?
            <div id="loader">
                <img src="assets/img/loading-icon.png" alt="icon" className="loading-icon"/>
            </div>
            :
            <>
            <WalletCardComponent/>
            <CoinsCardComponent/>
            <TransactionsCardComponent/>
            </>}
            
        </>
    )
}
