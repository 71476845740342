import React, { useEffect, useState } from 'react'
import {ethers} from "ethers";
import env from "react-dotenv";
import axios from 'axios';
import USDTContract from "../json/USDT.json"
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';


export default function SideBarTotal(props) {
    const [loading, setLoading] = useState(false);

    const [ETHbalance, setETHBalance] = useState();
    const [USDTbalance, setUSDTbalance] = useState();
    const [Nairabalance, setNairaBalance] = useState();
    const [BTCbalance, setBTCbalance] = useState();

    const [TotalUsdBalance, setTotalUsdBalance] = useState();
    const [ethUsdVal, setEthUsdVal] = useState();
    const [btcUsdVal, setBtcUsdVal] = useState();
    const [nairaUsdVal, setNairaUsdVal] = useState();
    const [usdtUsdVal, setUsdtUsdVal] = useState();

    const app_running = env.APP_RUNNING;
    const provider = app_running === 'development' ? new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER) : new ethers.providers.JsonRpcProvider(env.INFURA_RPC_PROVIDER_LIVE)
    const id = localStorage.getItem('prospayId')
    const userSeed = `prospaySeed${id}`;
    const passPhrase = localStorage.getItem(userSeed);

    const navigate = useNavigate();
    var wallet;
    if(!passPhrase){
        navigate('/register');
    }else if(!id){
        navigate('/login');
    }else {
        wallet = ethers.Wallet.fromMnemonic(passPhrase);
    }

    const getETHBalance = async (address) => {
        const balance = await provider.getBalance(address);
        setETHBalance(ethers.utils.formatEther(balance));
    };

    const getUSDTBalance = async (address) => {
        const app_running = env.APP_RUNNING;
        const contract = app_running === 'development' ? new ethers.Contract(env.USDT_CONTRACT_ADDRS, USDTContract.abi, provider) : new ethers.Contract(env.USDT_CONTRACT_ADDRS_LIVE, USDTContract.abi, provider);
        const balance = await contract.balanceOf(address);
        setUSDTbalance(ethers.utils.formatUnits(balance, env.DECIMAL));
    }

    // Define the headers for the API request
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${env.UBIQUITY_API_KEY}`,
    };

    const getBTCBalance = () => {

        const version = "v1"
        const protocol = "bitcoin"
        // const network = "mainnet"
        const network =  app_running === 'development' ? 'testnet' : 'mainnet';
        const from = localStorage.getItem(`sourceAddress${id}`)
        const BAL_ENDPOINT = `https://svc.blockdaemon.com/universal/${version}/${protocol}/${network}/account/${from}`;

        axios.post(`${env.PROXY_URL}/api/users/getBTCTBalance`, {UBIQUITY_API_KEY:env.UBIQUITY_API_KEY, BAL_ENDPOINT})
        .then(response => {
            // console.log('Account data:', response.data.data[0].confirmed_balance, env.UBIQUITY_API_KEY, BAL_ENDPOINT );
            setBTCbalance((response.data.data[0].confirmed_balance)/100000000);
        })
        .catch(error => {
            // console.error('Error ', error.response.data);
            return false;
        });
    }

    const getNairaBalance = async () => {
        // get naira value from the database and set state
        setNairaBalance(localStorage.getItem('naira_bal'));
    }

    const setCoinToUsdVal = () => {

        axios
        .get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum,tether,bitcoin&vs_currencies=usd`)
        .then(res => { 
    
            if(res.data){
                // console.log(res.data);

                setEthUsdVal(res.data.ethereum.usd)
                setBtcUsdVal(res.data.bitcoin.usd)
                setUsdtUsdVal(res.data.tether.usd)

            }else{
                console.log(res.data);
                return false
            }
            
        })
        .catch(err => {
            console.error(err);
        });
    }

    const setCurrToUsdVal = async() => {
        await axios.post(`${env.PROXY_URL}/api/users/getAddreses`, {})
        .then(res => {
            
            if (res.data.success) {
                setNairaUsdVal(localStorage.getItem('naira_bal')/res.data.data[0].naira_rate)
                // console.log(nairaUsdVal) 
            } else {
                console.log(res.data)
            }
        })
        .catch(err => {
            console.error(err);
        })
    }
    const body = document.getElementsByTagName("body")[0];
    var backdrop = document.createElement("div"); // <div></div>
    
    const handleBackDrop = () => {

        backdrop = document.querySelector('.modal-backdrop');
        const appHeader = document.getElementById('appHeader');
        body.removeChild(backdrop);
        appHeader.appendChild(backdrop);

    }
    

    useEffect(() => {
        setLoading(true);
        getETHBalance(wallet.address);
        getBTCBalance();
        getUSDTBalance(wallet.address);
        getNairaBalance();
        setCoinToUsdVal()
        setCurrToUsdVal()
        // console.log(ethUsdVal, 
        // btcUsdVal, 
        // usdtUsdVal, 
        // nairaUsdVal,
        // USDTbalance,
        // ETHbalance,
        // Nairabalance,
        // )
        // console.log(BTCbalance, btcUsdVal)
        // BTCbalance

        if(ethUsdVal !== undefined && 
        btcUsdVal !== undefined && 
        usdtUsdVal !== undefined && 
        nairaUsdVal !== undefined &&
        USDTbalance !== undefined &&
        ETHbalance !== undefined &&
        Nairabalance !== undefined && 
        BTCbalance !== undefined){
            const eth = ethUsdVal * ETHbalance
            // const btc = btcUsdVal * BTCbalance
            const btc = parseFloat(BTCbalance*btcUsdVal).toFixed(2)
            const usdt = usdtUsdVal * USDTbalance
            const naira = nairaUsdVal

            // const total = eth + btc + usdt + naira
            const total = eth + usdt + naira + parseFloat(btc)
            setTotalUsdBalance(ethers.utils.commify(parseFloat(total).toFixed(2)))

            if(TotalUsdBalance){
                setLoading(false)
            }
        }
        
      }, [ethUsdVal, btcUsdVal, usdtUsdVal, nairaUsdVal, TotalUsdBalance, USDTbalance, ETHbalance, Nairabalance]);
    //   BTCbalance

    return (
        <div className="sidebar-balance">
            <div className="listview-title">Balance</div>

            
            <div className="in">
                {loading ? (
                    <ThreeDots 
                    height="40" 
                    width="40" 
                    radius="5"
                    color="#4fa94d" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                    />
                ) : (
                    <h1 className="amount">$ {TotalUsdBalance}</h1>
                )}
            </div>


        </div>
    )
}
