import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'
import env from "react-dotenv";

export default function NewWalletPage(props) {
    const navigate = useNavigate();
    const [seedPhrase, setSeedPhrase] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('userToken')
    const id = localStorage.getItem('prospayId')
 

    useEffect(() => {
        // setLoading(true);
        const header = {authorization: `Bearer ${token}`};
        axios.post(`${env.PROXY_URL}/api/users/newWallet`, header)
        .then(res => { 
            if(res.data.success){
                localStorage.setItem(`xpub${id}`, res.data.data.xpub)
                localStorage.setItem(`privateKey${id}`, res.data.data.privateKey)
                localStorage.setItem(`sourceAddress${id}`, res.data.data.sourceAddress)
                setSeedPhrase(res.data.data.mnemonic);
                // setLoading(false);
            }else{
                toast(res.data.message)
            }
            
        })
        .catch(err => {
            console.error(err);
        });
    }, [loading])

    const savePhrase = () => {
        setLoading(true);
        const id = localStorage.getItem('prospayId')
        const userSeed = `prospaySeed${id}`;
        localStorage.setItem(userSeed, seedPhrase)
        navigate('/');
        setLoading(false);
    }

    return (
        <div className="section mt-3 text-center">
            <h1 >Your seed phrase</h1>
            <p>Write down or copy these words in the same order and save them somewhere safe.</p>
            <div className="wide-block pt-2 pb-2" style={{fontSize : "20px", marginTop:'30px'}}>
                {seedPhrase}
            </div>

            <button disabled={loading} onClick={() => {savePhrase()}} className="btn btn-primary btn-block btn-lg" style={{position:'fixed', bottom: '80px',left:'0px', right:'0px', textAlign: 'center'}}>
            {loading 
            ? 
                (
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="5"
                        color="#4fa94d" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                )
            :
                'COMPLETE'
            }
            </button>
        </div>
    )
}

 